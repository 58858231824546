import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, ButtonGroup, Dropdown, Tab, Tabs } from "react-bootstrap";



// Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { EventSourceState, UrlOptionState, EnvFilterState, CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";
import { TutorialGuidesState } from "store/Reducers/GuideInsights/tutorialGuides";
import tutorialGuidesReducer from "store/Reducers/GuideInsights/tutorialGuides";
import * as tutorialGuideActions from "store/Actions/index";
import { getCallExportExcel } from "utils/ApiCallActions";


import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ChartColors, RetryApi } from "utils/GlobalConstants";


// JSON Imports
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";


// SCSS Imports
import "./TutorialGuides.scss";
import "components/Table/CommonTable.scss";



import Loader from "components/Loader/Loader";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import Segments from 'components/SegmentationFilter/Segmentation';
import EventSource from 'components/EventSource/EventSource';
import StateFilter from "components/StateFilter/StateFilter";
import CommonTable from "components/Table/CommonTable";

import CustomDashboardModal from "components/CommonModal/CustomDashboardModal/CustomDashboardModal";
import Maintenance from "components/Maintenance/Maintenance";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";

import { useErrorBoundary } from 'react-error-boundary';

import SectionFilters from "components/Layouts/SectionFilters";
import { InsightLabels } from "labels";
import { v4 as uuidv4 } from 'uuid';
import UserInfoSection from "components/Layouts/UserInfoSection";


// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }

interface CurrentState {
    tutorialGuides: TutorialGuidesState,
    eventSource: EventSourceState,
    urlOption: UrlOptionState,
    envFilter: EnvFilterState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState

}

const TutorialGuides: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    let fetched_details = useSelector((state: CurrentState) => state);

    useEffect(() => {
        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(1, 2, 0, true, false);

        if (fetched_details.languageData.languageData) {
           
            document.title = insightsLbls.docTitleTutorialGuides ? insightsLbls.docTitleTutorialGuides : "" ;
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData]);


    const location = useLocation();
    const navigate = useNavigate();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const dispatch = useDispatch();
    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "75",
    }

    let linechart_height = 400;
    let guide_linechart_height = 320;

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const tutorialGuidesMount = useRef(false);
    const SummaryMount = useRef(false);

    const [parameter, setParameter] = useState({
        paramter: "",
        source: "",
    });

    // const [goingUp, setGoingUp] = useState(false);

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [tabKey, setTabKey] = useState({
        key: 1,
        chart: []
    });

    const [filterValues, setFilterValues] = useState({
        eventSource: "",
        guideStatus: "",
        segmentsGroup: {
            segmentsFilter: 0,
            segmentTitle: ''
        },
        envStatus: {
            envCode: '',
            value: ''
        }
    });


    const [guideAnalyticsTutorialGuidesChart, setGuideAnalyticsTutorialGuidesChart] = useState({
        loadTutorialGuidesChartData: [],
        guideAnalyticsTutorialGuidesChartDataFetched: false,
        tutorialGuidesChart: {},
        tutorialGuidesChartFetched: false,
        api: '',
    });

    const [guideAnalyticsSummaryTable, setGuideAnalyticsSummaryTable] = useState({
        guideAnalyticsSummaryTableDataFetched: false,
        searchQuery: '',
        // sortKey: 'live_guide_play_counts', 
        sortKey: 'tutorial_play_counts', //check
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 1,
            'sortValue': 'desc'
        },
        // renderTaggedTable: false,
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        hideDeleted: 0
    });

    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.docTitleTutorialGuides ? insightsLbls.docTitleTutorialGuides : "";
    }, [insightsLbls])

    useEffect(() => {
        setFilterValues((prevState: any) => {
            return {
                ...prevState,
                eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceTutorialGuides,
                guideStatus: fetched_details?.commonComponentData?.stateFilterData.stateFilter,
                segmentsGroup: { segmentsFilter: fetched_details?.commonComponentData?.segmentsFilter, segmentTitle: fetched_details?.commonComponentData?.segmentsFilter?.segmentTitle },
                envStatus: { envCode: fetched_details?.commonComponentData?.envFilter?.envCode, value: fetched_details?.commonComponentData?.envFilter?.value }
            };
        });
    }, []);


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@


    const getGuideAnalyticsTutorialGuidesChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
            "time_zone": timeZone,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceTutorialGuides ?? 'player',
            "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
            'request_uuid': reqUUID
            // 'type': "org",
        };

        setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
            return {
                ...prevState,
                api: ['TUTORIAL_GUIDES_CHART']
            }
        })

        tutorialGuideActions.getTutorialGuideAnalyticsData(
            params,
            "TUTORIAL_GUIDES_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_TUTORIAL_GUIDES_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsSummaryTableData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_id": fetched_details?.commonComponentData.appsData.appId,//
            "time_zone": timeZone,//
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",//
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",//
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceTutorialGuides ?? 'player',
            "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            "page_size": guideAnalyticsSummaryTable.limit,//
            "sort_by": guideAnalyticsSummaryTable.sortKey,
            // "sort_by": 'tutorial_play_counts', //check
            "order": guideAnalyticsSummaryTable.sortValue,//
            "page_index": guideAnalyticsSummaryTable.page,//
            "query": encodeURIComponent(guideAnalyticsSummaryTable.searchQuery),
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'type': "org",//
            'all_guides': guideAnalyticsSummaryTable.hideDeleted ? false : true,
            "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
            'request_uuid': reqUUID
        };

        tutorialGuideActions.getTutorialGuideAnalyticsData(
            params,
            "TUTORIAL_GUIDES_SUMMARY_TABLE",
            ActionTypes.SET_GUIDE_TUTORIAL_SUMMARY_TABLE_DATA,
            apiErrorFlag ?? '',
            guideAnalyticsSummaryTable.firstLoad
        ).then((res: any) => dispatch(res));
    };

    const exportExcel = (data: any) => {
        let params = {
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceTutorialGuides ?? 'player',
            "filter": fetched_details?.commonComponentData?.stateFilterData.stateFilter ?? 'published',
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            "page_size": guideAnalyticsSummaryTable.limit,
            "sort_by": guideAnalyticsSummaryTable.sortKey,
            "order": guideAnalyticsSummaryTable.sortValue,
            "page_index": guideAnalyticsSummaryTable.page,
            "query": encodeURIComponent(guideAnalyticsSummaryTable.searchQuery),
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'type': "org",
            'all_guides': guideAnalyticsSummaryTable.hideDeleted ? false : true,
            "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? ''

        };
        getCallExportExcel(params, 'TUTORIAL_GUIDES_SUMMARY_TABLE', data);
    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsTutorialGuidesChartDataFetched: false,
                        tutorialGuidesChartFetched: false,
                        tutorialGuidesChart: {}
                    };
                });
                getGuideAnalyticsTutorialGuidesChartData(request_uuid.value);
            }
            else if (optn === 1) {
                setGuideAnalyticsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsSummaryTableDataFetched: false,

                        searchQuery: '',
                        sortKey: 'tutorial_play_counts', //check
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        sortValue: 'desc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                        firstLoad: true,
                        hideDeleted: 0,
                        defaultSort: {
                            'sortColumn_index': 1,
                            'sortValue': 'desc'
                        }
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    };

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    setParameter((prevState: any) => {
                        return {
                            ...prevState,
                            paramter: data.tutorialID,
                            source: data.tutorialTitle,
                        };
                    });
                    break;

                case "sortColumn":
                    setGuideAnalyticsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });

                    break;

                case "changePage":
                    setGuideAnalyticsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setGuideAnalyticsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });

                    break;

                case "changeEntries":
                    setGuideAnalyticsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "hideDeleted":
                    setGuideAnalyticsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            firstLoad: false,
                            hideDeleted: data
                        }
                    });
                    break;

                case "exportExcel":
                    exportExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [guideAnalyticsSummaryTable]);


    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Sidenav hightlight on scroll

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            setTimeout(() => {
                if (process.env.REACT_APP_WESTPAC === 'true') {

                    if (currentScrollY >= 530) {
                        CommonUtils.LeftPanelHighlight(1, 2, 1, true, false);
                        // optionParam = 'MyGuideSurvey'
                    } else {
                        CommonUtils.LeftPanelHighlight(1, 2, 0, true, false);
                        // optionParam = 'GuideMe'
                    }

                }
                else {
                    // let optionParam = '';
                    if (currentScrollY >= 500) {
                        CommonUtils.LeftPanelHighlight(1, 2, 1, true, false);

                    }

                    else {
                        CommonUtils.LeftPanelHighlight(1, 2, 0, true, false);
                        // optionParam = 'GuideMe'
                    }
                }
            }, 0);
        };

        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => window.removeEventListener("scroll", handleScroll);
    }, [window.scrollY]);

    // Scroll on sidenav click
    useEffect(() => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData?.datesData.fdate,
            tdate: fetched_details?.commonComponentData?.datesData.tdate,
            event_source: fetched_details?.commonComponentData?.eventSource.eventSourceTutorialGuides,
            state: fetched_details?.commonComponentData?.stateFilterData.stateFilter,
            segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            env: fetched_details?.commonComponentData?.envFilter?.envCode,
            option: fetched_details?.urlOption.urlOption,
        }

        navigate({
            pathname: `/guide-insights/tutorial-guides/`,
            search: qs.stringify(queryParams)
        })
        if (process.env.REACT_APP_WESTPAC === 'true') {

            if (fetched_details?.urlOption.urlOption === 'TutorialGuideGraph' || fetched_details?.urlOption.urlOption === '') {
                // CommonUtils.LeftPanelHighlight(1, 0, 0, true, false);
                window.scrollTo({ top: 0, behavior: 'smooth' });

            } else {
                // CommonUtils.LeftPanelHighlight(1, 0, 6, true, false);
                window.scrollTo({ top: 630, behavior: 'smooth' });

            }
        }
        else {
            if (fetched_details?.urlOption.urlOption === 'TutorialGuideGraph' || fetched_details?.urlOption.urlOption === '') {
                // CommonUtils.LeftPanelHighlight(1, 0, 0, true, false);
                window.scrollTo({ top: 0, behavior: 'smooth' });

            }
            else {
                // CommonUtils.LeftPanelHighlight(1, 0, 6, true, false);
                window.scrollTo({ top: 630, behavior: 'smooth' });

            }

        }

    }, [fetched_details?.urlOption.urlOption])


    useEffect(() => {
        try {
            if (Object.keys(fetched_details.commonComponentData.datesData).length !== 0) {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData?.datesData.fdate,
                    tdate: fetched_details?.commonComponentData?.datesData.tdate,
                    event_source: fetched_details?.commonComponentData?.eventSource.eventSourceTutorialGuides,
                    state: fetched_details?.commonComponentData?.stateFilterData.stateFilter,
                    segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
                    env: fetched_details?.commonComponentData?.envFilter?.envCode,
                    option: fetched_details?.urlOption.urlOption,
                }
                navigate({
                    pathname: `/guide-insights/tutorial-guides/`,
                    search: qs.stringify(queryParams)
                })

                if (fetched_details?.commonComponentData?.datesData.source === 'component' &&
                    fetched_details?.commonComponentData.appsData?.appCode === localStorage.getItem('switch_app_code') &&
                    fetched_details?.commonComponentData?.envFilter.listFetched &&
                    fetched_details.commonComponentData.segmentsFilter.listFetched) {
                    // fetched_details?.commonComponentData?.datesData.dateSelection = 15 (Custom Date option)
                    // fetched_details?.commonComponentData?.datesData.dateSelection = 14 (Last Calendar Year)

                    if (fetched_details.commonComponentData.datesData.url.includes('tutorial-guides/')) {
                        const reqUUID = uuidv4();
                        setUuid((prevState: any) => {
                            return {
                                ...prevState,
                                value: reqUUID
                            }
                        })

                        setFilterValues((prevState: any) => {
                            return {
                                ...prevState,
                                eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceTutorialGuides,
                                guideStatus: fetched_details?.commonComponentData?.stateFilterData.stateFilter,
                                segmentsGroup: { segmentsFilter: fetched_details?.commonComponentData?.segmentsFilter, segmentTitle: fetched_details?.commonComponentData?.segmentsFilter?.segmentTitle },
                                envStatus: { envCode: fetched_details?.commonComponentData?.envFilter?.envCode, value: fetched_details?.commonComponentData?.envFilter?.value }
                            };
                        });


                        enableExportPdf(0, false);
                        setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsTutorialGuidesChartDataFetched: false,
                                tutorialGuidesChartFetched: false,
                                tutorialGuidesChart: {}
                            };
                        });
                        getGuideAnalyticsTutorialGuidesChartData(reqUUID);


                        enableExportPdf(1, false);
                        setGuideAnalyticsSummaryTable((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsSummaryTableDataFetched: false,
                                searchQuery: '',
                                sortKey: 'tutorial_play_counts',
                                filterLabels: [],
                                page: 1,
                                showExport: true,
                                defaultSort: {
                                    'sortColumn_index': 1,
                                    'sortValue': 'desc'
                                },
                                sortValue: 'desc',
                                limit: 5,
                                tablePagination: {
                                    offset: 0,
                                    data: [],
                                    perPage: 5,
                                    currentPage: 0
                                },
                                firstLoad: true,
                                hideDeleted: 0
                            };
                        });
                    }
                }
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.commonComponentData.datesData,
    fetched_details.commonComponentData.stateFilterData,
    fetched_details?.commonComponentData?.eventSource.eventSourceTutorialGuides,
    fetched_details?.commonComponentData.segmentsFilter.listFetched,
    fetched_details?.commonComponentData.segmentsFilter.segmentsFilter,
    fetched_details?.commonComponentData?.envFilter,
    fetched_details.languageData.languageData])



    //TutorialGuide     
    useEffect(() => {
        try {
            if (
                fetched_details?.tutorialGuides?.guideAnalyticsTutorialGuidesChartData?.data !== undefined &&
                fetched_details?.tutorialGuides?.guideAnalyticsTutorialGuidesChartData?.result === "success" &&
                fetched_details?.tutorialGuides?.guideAnalyticsTutorialGuidesChartData?.requestUUID === request_uuid.value
            ) {
                tutorialGuidesMount.current = true;
                let TutorialGuidesChartData: any = [];
                TutorialGuidesChartData = fetched_details?.tutorialGuides?.guideAnalyticsTutorialGuidesChartData.data.graphData;
                setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTutorialGuidesChartData: [...TutorialGuidesChartData],
                        guideAnalyticsTutorialGuidesChartDataFetched: true,
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true)

            } else if (fetched_details?.tutorialGuides?.guideAnalyticsTutorialGuidesChartData?.result === 'retry') {
                getGuideAnalyticsTutorialGuidesChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.tutorialGuides?.guideAnalyticsTutorialGuidesChartData?.result === "error"
            ) {
                setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsTutorialGuidesChartDataFetched: true,
                    };
                });
                apiCatchError(0, 1);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.tutorialGuides?.guideAnalyticsTutorialGuidesChartData]);

    // Summary Table
    useEffect(() => {
        try {
            if (
                fetched_details?.tutorialGuides?.guideAnalyticsSummaryTableData?.data !== undefined &&
                fetched_details?.tutorialGuides?.guideAnalyticsSummaryTableData?.result === "success" &&
                fetched_details?.tutorialGuides?.guideAnalyticsSummaryTableData?.requestUUID === request_uuid.value
            ) {
                SummaryMount.current = true;
                setGuideAnalyticsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details?.tutorialGuides?.guideAnalyticsSummaryTableData?.result === 'retry') {
                getGuideAnalyticsSummaryTableData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.tutorialGuides?.guideAnalyticsSummaryTableData?.result === "error"
            ) {
                setGuideAnalyticsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(1, 1);
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.tutorialGuides?.guideAnalyticsSummaryTableData]);

    // Call summary table api on table related actions changes
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData?.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData?.datesData?.url?.includes('tutorial-guide')) {
                enableExportPdf(1, false);
                getGuideAnalyticsSummaryTableData(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        guideAnalyticsSummaryTable.sortKey,
        guideAnalyticsSummaryTable.sortValue,
        guideAnalyticsSummaryTable.defaultSort,
        guideAnalyticsSummaryTable.page,
        guideAnalyticsSummaryTable.searchQuery,
        guideAnalyticsSummaryTable.limit,
        guideAnalyticsSummaryTable.hideDeleted,
    ]);

    // Export PDF Btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])

    // Detail Navigation
    useEffect(() => {
        try {
            if (parameter.paramter !== '') {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData?.datesData.fdate,
                    tdate: fetched_details?.commonComponentData?.datesData.tdate,
                }
                navigate({
                    pathname: "/guide-insights/tutorial-guides-detail/" + parameter.paramter + '/',
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [parameter])


    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])

    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const renderLineChart = (data: any) => {

    }

    const renderDonutChart = (data: any) => {

    }

    const LineChartTutorialGuides = useCallback(() => {
        try {
            setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
                return {
                    ...prevState,
                    tutorialGuidesChart: <LineChart
                        chartEntities={['tutorial_play', 'tutorial_unique_play', 'tutorial_complete']}
                        optionEntities={
                            [
                                {
                                    key: insightsLbls.totalPlayed,
                                    color: ChartColors.color1, 
                                    associatedDataPoint: "tutorial_play",
                                    area: true
                                },
                                {
                                    key: insightsLbls.uniquePlayed,
                                    color: ChartColors.color2, 
                                    associatedDataPoint: "tutorial_unique_play",
                                    area: true
                                },
                                {
                                    key: insightsLbls.completed,
                                    color: ChartColors.color3, 
                                    associatedDataPoint: "tutorial_complete",
                                    area: true
                                }
                            ]}
                        chartData={fetched_details?.tutorialGuides?.guideAnalyticsTutorialGuidesChartData?.data.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls.yAxisLabelCount}
                        refId="guideAnalytics_tutorialguide">
                    </LineChart>,
                    tutorialGuidesChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [lineChartMargin, linechart_height]);

    const handleSwitch = (e: any) => {

    };

    return (
        <section className="demo tutorialGuideAnalytics width100" ref={componentRef}>
            {/* Breadcrumb */}
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">
                            {insightsLbls.tutorialGuide}
                        </li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EnvironmentFilter component={"guideAnalytics"} disabled={false} />
                    <Segments component={"guideAnalytics"}></Segments>
                    <EventSource component={"tutorialGuides"}></EventSource>
                    <StateFilter component={"guideAnalytics"} />
                    <Datepicker source="component"></Datepicker>
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={insightsLbls.tutorialGuide} svgImage={"guides"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"Guides"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}


            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Guides"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                dateObject={fetched_details?.commonComponentData?.datesData}
                messageText={insightsLbls.tutorialGuide}>
            </UserInfoSection>
            
            <div id="guideAnalyticsSection">

                {
                    // <SectionRefresh sectionTitle={insightsLbls.tutorialGuide} refreshClass={"marginTop-30"}>
                    //     <i
                    //         className="fa fa-refresh pull-right pointer row displayFlex"
                    //         aria-hidden="true" title={insightsLbls.refresh}
                    //         onClick={() => refreshSections(0)}>
                    //     </i>
                    //     <CustomDashboardModal
                    //         api={guideAnalyticsTutorialGuidesChart.api}
                    //         eventSource={filterValues.eventSource}
                    //         guideStatus={filterValues.guideStatus}
                    //         segmentsGroup={filterValues.segmentsGroup}
                    //         chartType={"LineChart"}
                    //         sectionHeader={insightsLbls.tutorialGuide}
                    //         sectionTooltip={
                    //             {
                    //                 "subTitle": insightsLbls.activity,
                    //                 "tooltipText": insightsLbls.activitiesOnTutorialGraph,
                    //                 "childern": [
                    //                     {
                    //                         "key": insightsLbls.totalPlayed + ":",
                    //                         "value": insightsLbls.totalNumberOfGuidesPlayed
                    //                     },
                    //                     {
                    //                         "key": insightsLbls.uniquePlayed + ":",
                    //                         "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                    //                     },
                    //                     {
                    //                         "key": insightsLbls.completed + ":",
                    //                         "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                    //                     }
                    //                 ]
                    //             }
                    //         }
                    //         sectionType={'type1'}
                    //         guide_filter={true}
                    //         event_filter={true}
                    //         segment_filter={true}
                    //         tooltip_filter={false}
                    //         component={'guides'}
                    //     ></CustomDashboardModal>
                    // </SectionRefresh>

                }

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.tutorialGuide,
                                    "tooltipText": insightsLbls.activitiesOnTutorialGraph,
                                    "childern": [
                                        {
                                            "key": insightsLbls.totalPlayed + ":",
                                            "value": insightsLbls.totalNumberOfGuidesPlayed
                                        },
                                        {
                                            "key": insightsLbls.uniquePlayed + ":",
                                            "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                                        },
                                        {
                                            "key": insightsLbls.completed + ":",
                                            "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>


                            <span>
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={guideAnalyticsTutorialGuidesChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    segmentsGroup={filterValues.segmentsGroup}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.tutorialGuide}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.activity,
                                            "tooltipText": insightsLbls.activitiesOnTutorialGraph,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.totalPlayed + ":",
                                                    "value": insightsLbls.totalNumberOfGuidesPlayed
                                                },
                                                {
                                                    "key": insightsLbls.uniquePlayed + ":",
                                                    "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                                                },
                                                {
                                                    "key": insightsLbls.completed + ":",
                                                    "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                                }
                                            ]
                                        }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={true}
                                    event_filter={true}
                                    segment_filter={true}
                                    tooltip_filter={false}
                                    component={'guides'}
                                ></CustomDashboardModal>
                            </span>

                        </ContainerTooltip>

                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                            {guideAnalyticsTutorialGuidesChart.guideAnalyticsTutorialGuidesChartDataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    guideAnalyticsTutorialGuidesChart.loadTutorialGuidesChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <>
                                            {!fetched_details?.commonComponentData?.datesData.excludeFlag && (
                                                <p className="weekendsIncluded-p">
                                                    <span>{insightsLbls.weekendsIncluded}</span>
                                                </p>
                                            )}

                                            {guideAnalyticsTutorialGuidesChart.tutorialGuidesChartFetched ?
                                                <>{guideAnalyticsTutorialGuidesChart.tutorialGuidesChart}</> :
                                                (<LineChartTutorialGuides></LineChartTutorialGuides>)
                                            }
                                        </>
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>

                    </CardContainer>
                </div>

                {

                    // <SectionRefresh sectionTitle={insightsLbls.summary} refreshClass={"marginTop-30"}>
                    //     <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(1)}></i>
                    // </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.guideSummary,
                                    "tooltipText": insightsLbls.myGuideTutorialGuidesPlayed,
                                    "childern": [
                                        {
                                            "key": insightsLbls.guideTitle + ":",
                                            "value": insightsLbls.tutorialGuideName
                                        },
                                        {
                                            "key": insightsLbls.guidePlayed + ":",
                                            "value": insightsLbls.totalNoOfTutorialGuidesPlayed
                                        },
                                        {
                                            "key": insightsLbls.guideCompleted + ":",
                                            "value": insightsLbls.totalNumberOfGuidesCompleted
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>
                            <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>
                        {
                            guideAnalyticsSummaryTable.guideAnalyticsSummaryTableDataFetched ? (
                                errorCount.errorCount[1] === 0 ? (
                                    <div className="tabelEditing commonSummaryTable">
                                        <CommonTable tableId="tutorialGuideTable"
                                            data={fetched_details?.tutorialGuides?.guideAnalyticsSummaryTableData.data}
                                            currentPage={guideAnalyticsSummaryTable.page}
                                            barColors=""
                                            linkPresent={true}
                                            showExport={true}
                                            showSearch={true}
                                            showEntries={true}
                                            defaultSort={guideAnalyticsSummaryTable.defaultSort}
                                            tablePagination={guideAnalyticsSummaryTable.tablePagination}
                                            tableEvent={handleTableEvents}
                                            firstTimeloadFlag={fetched_details?.tutorialGuides?.guideAnalyticsSummaryTableData.firstTimeload}
                                            hideDeleted={true}
                                            checkboxText={insightsLbls.showGuideWithActivities}
                                            modalTable={false}
                                        />
                                    </div>
                                )
                                    : errorCount.errorCount[1] === 1 ? (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                                        : null)
                                : (
                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }
                    </CardContainer>
                </div>

            </div>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>

    )
}
export default withRouter(withReducer("tutorialGuides", tutorialGuidesReducer)(TutorialGuides));