
import { CommonUtils } from "./CommonUtils";
import { getCall } from "./ApiCallActions";
import { ChartColors } from "./GlobalConstants";

export const CommonCharts = {
    lineChart: async function (api: any, params: any, sectionHeader: string, guideIds? : any, apiErrorFlag?: string, activeTab?: number) {
        let totalGuideMeCount = 0;
        let totalGuideMeCompleteCount = 0;
        let totalGuideMePlayedCompleteRatio = 0;

        let guideVideoPlay = 0;
        let guideGifPlay = 0;
        let guideSlideshowPlay = 0;
        let guideSlideshowComplete = 0;
        let guideVideoComplete = 0;
        let guideShowMePlay = 0;
        let guideShowMeComplete = 0;


        let guideVideoDownload = 0;
        let guideGifDownload = 0;
        let guideSlideshowDownload = 0;
        let guidePdfDownload = 0;
        let guideDocxDownload = 0;
        let guideHtmlDownload = 0;

        let dataObj: any = {};
        await getCall(params, api[0], apiErrorFlag ?? '', guideIds).then((data: any) => {
            if (data.result === "success" && data.data !== undefined) {

                let lineChartData: any = [];
                let dummyDonutData: any = [];
                let dummyPieData: any = [];


                lineChartData = data.data.graphData;
                lineChartData = CommonUtils.dateSeparator(lineChartData);

                let dummyData: any = [];

                if (totalGuideMeCount !== 0) {
                    totalGuideMePlayedCompleteRatio = totalGuideMeCompleteCount / totalGuideMeCount * 100;
                } else {
                    totalGuideMePlayedCompleteRatio = 0;
                }

                let chartOptions: any = [];
                let itr = 0;

                data.data.legends.map((d: any, j: number) => {
                    if (sectionHeader === 'Duct Tape' || sectionHeader === 'Power Form') {
                        if (d.associatedDataPoint === 'digitalDuctTape') {
                            chartOptions[0] = {};
                            chartOptions[0].key = d.legendTitle;
                            chartOptions[0].values = dummyData[d.associatedDataPoint];
                            chartOptions[0].area = d.area;
                            chartOptions[0].color = d.color;
                            chartOptions[0].associatedDataPoint = d.associatedDataPoint;
                        }

                        if (d.associatedDataPoint === 'powerForm') {
                            chartOptions[1] = {};
                            chartOptions[1].key = d.legendTitle;
                            chartOptions[1].values = dummyData[d.associatedDataPoint];
                            chartOptions[1].area = d.area;
                            chartOptions[1].color = d.color;
                            chartOptions[1].associatedDataPoint = d.associatedDataPoint;
                        }

       
                    } else if (sectionHeader === 'Tooltip & Validation') {
                        if (d.associatedDataPoint !== 'digitalDuctTape') {
                            chartOptions[itr] = {};
                            chartOptions[itr].key = d.legendTitle;
                            chartOptions[itr].values = dummyData[d.associatedDataPoint];
                            chartOptions[itr].area = d.area;
                            chartOptions[itr].color = d.color;
                            chartOptions[itr].associatedDataPoint = d.associatedDataPoint;

                            itr++;
                        }
                    } else if(sectionHeader === 'Power Form & Digital Duct Tape'){
                        if (d.associatedDataPoint === 'digitalDuctTape') {
                            chartOptions[0] = {};
                            chartOptions[0].key = d.legendTitle;
                            chartOptions[0].values = dummyData[d.associatedDataPoint];
                            chartOptions[0].area = d.area;
                            chartOptions[0].color = d.color;
                            chartOptions[0].associatedDataPoint = d.associatedDataPoint;
                        }

                        if (d.associatedDataPoint === 'powerForm') {
                            chartOptions[1] = {};
                            chartOptions[1].key = d.legendTitle;
                            chartOptions[1].values = dummyData[d.associatedDataPoint];
                            chartOptions[1].area = d.area;
                            chartOptions[1].color = d.color;
                            chartOptions[1].associatedDataPoint = d.associatedDataPoint;
                        }

                    } else if ((sectionHeader === 'ShowMe' && api[0] === 'GUIDE_ANALYTICS_SHOW_ME_CHART')) {
                        switch (activeTab) {
                            case 0:
                                if(d.associatedDataPoint === 'guided_showme_play' || d.associatedDataPoint === 'guided_showme_complete' ){
                                    chartOptions[itr] = {};
                                    chartOptions[itr].key =  d.associatedDataPoint === 'guided_showme_play' ? 'Play' : 'Completed';
                                    chartOptions[itr].values = dummyData[d.associatedDataPoint];
                                    chartOptions[itr].area = d.area;
                                    chartOptions[itr].color = d.color;
                                    chartOptions[itr].associatedDataPoint = d.associatedDataPoint;
                                    itr++;
                                }
                                break;
                            case 1:
                                if(d.associatedDataPoint === 'guide_slideshow_play' || d.associatedDataPoint === 'guide_slideshow_complete' ){
                                    chartOptions[itr] = {};
                                    chartOptions[itr].key =  d.associatedDataPoint === 'guide_slideshow_play' ? 'Play' : 'Completed';
                                    chartOptions[itr].values = dummyData[d.associatedDataPoint];
                                    chartOptions[itr].area = d.area;
                                    chartOptions[itr].color = d.color;
                                    chartOptions[itr].associatedDataPoint = d.associatedDataPoint;
                                    itr++;
                                }
                                break;

                            case 2:
                                if(d.associatedDataPoint === 'guide_video_play' || d.associatedDataPoint === 'guide_video_complete' ){
                                    chartOptions[itr] = {};
                                    chartOptions[itr].key =   d.associatedDataPoint === 'guide_video_play' ? 'Play' : 'Completed';
                                    chartOptions[itr].values = dummyData[d.associatedDataPoint];
                                    chartOptions[itr].area = d.area;
                                    chartOptions[itr].color = d.color;
                                    chartOptions[itr].associatedDataPoint = d.associatedDataPoint;
                                    itr++;
                                }
                                break;

                            case 3:
                                if(d.associatedDataPoint === 'guide_gif_play' ){
                                    chartOptions[itr] = {};
                                    chartOptions[itr].key =  d.associatedDataPoint === 'guide_gif_play' ? 'Play' : 'Completed' ;
                                    chartOptions[itr].values = dummyData[d.associatedDataPoint];
                                    chartOptions[itr].area = d.area;
                                    chartOptions[itr].color = d.color;
                                    chartOptions[itr].associatedDataPoint = d.associatedDataPoint;
                                    itr++;
                                }
                                break;
                        
                            default:
                                break;
                        }

                        if (d.associatedDataPoint === 'guided_showme_play' 
                            || d.associatedDataPoint === 'guide_slideshow_play' 
                            || d.associatedDataPoint === 'guide_video_play'
                            || d.associatedDataPoint === 'guide_gif_play') {
                            if (d.associatedDataPoint === 'guided_showme_play') {
                                dummyDonutData.push({ key: d.legendTitle, y: guideShowMePlay, color: ChartColors.color1 })
                            } else if (d.associatedDataPoint === 'guide_slideshow_play') {
                                dummyDonutData.push({ key: d.legendTitle, y: guideSlideshowPlay, color: ChartColors.color2 })
                            } else if (d.associatedDataPoint === 'guide_video_play') {
                                dummyDonutData.push({ key: d.legendTitle, y: guideVideoPlay, color: ChartColors.color3 })
                            } else if (d.associatedDataPoint === 'guide_gif_play') {
                                dummyDonutData.push({ key: d.legendTitle, y: guideGifPlay, color: ChartColors.color4 })
                            }

                        }
                        else if (d.associatedDataPoint === 'guide_video_download'
                            || d.associatedDataPoint === 'guide_gif_download'
                            || d.associatedDataPoint === 'guide_slideshow_download'
                            || d.associatedDataPoint === 'guide_pdf_download'
                            || d.associatedDataPoint === 'guide_docx_download'
                            || d.associatedDataPoint === 'guide_html_download') {
                            if (d.associatedDataPoint === 'guide_video_download') {
                                dummyPieData.push({ key: d.legendTitle, y: guideVideoDownload, color: d.color })
                            } else if (d.associatedDataPoint === 'guide_gif_download') {
                                dummyPieData.push({ key: d.legendTitle, y: guideGifDownload, color: d.color })
                            } else if (d.associatedDataPoint === 'guide_slideshow_download') {
                                dummyPieData.push({ key: d.legendTitle, y: guideSlideshowDownload, color: d.color })
                            } else if (d.associatedDataPoint === 'guide_pdf_download') {
                                dummyPieData.push({ key: d.legendTitle, y: guidePdfDownload, color: d.color })
                            } else if (d.associatedDataPoint === 'guide_docx_download') {
                                dummyPieData.push({ key: d.legendTitle, y: guideDocxDownload, color: d.color })
                            } else if (d.associatedDataPoint === 'guide_html_download') {
                                dummyPieData.push({ key: d.legendTitle, y: guideHtmlDownload, color: d.color })
                            }
                        }
                    }
                    else {
                        chartOptions[j] = {};
                        chartOptions[j].key = d.legendTitle;
                        chartOptions[j].values = dummyData[d.associatedDataPoint];
                        chartOptions[j].area = d.area;
                        chartOptions[j].color = d.color;
                        chartOptions[j].associatedDataPoint = d.associatedDataPoint;

                    }
                    return {};

                })

                let cnt_donut = 0;
                for(let data of dummyDonutData){
                    cnt_donut = cnt_donut + data['y'];
                }

                let cnt_pie = 0;
                for (let data of dummyPieData) {
                    cnt_pie = cnt_pie + data['y'];
                }

                dataObj = {
                    data: lineChartData,
                    serviceAvailable : true,
                    data_fetched: true,
                    options: chartOptions,
                    ratioVal: totalGuideMePlayedCompleteRatio ?? null,
                    totalGuideMeCount: totalGuideMeCount ?? null,
                    totalGuideMeCompleteCount: totalGuideMeCompleteCount ?? null,
                    dummyDonutData: cnt_donut === 0 ? [] : dummyDonutData,
                    dummyPieData: cnt_pie === 0 ? [] : dummyPieData
                }


            } else if (data.result === "retry") {
                dataObj = {
                    serviceAvailable: false,
                    data_fetched: true,
                    retry: true
                }
            }
            else if (data.result === "error") {
                dataObj = {
                    serviceAvailable : false,
                    data_fetched: true,
                    retry: false
                }
            }
        });

        return dataObj;
    },

    barChart: async function (api: any, params: any, sectionHeader: string, apiErrorFlag?: string) {
        let dataObj: any = {};

        await getCall(params, api[0], apiErrorFlag ?? '').then((data: any) => {

            if (data.result === "success" && data.data !== undefined) {

                let barChartData: any = [];

                barChartData = data.data.graphData;
                barChartData = CommonUtils.dateSeparator(barChartData);

                let dummyData: any = [];
                data.data.legends.map((d: any) => {
                    dummyData[d.associatedDataPoint] = [];
                    data.data.graphData.map((optn: any, i: number) => {
                        dummyData[d.associatedDataPoint][i] = {};
                        dummyData[d.associatedDataPoint][i].x = optn.startTimestamp;
                        dummyData[d.associatedDataPoint][i].y = parseInt(optn.counts[d.associatedDataPoint], 10);
                        dummyData[d.associatedDataPoint][i].date = optn.date;
                        dummyData[d.associatedDataPoint][i].startTimeStamp = optn.startTimestamp;
                        dummyData[d.associatedDataPoint][i].associatedDataPoint =  d.associatedDataPoint;
                        
                        return {};
                    })
                    return {};
                })


                let chartOptions: any = [];
                data.data.legends.map((d: any, j: number) => {
                    chartOptions[j] = {};
                    chartOptions[j].key = d.legendTitle;
                    chartOptions[j].values = dummyData[d.associatedDataPoint];
                    chartOptions[j].area = d.area;
                    chartOptions[j].color = d.color;
                    chartOptions[j].associatedDataPoint = d.associatedDataPoint;

                    return {};
                })

                dataObj = {
                    data: barChartData,
                    serviceAvailable : true,
                    data_fetched: true,
                    options: chartOptions,
                }
            } else if (data.result === "retry") {
                dataObj = {
                    serviceAvailable: false,
                    data_fetched: true,
                    retry: true
                }
            }

            else if (data.result === "error") {
                dataObj = {
                    serviceAvailable : false,
                    data_fetched: true,
                }
            }
        });

        return dataObj;
    },

    wordCloudChart: async function (api: any, params: any, apiErrorFlag?: string) {
        let dataObj: any = [];
        
        await getCall(params, api[0], apiErrorFlag ?? '').then((data: any) => {
            if (data.result === "success" && data.data !== undefined) {
                dataObj = {
                    serviceAvailable : true,
                    data: data.data.wordData,
                    data_fetched: true
                }
            } else if (data.result === "retry") {
                dataObj = {
                    serviceAvailable: false,
                    data_fetched: true,
                    retry: true
                }
            }
            else if (data.result === "error") {
                dataObj = {
                    serviceAvailable : false,
                    data_fetched: true,
                }
            }
        });
        return dataObj;
    },

    searchStats: async function (api: any, params: any, apiErrorFlag?: string) {
        let dataObj: any = [];
        await getCall(params, api[1], apiErrorFlag ?? '').then((data: any) => {
            if (data.result === "success" && data.data !== undefined) {
                dataObj = {
                    data: data.data.searchStats,
                    serviceAvailable : true,
                    data_fetched: true, 
                }
            } else if (data.result === "retry") {
                dataObj = {
                    serviceAvailable: false,
                    data_fetched: true,
                    retry: true
                }
            }
            else if (data.result === "error") {
                dataObj = {
                    serviceAvailable : false,
                    data_fetched: true,
                }
            }
        });
        return dataObj;
    }
};
