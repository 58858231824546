
// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect initial load




import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Link } from 'react-router-dom';
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation, useParams } from 'react-router-dom';



// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { CommonComponentState, EventSourceState, LanguageState } from "store/Reducers";

import * as ActionTypes from "../../../store/Actions/ActionTypes";
import { SurveyStatsDetailsState } from "store/Reducers/Survey/surveyStatsDetails";
import surveyStatsDetailsReducer from "store/Reducers/Survey/surveyStatsDetails";
import * as surveyStatsDetailsActions from "store/Actions/index";
import { getCall, getCallExportExcel } from "utils/ApiCallActions";


import { CommonUtils } from "utils/CommonUtils";
import { ChartColors } from "utils/GlobalConstants";
import qs from 'querystring';


// SCSS Imports
import "./StatsDetails.scss";


import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import Loader from "components/Loader/Loader";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import Piechart from "components/Chart/nvd3Charts/Piechart";
import Card from "components/Card/Card";
import SurveyFilter from "components/CommonModal/SurveyFilter/SurveyFilter";
import SurveyShareModal from 'components/ManageSurvey/SurveyShareModal';
import CommonModal from 'components/CommonModal/CommonModal';
import Maintenance from "components/Maintenance/Maintenance";
import BarChart from "components/Chart/nvd3Charts/BarChart";
import SectionFilters from "components/Layouts/SectionFilters";
import { useErrorBoundary } from 'react-error-boundary';
// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }


import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import { InsightLabels } from "labels";
import UserInfoSection from "components/Layouts/UserInfoSection";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";


interface CurrentState {
    commonComponentData: CommonComponentState,
    eventSource: EventSourceState,
    surveyStatsDetails: SurveyStatsDetailsState,
    languageData: LanguageState

}

const StatsDetails: React.FC = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();
    const isInitialMount = useRef(true);

    const location = useLocation();
    const navigate = useNavigate();


    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    let urlData: any = {};

    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });

    const { sentiment_code } = useParams();
    const surveyCode = sentiment_code;

    const dispatch = useDispatch();

    // const [sectionTooltip, setSectionTooltip] = useState([{ title: '', section: [] }]);


    const lineChartMargin = useMemo(() => {
        return {
            top: "50",
            right: "50",
            bottom: "50",
            left: "75",
        };
    }, [])

    let linechart_height = 320;
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    const SurveyStatsDetailsMount = useRef(false);
    const SurveyQuestionsListMount = useRef(false);
    const ShareModalMount = useRef(false);

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0,],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [surveyStatsDetailsBarChart, setSurveyStatsDetailsBarChart] = useState({
        loadBarChartData: [],
        loadBarChartOptions: [],
        surveyStatsDetailsBarChartDataFetched: false,
        barChart: {},
        barChartFetched: false,
        errorMsg: ''
    });

    const [surveyStatsDetailsPieChart, setSurveyStatsDetailsPieChart] = useState({
        pieChartData: [],
        pieChartDataFetched: false,
        pieChartFetched: false,
        pieChart: {},
        errorMsg: ''
    });

    const [surveyStatsDetailsQuestionsList, setSurveyStatsDetailsQuestionsList] = useState({
        surveyStatsDetailsQuestionsListData: [],
        surveyStatsDetailsQuestionsListDataFetched: false,
        firstLoad: true,
        hideDeleted: false,
        errorMsg: ''
    });

    const [shareModal, setShareModal] = useState({
        open: false,
        sentimentTitle: "",
        sentimentCode: "",
        publicUrlString: "Generating...",
        publicUrlFlag: false,
        dataFetched: false
    })


    const [surveyFilter, setSurveyFilter] = useState({
        filter1: "",
        filter2: "",
        filter3: "",
    });

    const [surveyTitle, setSurveyTitle] = useState('');

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
            document.title = insightsLbls['surveyStatsDetailed'] ? insightsLbls['surveyStatsDetailed'] : "";

        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(3, 0, 0, true, false);
    }, [insightsLbls])

    useEffect(() => {

        //To check if redirect flag is avilable when location pathname is "/survey/survey-details/".
        //This flag will only appear once, when survey is clicked from CIO dashboard & will disappear after that.
        //It is added, so that during re-directing, corresponding app of survey should be set in store & in page.
        let queryParams: any = {
            app_id: +urlData['redirect'] === 1 ? urlData['app_id'] : fetched_details?.commonComponentData.appsData.appId,
            app_code: +urlData['redirect'] === 1 ? urlData['app_code'] : fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
        }

        let component_path: any[] = location.pathname.split('/');
        component_path = component_path.filter(function (el) {
            return el != '';
        });

        navigate({
            pathname: "/survey/survey-details/" + component_path[component_path.length - 1] + '/',
            search: qs.stringify(queryParams),
        });

        // && window.location.href.includes('survey-details')
        if (fetched_details?.commonComponentData.datesData.source === 'component' && urlData['app_code'] == localStorage.getItem('switch_app_code')) {
            if (fetched_details?.commonComponentData.datesData.url?.includes('survey-details')) {
                getSurveyStatsDetailsBarChartData();
                getSurveyStatsDetailsQuestionsListData();
            }
        }
    }, [fetched_details?.commonComponentData.datesData]);


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getSurveyStatsDetailsBarChartData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "sentiment_code": surveyCode,
            "event_source": fetched_details.eventSource.eventSourceGuides,
            'guide_id': surveyFilter.filter1,
            'step_id': surveyFilter.filter2,
            'trigger_source': surveyFilter.filter3,
            'exclude_weekends': false,
            "app_id": fetched_details?.commonComponentData.appsData.appId
        };

        surveyStatsDetailsActions.getSurveyStatsDetailsData(
            params,
            "SURVEY_STATS_DETAILS_BAR_CHART",
            ActionTypes.SET_SURVEY_STATS_DETAILS_BAR_CHART_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    };

    const getSurveyStatsDetailsQuestionsListData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            'guide_id': surveyFilter.filter1,
            'step_id': surveyFilter.filter2,
            'trigger_source': surveyFilter.filter3,
            'sentiment_code': surveyCode,
            'hide_deleted_questions': surveyStatsDetailsQuestionsList.hideDeleted,
            'event_source': fetched_details.eventSource.eventSourceGuides,
            'exclude_weekends': false,
            "app_id": fetched_details?.commonComponentData.appsData.appId
        };

        surveyStatsDetailsActions.getSurveyStatsDetailsData(
            params,
            "SURVEY_STATS_DETAILS_QUESTIONS_LIST",
            ActionTypes.SET_SURVEY_STATS_DETAILS_QUESTIONS_LIST_DATA,
            apiErrorFlag ?? '',
            surveyStatsDetailsQuestionsList.firstLoad
        ).then((res: any) => dispatch(res));
    };

    const exportExcel = (data: any) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            'guide_id': surveyFilter.filter1,
            'step_id': surveyFilter.filter2,
            'trigger_source': surveyFilter.filter3,
            'sentiment_code': surveyCode,
            'hide_deleted_questions': surveyStatsDetailsQuestionsList.hideDeleted,
            'event_source': fetched_details.eventSource.eventSourceGuides,
            'exclude_weekends': false,
            "app_id": fetched_details?.commonComponentData.appsData.appId
        }
        getCallExportExcel(params, 'SURVEY_STATS_DETAILS_QUESTIONS_LIST', data);
    }

    const getPublicUrl = useCallback((data: any) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "sentiment_code": data.surveyCode,
            "allow_anonymous_response": data.viewFlag,
            "link_expiry": data.linkExpiry,
            "sentiment_title": shareModal.sentimentTitle,
            "app_id": fetched_details?.commonComponentData.appsData.appId
        };


        getCall(params, 'PUBLIC_URL', '').then((data: any) => {
            if (data.result === 'success') {
                setShareModal((prevData: any) => {
                    return {
                        ...prevData,
                        publicUrlString: data.data.url,
                        publicUrlFlag: true,
                    }
                })

            }
            else if (
                data.result === "error"
            ) {
                setShareModal((prevData: any) => {
                    return {
                        ...prevData,
                        publicUrlString: "Couldn't fetch the URL.",
                        publicUrlFlag: false,
                    }
                })
            }
        });
    }, [])

    //Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setSurveyStatsDetailsBarChart((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsDetailsBarChartDataFetched: false,
                        barChartFetched: false,
                        barChart: {}
                    };
                });
                setSurveyStatsDetailsPieChart((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: false,
                        pieChartFetched: false,
                        pieChart: {}
                    };
                });
                getSurveyStatsDetailsBarChartData();

            } else if (optn === 1) {
                setSurveyStatsDetailsQuestionsList((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsDetailsQuestionsListDataFetched: false,
                        firstLoad: true,
                        hideDeleted: false,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const getFilterValues = useCallback((triggerSource: string, guideId: number, stepId: number) => {
        try {
            setSurveyFilter((prevState: any) => {
                return {
                    ...prevState,
                    filter3: triggerSource,
                    filter1: guideId !== 0 ? (guideId) : (""),
                    filter2: stepId !== 0 ? (stepId) : (""),
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }, [surveyFilter]);

    const handleCardEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {

                case "hideDeleted":
                    setSurveyStatsDetailsQuestionsList((prevState: any) => {
                        return {
                            ...prevState,
                            firstLoad: false,
                            hideDeleted: data
                        }
                    });
                    break;

                case "exportExcel":
                    exportExcel(data);
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [surveyStatsDetailsQuestionsList]);

    const openShareModal = (title: any, code: any) => {
        try {
            ShareModalMount.current = true;
            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                    sentimentTitle: title,
                    sentimentCode: code,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }
    const closeShareModal = () => {
        try {
            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    // UseEffects @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    //Bar Chart
    useEffect(() => {
        try {
            if (
                fetched_details.surveyStatsDetails.surveyStatsDetailsBarChartData.data !== undefined &&
                fetched_details.surveyStatsDetails.surveyStatsDetailsBarChartData.result === "success"
            ) {
                SurveyStatsDetailsMount.current = true;
                let surveyBarChartData: any = [];

                surveyBarChartData = fetched_details.surveyStatsDetails.surveyStatsDetailsBarChartData.data;
                surveyBarChartData = CommonUtils.dateSeparator(surveyBarChartData);
                renderPieChart(surveyBarChartData);
                setSurveyStatsDetailsBarChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadBarChartData: [...surveyBarChartData],
                        surveyStatsDetailsBarChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true)

            } else if (fetched_details.surveyStatsDetails.surveyStatsDetailsBarChartData.result === 'retry') {
                getSurveyStatsDetailsBarChartData('retry');
            } else if (
                fetched_details.surveyStatsDetails.surveyStatsDetailsBarChartData.result === "error"
            ) {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.surveyStatsDetails.surveyStatsDetailsBarChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setSurveyStatsDetailsBarChart((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsDetailsBarChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(0, errorFlag);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.surveyStatsDetails.surveyStatsDetailsBarChartData]);

    // List
    useEffect(() => {
        try {
            if (
                fetched_details.surveyStatsDetails.surveyStatsDetailsQuestionsListData.data !== undefined &&
                fetched_details.surveyStatsDetails.surveyStatsDetailsQuestionsListData.result === "success"
            ) {
                SurveyQuestionsListMount.current = true;
                setSurveyStatsDetailsQuestionsList((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsDetailsQuestionsListData: fetched_details.surveyStatsDetails.surveyStatsDetailsQuestionsListData.data.responseDetails,
                        surveyStatsDetailsQuestionsListDataFetched: true,
                        errorMsg: ''
                    };
                });

                setSurveyTitle(fetched_details.surveyStatsDetails.surveyStatsDetailsQuestionsListData.data.sentimentTitle);
                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.surveyStatsDetails.surveyStatsDetailsQuestionsListData.result === 'retry') {
                getSurveyStatsDetailsQuestionsListData('retry');
            } else if (
                fetched_details.surveyStatsDetails.surveyStatsDetailsQuestionsListData.result === "error"
            ) {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.surveyStatsDetails.surveyStatsDetailsQuestionsListData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setSurveyStatsDetailsQuestionsList((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsDetailsQuestionsListDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(1, errorFlag);
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.surveyStatsDetails.surveyStatsDetailsQuestionsListData]);

    //SurveyFilter
    useEffect(() => {
        try {
            if (
                SurveyStatsDetailsMount.current &&
                SurveyQuestionsListMount.current
            ) {
                enableExportPdf(0, false);

                setSurveyStatsDetailsBarChart((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsDetailsBarChartDataFetched: false,
                        barChartFetched: false,
                        barChart: {}
                    };
                });

                getSurveyStatsDetailsBarChartData();



                enableExportPdf(1, false);
                setSurveyStatsDetailsQuestionsList((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsDetailsQuestionsListDataFetched: false,
                        firstLoad: true,
                        hideDeleted: false
                    };
                });

                getSurveyStatsDetailsQuestionsListData();

            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        surveyFilter.filter1,
        surveyFilter.filter2,
        surveyFilter.filter3,])

    // Call summary table api on table related actions changes

    useEffect(() => {
        try {
            if (fetched_details?.surveyStatsDetails?.surveyStatsDetailsQuestionsListData?.data) {
                enableExportPdf(1, false);
                getSurveyStatsDetailsQuestionsListData();

            }

        } catch (error) {
            showBoundary(error)
        }

        // surveyStatsDetailsQuestionsList.surveyStatsDetailsQuestionsListDataFetched removed from dependancy list
        // to avoid multiple api calls on component load
    }, [
        surveyStatsDetailsQuestionsList.firstLoad,
        surveyStatsDetailsQuestionsList.hideDeleted,

    ]);



    // Export PDF Btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])


    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])

    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const renderPieChart = (data: any) => {
        try {
            let total: any = 0;

            for (let i = 0; i < data.length; i++) {
                total += data[i].counts.pos + data[i].counts.neg + data[i].counts.neu;
                if (total) break;
            }

            let pieDataAvailable: boolean = false;

            total === 0 ? (pieDataAvailable = false) : (pieDataAvailable = true)

            setSurveyStatsDetailsPieChart((prevState: any) => {
                return {
                    ...prevState,
                    pieChartData: [...data],
                    pieChartDataFetched: pieDataAvailable,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const getBarData = useCallback(
        () => {
            return surveyStatsDetailsBarChart.loadBarChartData;
        },
        [surveyStatsDetailsBarChart.loadBarChartData]
    );

    const BarChartSurvey = useCallback(() => {
        try {
            setSurveyStatsDetailsBarChart((prevState: any) => {
                return {
                    ...prevState,
                    barChart: <BarChart
                        chartEntities={['pos', 'neu', 'neg']}
                        optionEntities={
                            [
                                {
                                    key: (JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) && insightsLbls['positive'],
                                    color: ChartColors.lightgreen,
                                    associatedDataPoint: "pos",
                                    area: true
                                },
                                {
                                    key: (JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) && insightsLbls['neutral'],
                                    color: ChartColors.yellowsandybrown,
                                    associatedDataPoint: "neu",
                                    area: true
                                },
                                {
                                    key: (JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) && insightsLbls['negative'],
                                    color: ChartColors.brightred,
                                    associatedDataPoint: "neg",
                                    area: true
                                }
                            ]}
                        chartData={getBarData()}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls['yAxisLabelCount']}
                        refId="statsDetail_barchart"
                        parseIntFlag={true}>
                    </BarChart>,
                    barChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [lineChartMargin, linechart_height, getBarData]);



    const getPieData = useCallback(
        () => {

            return surveyStatsDetailsPieChart.pieChartData
        },
        [surveyStatsDetailsPieChart.pieChartData]
    );

    const PieChartSurvey = useCallback(() => {
        try {
            setSurveyStatsDetailsPieChart((prevState: any) => {
                return {
                    ...prevState,
                    pieChart: <Piechart
                        chartData={getPieData()}
                        chartEntities={['pos', 'neu', 'neg']}
                        optionEntities={[
                            {
                                key: (JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) && insightsLbls['positive'],
                                associatedDataPoint: 'pos',
                                color: ChartColors.lightgreen
                            }, 
                            {
                                key: (JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) && insightsLbls['neutral'],
                                associatedDataPoint: 'neu',
                                color: ChartColors.yellowsandybrown
                            },
                            {
                                key: (JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) && insightsLbls['negative'],
                                associatedDataPoint: 'neg',
                                color: ChartColors.brightred
                            },
                        ]}
                        valueFormat={'value'}
                        height={300}
                        width={300}
                        showLegends={true}
                        chartType={'pie'}
                        labelType={'percent'}
                        aggRequired={true}>
                    </Piechart>,
                    pieChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }


    }, [getPieData]);

    const navigateToMainPage = () => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
        }
        navigate({
            pathname: `/survey/survey/`,
            search: qs.stringify(queryParams)
        })
    }

    return (
        <section className="demo surveyStatsDetails width100" ref={componentRef}>


            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li>
                            <span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls['survey']}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </li>
                        <li className="active">{insightsLbls['surveyDetails']}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <SurveyFilter surveyCode={surveyCode} guideId={surveyFilter.filter1} stepId={surveyFilter.filter2}
                        triggerSource={surveyFilter.filter3} setFilterValues={getFilterValues}></SurveyFilter>
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={surveyTitle} svgImage={"surveys"} className={'marginTop-50'}>
                <button className="secondary-btn-square cursorPointer positionAbsolute right145 top10" onClick={() => openShareModal(surveyTitle, surveyCode)}>
                    <span className="displayFlex alignCenter justifyContent">
                        <i className="fa fa-external-link" aria-hidden="true"></i>
                        {insightsLbls['share']}
                    </span>
                </button>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"Survey Stats Details"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Survey Stats Details"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                messageText={surveyTitle}>

                <div className="displayInlineFlex">
                    <button className="secondary-btn-square cursorPointer positionAbsolute right145 top10" onClick={() => openShareModal(surveyTitle, surveyCode)}>
                        <span className="displayFlex alignCenter justifyContent">
                            <i className="fa fa-external-link" aria-hidden="true"></i>
                            {insightsLbls['share']}
                        </span>
                    </button>
                </div>
            </UserInfoSection>




            <div id="surveyStatsDetailsSection">


                {
                    // <SectionRefresh sectionTitle={insightsLbls.surveyDetails} refreshClass={""}>
                    //     <i
                    //         className="fa fa-refresh pull-right pointer row displayFlex"
                    //         aria-hidden="true" title={insightsLbls['refresh']}
                    //         onClick={() => refreshSections(0)}>
                    //     </i>
                    // </SectionRefresh>
                }

                <div className="col-lg-12 page_section paddingLeft-10 paddingRight-10 justifyCenter height535 displayFlex">
                    <CardContainer div1Classes={"col-md-6 col-xs-6 table-container padding0 marginRight-10"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 height100-percent"}
                        div3Classes={"col-sm-12 minHeight456 height100-percent padding0 row marginLR-0"}>

                        <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section minHeight456">
                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.responseDistribution,
                                        "tooltipText": insightsLbls.graphPercentageSurevey,
                                        "childern": [
                                            {
                                                "key": insightsLbls.positive + ":",
                                                "value": insightsLbls.countPositiveResponses
                                            },
                                            {
                                                "key": insightsLbls.neutral + ":",
                                                "value": insightsLbls.countNeutralResponses
                                            },
                                            {
                                                "key": insightsLbls.negative + ":",
                                                "value": insightsLbls.countNegativeResponses
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                                <button className="pointer refreshBtn" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
                            </ContainerTooltip>

                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                {surveyStatsDetailsBarChart.surveyStatsDetailsBarChartDataFetched ? (
                                    errorCount.errorCount[0] === 0 ? (
                                        surveyStatsDetailsBarChart.loadBarChartData.length === 0 ? (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <div className="noDataText-font textCenter-absolute">
                                                        {insightsLbls.noRecordFound}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            surveyStatsDetailsBarChart.barChartFetched ? <>{surveyStatsDetailsBarChart.barChart}</> :
                                                (<BarChartSurvey></BarChartSurvey>)
                                        )
                                    ) : (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {surveyStatsDetailsBarChart.errorMsg}
                                                </h5>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                      


                    </CardContainer>

                    <CardContainer div1Classes={"col-md-6 col-xs-6 table-container padding0 marginLeft-10"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 minHeight456 height100-percent padding0 row marginLR-0"}>


                        <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section ">
                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.stats,
                                        "tooltipText": insightsLbls.pieChartPosNegNeu,
                                        "childern": []
                                    }
                                } placement="left"></SectionTooltip>
                                <button className="pointer refreshBtn" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>

                            </ContainerTooltip>
                            <div className="displayFlex col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                {surveyStatsDetailsBarChart.surveyStatsDetailsBarChartDataFetched ? (
                                    errorCount.errorCount[0] === 0 ? (
                                        surveyStatsDetailsPieChart.pieChartDataFetched ? (

                                            surveyStatsDetailsPieChart.pieChartFetched ? <>{surveyStatsDetailsPieChart.pieChart}</> :
                                                (<PieChartSurvey></PieChartSurvey>)
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <div className="noDataText-font textCenter-absolute">
                                                        {insightsLbls.noRecordFound}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {surveyStatsDetailsPieChart.errorMsg}
                                                </h5>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    </CardContainer>
                </div>
                {
                    // <SectionRefresh sectionTitle={insightsLbls.surveyQuestions} refreshClass={"marginTop-30"}>
                    //     {/* <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(1)}></i> */}
                    // </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                    
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section displayContent">

                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.surveyQuestions,
                                        "tooltipText": insightsLbls['surveyResponses'],
                                        "childern": []
                                    }
                                } placement="right"></SectionTooltip>
                                {/* <button className="pointer refreshBtn" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button> */}
                            </ContainerTooltip>


                            {surveyStatsDetailsQuestionsList.surveyStatsDetailsQuestionsListDataFetched ? (
                                errorCount.errorCount[1] === 0 ? (
                                    surveyStatsDetailsQuestionsList.surveyStatsDetailsQuestionsListData.length === 0 ? (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.noRecordFound}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <Card
                                            cardId="surveyStatsDetail"
                                            data={surveyStatsDetailsQuestionsList.surveyStatsDetailsQuestionsListData}
                                            cardEvent={handleCardEvents}
                                            checkboxText={insightsLbls['hideDeleted']}
                                            hideDeleted={true}
                                            showExport={true}
                                            // clickEvent={handleClick}
                                            // showEntries={true}
                                            // showEventType={true}
                                            // guideTitle={guideDetail.guideName}
                                            firstTimeloadFlag={
                                                surveyStatsDetailsQuestionsList.firstLoad
                                            }>
                                        </Card>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {surveyStatsDetailsQuestionsList.errorMsg}
                                            </h5>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}


                        </div>
                    </CardContainer>
                </div>

            </div>

            <CommonModal
                modalState={shareModal.open}
                dispatchModalState={closeShareModal}
                modalTitle={insightsLbls && insightsLbls['shareSurvey']}
                footerDisabled={false}
                exportModal={false}
                padding={true}
                dispatchModalOk={closeShareModal}
                okBtn={insightsLbls['close']}
                size={'md'}
                dialogClassName={'maxWidth650'}
            >
                <SurveyShareModal
                    surveyTitle={shareModal.sentimentTitle}
                    surveyCode={shareModal.sentimentCode}
                    surveyURL={shareModal.publicUrlString}
                    publicURLFlag={shareModal.publicUrlFlag}
                    getEvent={getPublicUrl}>
                </SurveyShareModal>

            </CommonModal>


            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>

    )
}
export default withRouter(withReducer("surveyStatsDetails", surveyStatsDetailsReducer)(StatsDetails));
