import React, { useState, useEffect, useCallback, useRef } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';
import RoutePaths from "routes/Routes";
import moment from 'moment';
import qs from "querystring";
import { ApiRelativePaths, DateSelection } from '../../utils/GlobalConstants';
import axios from "axios";
import { CommonUtils } from "utils/CommonUtils";
import { setCommonComponent, setHandOffDetails, setSectionVisibility } from "store/Actions";
import { HandoffState, AppsState, DatesState, StatusFilterState, EventSourceState, SegmentsFilterState, EnvFilterState, LanguageState } from "store/Reducers";
// Components
import Header from "../../components/Header/Header";
import SideNav from "../../components/SideNav/SideNav";
import Maintenance from "../../components/Maintenance/Maintenance";
import SwitchLoader from "../SwitchLoader/SwitchLoader";
import { setDates } from "store/Actions";
import axiosInstance from 'network/apis/interceptor';
// SCSS
import "./SiteLayout.scss";
import "../../style.scss";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader/Loader";

import { InsightLabels } from "labels";

import { setLanguage } from "store/Actions";
import { useErrorBoundary } from 'react-error-boundary';
import { getCall } from "utils/ApiCallActions";
import { setDashboards } from "store/Actions";


let previousPath;
let currentPath;
let urlData: any = {};

interface CurrentState {
    handoff: HandoffState,
    apps: AppsState,
    dates: DatesState,
    statusFilter: StatusFilterState,
    segmentsFilter: SegmentsFilterState,
    eventSource: EventSourceState,
    envFilter: EnvFilterState,
    languageData: LanguageState

}

const SiteLayout = (props: any) => {

    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    let fetchDetails = useSelector((state: CurrentState) => state);
    const dispatch = useDispatch();

    const hasFetched = useRef(false); 

    const location = useLocation();
    const navigate = useNavigate();



    let ApiPaths: any = ApiRelativePaths;

    const d = new Date();
    let currentYear = d.getFullYear();

    const [data, setData] = useState({
        handOffProcess: true,
        showMaintenanceSection: false,
        showLoader: false
    })

    useEffect(() => {
        if (fetchDetails.languageData.languageData) {
            setInsightsLbls(fetchDetails.languageData.languageData);
        }

    }, [fetchDetails.languageData.languageData])
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // dispatch loader
    useEffect(() => {
        try {
            if (fetchDetails.dates.url) {
                if (location.pathname.includes('guide-insights') && fetchDetails.segmentsFilter.listFetched && fetchDetails.envFilter.listFetched) {
                    let commonComponentData = {
                        datesData: fetchDetails.dates,
                        stateFilterData: fetchDetails.statusFilter,
                        appsData: fetchDetails.apps,
                        eventSource: fetchDetails?.eventSource,
                        segmentsFilter: fetchDetails.segmentsFilter,
                        envFilter: fetchDetails.envFilter
                    }
                    dispatch(setCommonComponent(commonComponentData));
                } else {
                    let commonComponentData = {
                        datesData: fetchDetails.dates,
                        stateFilterData: fetchDetails.statusFilter,
                        eventSource: fetchDetails?.eventSource,
                        appsData: fetchDetails.apps,
                        segmentsFilter: fetchDetails.segmentsFilter,
                        envFilter: fetchDetails.envFilter
                    }
                    dispatch(setCommonComponent(commonComponentData));
                }


                if (location.pathname.includes('goal')) {
                    if (fetchDetails.apps.appId == +(localStorage.getItem('switch_app_id')!)) {
                        const commonComponentData = {
                            datesData: fetchDetails.dates,
                            stateFilterData: fetchDetails.statusFilter,
                            appsData: fetchDetails.apps
                        }
                        dispatch(setCommonComponent(commonComponentData));
                    }
                }
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [fetchDetails.dates.fdate,
    fetchDetails.dates.tdate,
    fetchDetails.dates.excludeFlag,
    fetchDetails.dates.realTimeCheck,
    fetchDetails.dates.appId,
    fetchDetails.dates.url,
    fetchDetails.envFilter.listFetched,
    fetchDetails.envFilter.envCode,
    fetchDetails.statusFilter.stateFilter,
    fetchDetails?.eventSource,
    fetchDetails?.segmentsFilter.listFetched,
    fetchDetails?.segmentsFilter.segmentsFilter,
    location.pathname,
    ])

    useEffect(() => {
        if (localStorage.getItem('IS_AUTHENTICATED') === 'true' && !hasFetched.current) {
            hasFetched.current = true; // Set to true to prevent further calls
            localStorage.removeItem('WEB_APP_LANGUAGE');
            const urlData = process.env.REACT_APP_ANALYTICS_HOST + ApiRelativePaths.GET_LANGUAGE;
            axiosInstance
                .get(urlData)
                .then((res) => {
                    if (res.data.status === 'success') {
                        localStorage.setItem('WEB_APP_LANGUAGE', res.data.data['preferredLanguage']['UILanguagePreference']);
                        dispatch(setLanguage(CommonUtils.getLanguageChangeData()));
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, []);



    useEffect(() => {
        try {
            const query = new URLSearchParams(location.search);
            query.forEach((v, k) => {
                urlData[k] = v;
            });

            // && urlData['app_code'].length
            if (urlData['handoff-token']) {

                localStorage.removeItem("TDATE");
                localStorage.removeItem("FDATE");
                localStorage.removeItem("SELECTED_OPTN");
                localStorage.removeItem('ORG_SWITCH_ID');
                localStorage.removeItem('DASHBOARD_APP_LIST')
                localStorage.removeItem('switch_app_code');
                localStorage.removeItem('switch_app_id');


                setHandoffToken(urlData['handoff-token']);

                if (!location.pathname.includes('dashboard')) {
                    localStorage.setItem('switch_app_code', urlData['app_code']);
                    localStorage.setItem('switch_app_id', urlData['app_id']);
                }

                localStorage.setItem('IS_AUTHENTICATED', 'true');
                localStorage.setItem("TDATE", moment(new Date()).format("YYYY-MM-DD"));
                localStorage.setItem("FDATE", moment(new Date()).subtract(DateSelection.defaultDays - 1, 'days').format("YYYY-MM-DD"));
                localStorage.setItem("SELECTED_OPTN", DateSelection.defaultSelection.toString());
                localStorage.setItem("WEEKEND_TEXT", 'Include Weekends');
                localStorage.setItem('SECONDSFLAG', 'true');

                setData((prevState: any) => {
                    return {
                        ...prevState,
                        showLoader: false,
                        showMaintenanceSection: false,
                        handOffProcess: true
                    }
                });

                dispatch(setHandOffDetails({ setHandoff: true }));

            } else {
                setData((prevState: any) => {
                    return {
                        ...prevState,
                        showLoader: false,
                        showMaintenanceSection: false,
                        handOffProcess: false
                    }
                });

                dispatch(setHandOffDetails({ setHandoff: false }));
            }

            if ((location.pathname.includes('login') || location.pathname.includes('otp') || location.pathname.includes('password'))
                && localStorage.getItem('SESSION_EXP') === 'false') {

                let path = '';
                if (localStorage.getItem('GUIDEINSIGHTS_VISIBILITY') == 'false' && localStorage.getItem('APPINSIGHTS_VISIBILITY') == 'false') {
                    path = `/survey/manage-survey/`;
                } else {
                    path = `/dashboard/`;
                }

                let queryParams = {
                };

                navigate({
                    pathname: path,
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }



    }, []); // location.search (ADDED can be femoved)


    useEffect(() => {
        try {
            if (window.location.href.includes('isMaintenance=true')) {
                setData((prevState: any) => {
                    return {
                        ...prevState,
                        showMaintenanceSection: true
                    }
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [window.location.href])

    // Handoff token
    const setHandoffToken = useCallback((token: string) => {
        let dupURL = "";
        try {
            let params = {
                "session_token": token,
            };

            let data = {}
            let path = ApiPaths['HANDOFF_TOKEN'];
            let paramObj = {};
            let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);

            axios.get(generatedURL, {
                headers: {
                    'AppKey': process.env.REACT_APP_APP_KEY,
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                if (res.data.status === 'success') {

                    let data = res.data;

                    localStorage.setItem('EMAIL_ID', data.data.user.user_email ?? '');
                    localStorage.setItem('ORG_SWITCH_ID', data.data.payload.organization_id);


                    const sectionData = CommonUtils.storeAuthData(data.data.user);

                    let urlData: any = {};
                    const query = new URLSearchParams(location.search);
                    query.forEach((v, k) => {
                        urlData[k] = v;
                    });

                    let dateValues: object = {
                        tdate: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
                        fdate: moment(new Date()).subtract(DateSelection.defaultDays, "days").format("YYYY-MM-DD"),
                        dateSelection: DateSelection.defaultSelection,
                        excludeFlag: false,
                        source: 'component',
                        url: location.pathname,
                        realTimeCheck: localStorage.getItem('REAL_TIME_CHECK') === 'true' ? true : false,
                        realTime_tdate: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
                        appId: urlData['app_id'],
                        appCode: urlData['app_code']

                    };

                    dispatch(setDates(dateValues));

                    setData((prevState: any) => {
                        return {
                            ...prevState,
                            showLoader: false,
                            showMaintenanceSection: false,
                            handOffProcess: false
                        }
                    });

                    let base_url = window.location.href.split('?')[0];
                    let base_params: any = window.location.href.split('?')[1];


                    base_params = base_params.split('&');
                    let new_base_param: any = [];
                    for (let itr0 = 0; itr0 < base_params.length; itr0++) {
                        if (!(base_params[itr0].indexOf('handoff-token') >= 0)) {
                            new_base_param.push(base_params[itr0])
                        }
                    }
                    let url = base_url + '?' + new_base_param.join('&');
                    dupURL = url;
                    // if (url.includes('&handoff-token')) {
                    //     // url = url.split('&handoff-token')[0];
                    // 
                    CommonUtils.routeOnLogin();
                    let section_data = {
                        superAdmin: sectionData.superAdmin,
                        isPITrackingEnabled: sectionData.isPITrackingEnabled,
                        myBot_flag: sectionData.myBot_flag,
                        guideInsights_flag: sectionData.guideInsights_flag,
                        appInsights_flag: sectionData.appInsights_flag,
                        qaAutomator_flag: sectionData.qaAutomator_flag,
                    }

                    if (localStorage.getItem('USER_ROLE') === 'creator') {
                        section_data['guideInsights_flag'] = false;
                        section_data['appInsights_flag'] = false;
                    }

                    dispatch(setSectionVisibility(section_data));

                    // window.open(url, "_self");


                    // setTimeout(() => {
                    //     // window.location.href = url
                    // window.location.reload();
                    // },5000)
                    if (url.includes('dashboard')) {
                        let params = {};

                        getCall(params, "CREATE_DASHBOARD", '').then((data: any) => {
                            if (data.result === 'success') {
                                let defaultDash = '/dashboard/';
                                for (let item of data.data) {
                                    if (item['isDefault']) {
                                        defaultDash = '/custom-dashboard/' + item['dashboardNumber'] + '/';
                                        break;
                                    }
                                }

                                let queryParams = {
                                    app_id: "",
                                };

                                navigate({
                                    pathname: defaultDash,
                                    search: qs.stringify(queryParams),
                                });

                                const dashboardData: any = data.data;
                                const dummyData: any = [];
                                for (let i = 0; i < dashboardData.length; i++) {
                                    dummyData[i] = {
                                        ['dashboard' + (i + 1)]: {
                                            active: true,
                                            name: dashboardData[i].profileName,
                                            profileID: dashboardData[i].profileID,
                                            isDefault: dashboardData[i].isDefault,
                                            dashboardIcon: dashboardData[i].dashboardIcon,
                                            profileDetails: dashboardData[i].profileDetails
                                        }
                                    }
                                }
                                
                                dispatch(setDashboards(dummyData))

                                window.location.reload();
                            } else {
                                window.open(url, "_self");
                            }
                        });

                        if (localStorage.getItem('IS_AUTHENTICATED') === 'true') {
                            localStorage.removeItem('WEB_APP_LANGUAGE');
                            let urlData = process.env.REACT_APP_ANALYTICS_HOST + ApiRelativePaths.GET_LANGUAGE;
                            axiosInstance.get(urlData)
                                .then(res => {
                                    if (res.data.status === 'success') {
                                        localStorage.setItem('WEB_APP_LANGUAGE', res.data.data['preferredLanguage']['UILanguagePreference']);
                                        dispatch(setLanguage(CommonUtils.getLanguageChangeData()));
                                    }
                                })
                                .catch(err => {
                                });
                        }
                    }


                    if (localStorage.getItem('IS_AUTHENTICATED') === 'true') {
                        localStorage.removeItem('WEB_APP_LANGUAGE');
                        let urlData = process.env.REACT_APP_ANALYTICS_HOST + ApiRelativePaths.GET_LANGUAGE;
                        axiosInstance.get(urlData)
                            .then(res => {
                                if (res.data.status === 'success') {
                                    localStorage.setItem('WEB_APP_LANGUAGE', res.data.data['preferredLanguage']['UILanguagePreference']);
                                    dispatch(setLanguage(CommonUtils.getLanguageChangeData()));
                                }
                            })
                            .catch(err => {
                            });
                    }
                }
            })
                .catch(err => {
                    // history.push({
                    //     pathname: `/login/`,
                    // })
                    // window.location.reload();
                    <data className="handoffProcess"></data>
                    if (data["handOffProcess"]) {
                        navigate({
                            pathname: "/login/",
                        });
                        window.location.reload();
                        window.open(dupURL, "_self");
                        setTimeout(() => {
                            //     // window.location.href = url
                            window.location.reload();
                        }, 5000)
                    }
                })

        } catch (error) {
            showBoundary(error)
        }
    }, []);


    const hideRealtimeInfo = () => {
        try {
            const elem = document.getElementById('realTime_info_div') as HTMLDivElement;
            if (elem) {
                elem.style.display = 'none';
            }

            localStorage.setItem('REALTIME_INFO_VISIBILITY', 'false');
        } catch (error) {
            showBoundary(error)
        }
    }



    return (
        <div>


            {!data.handOffProcess ?
                (<div>
                    <Header />
                    <SideNav />

                    {(localStorage.getItem('REALTIME_INFO_VISIBILITY') === 'true' && localStorage.getItem('ENABLE_REAL_TIME') === 'true') && (
                        <div className="realtimeInfo" id="realTime_info_div">
                            <div className="infoDiv">
                                <p className="iconP">
                                    <i className="fa fa-times cursorPointer" aria-hidden="true" onClick={() => hideRealtimeInfo()}></i>
                                </p>
                                <p className="infoP">
                                    {insightsLbls.reaTimeDateCondition}
                                </p>
                            </div>
                        </div>
                    )}

                    <div className="right-panel">
                        <div className="mainSection">
                            {data.showMaintenanceSection
                                ? (<Maintenance></Maintenance>)
                                : (<RoutePaths />)
                            }
                        </div>

                        {/* footer */}
                        <div className="footer">
                            <p className="pull-left">&#169; Cornerstone 2024 &#8226;&nbsp;</p>
                            <p className="pull-left marginLeft-footer-0">{insightsLbls.version}</p>
                            <p className="pull-right">{insightsLbls.supportLinkText}&nbsp;
                                <b>
                                    <a href={insightsLbls.supportLink} className="supportLink">
                                        {insightsLbls.displaySupportLink}
                                    </a>
                                </b>
                            </p>
                        </div>
                    </div>
                </div>)
                : (<section className="mainSection width100 paddingT-300">
                    <Loader></Loader>
                </section>)
            }
        </div>
    )
}


export default withRouter(SiteLayout);