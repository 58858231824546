/**
 * @author mtiwari
 * Updated on 21 May, 2024
 * Updated key structure while calling Type1 component and added marginTop for export pdf to prevent overlapping of breadcrumb
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { toast } from "react-toastify";


// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppsState, DatesState, EventSourceState, DashboardsState, LanguageState, CommonComponentState } from "store/Reducers";
import { getCall, postCall } from "utils/ApiCallActions";
import { setDashboards } from "store/Actions";


import qs from "querystring";
import { CommonUtils } from "../../utils/CommonUtils";
import { ApiRelativePaths, RetryApi } from "../../utils/GlobalConstants";


import SectionHeader from "../../components/Layouts/SectionHeader";
import SectionButtons from "../../components/Layouts/SectionButtons";
import Datepicker from "../../components/Calender/Datepicker";
import ExportToPdf from "../../components/ExportToPdf/ExportToPdf";
import Loader from 'components/Loader/Loader';
import Type1 from "components/Layouts/SectionLayouts/Type1";
import { useErrorBoundary } from 'react-error-boundary';

// SCSS Imports
import "./CustomDashboard.scss";

import SectionFilters from "components/Layouts/SectionFilters";

import { InsightLabels } from "labels";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import UserInfoSection from "components/Layouts/UserInfoSection";

interface CurrentState {
    apps: AppsState,
    dates: DatesState,
    eventSource: EventSourceState,
    dashboards: DashboardsState,
    languageData: LanguageState
    commonComponentData: CommonComponentState;

}

const CustomDashboard: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const isInitialMount = useRef(true);

    const { dashboardNumber } = useParams();
    const navigate = useNavigate();
    const location = useLocation();


    const dispatch = useDispatch();

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    let fetched_dashboard_details = useSelector((state: CurrentState) => state.dashboards);
    let fetched_details = useSelector((state: CurrentState) => state);



    // const [dashboardState, setDashboardState] = useState<DashboardsState>(fetched_dashboard_details)
    const [dashboardPinnedChartsData, setDashboardPinnedChartsData] = useState({
        dashboardPinnedChartsDataFetched: false,
        dashboardData: [],
        dateChange: false,
        serviceAvailable: true,
        dashLoader: true
    });

    const [defaultDashbordValues, setDefaultDashboardValues] = useState(({
        flag: true
    }))


    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        window.scrollTo(0, 0);

        let queryParams = {
            app_id: '',
        };

        navigate({
            pathname: `/custom-dashboard/` + dashboardNumber + `/`,
            search: qs.stringify(queryParams),
        })
        setTimeout(() => {
            CommonUtils.LeftPanelHighlight(0, (parseInt(dashboardNumber) - 1), 0, true, false);
        }, 2000);
    }, [])

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getDashboardData = (from?: string, apiErrorFlag?: string) => {
        try {
            setDashboardPinnedChartsData((prevState: any) => {
                return {
                    ...prevState,
                    dashLoader: true,
                    dashboardPinnedChartsDataFetched: false,
                    dateChange: false
                }
            })

            setTimeout(() => {
                CommonUtils.LeftPanelHighlight(0, (parseInt(dashboardNumber) - 1), 0, true, false);
            }, 100);

            // OC - multiple times same state is updated, no impact should happen in flow ideally. Still need to check if this was done intentionally after set
            // timeout
            // setDashboardPinnedChartsData((prevState: any) => {
            //     return {
            //         ...prevState,
            //         dashboardPinnedChartsDataFetched: false,
            //         dateChange: false
            //     }
            // })

            let params = {};
            getCall(params, "CREATE_DASHBOARD", apiErrorFlag ?? '').then((data: any) => {
                let dashDummy: any = [];
                const dummyData: any = [];

                if (data.result === 'success') {
                    const dashboardData: any = data.data;
                    for (let i = 0; i < dashboardData.length; i++) {
                        dummyData[i] = {
                            ['dashboard' + (i + 1)]: {
                                active: true,
                                name: dashboardData[i].profileName,
                                profileID: dashboardData[i].profileID,
                                isDefault: dashboardData[i].isDefault,
                                dashboardIcon: dashboardData[i].dashboardIcon,
                                profileDetails: dashboardData[i].profileDetails
                            }
                        }

                    }

                    dispatch(setDashboards(dummyData))

                    if (data.result === "success" && data.data !== undefined) {
                        dashDummy.profileID = data.data[(parseInt(dashboardNumber) - 1)].profileID;
                        dashDummy.isDefault = data.data[(parseInt(dashboardNumber) - 1)].isDefault;
                        populateCharts(data.data[(parseInt(dashboardNumber) - 1)].profileDetails, dashDummy.profileID);
                    }

                    setDefaultDashboardValues((prevState: any) => {
                        return {
                            ...prevState,
                            flag: dummyData[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['isDefault']
                        }
                    })

                    setDashboardPinnedChartsData((prevState: any) => {
                        return {
                            ...prevState,
                            dashLoader: false
                        }
                    })
                } else if (data.result === 'retry') {
                    setTimeout(() => {
                        getDashboardData('', 'retry')
                    }, RetryApi.TIMEOUT)
                }
            });
        } catch (error) {
            showBoundary(error)
        }
    }

    // Helper function to push dashboard data into d_dummy
    const pushDashboardData = (d_dummy, dashboard, index, profileDetails, isDefault = false) => {
        d_dummy.push({
            profileID: dashboard.profileID,
            profileName: dashboard.name,
            isDefault: isDefault,
            dashboardNumber: index + 1,
            dashboardIcon: dashboard.dashboardIcon,
            isDashboard: false,
            profileDetails: profileDetails
        });
    }

    // Helper function to swap chartIndex values
    function swapChartIndexes(dummy_profileData, chartIndex, offset) {
        const currentProfileDetail = dummy_profileData.find(profile => profile.chartIndex === chartIndex);
        const targetProfileDetail = dummy_profileData.find(profile => profile.chartIndex === chartIndex + offset);
        if (currentProfileDetail && targetProfileDetail) {
            [currentProfileDetail.chartIndex, targetProfileDetail.chartIndex] = [targetProfileDetail.chartIndex, currentProfileDetail.chartIndex];
        }
    }

    // Helper function to handle selected dashboard logic
    function handleSelectedDashboard(d_dummy, currentDashboard, index, direction, chartIndex) {
        let dummy_profileData = currentDashboard.profileDetails;
        // Handle rearrangement based on direction
        const offset = direction === 'up' ? -1 : 1;
        swapChartIndexes(dummy_profileData, chartIndex, offset);
        // Add updated dashboard to d_dummy
        pushDashboardData(d_dummy, currentDashboard, index, dummy_profileData);
    }

    const moveByDirection = (direction: string, chartIndex: number) => {
        try {
            let d_dummy = [];
            fetched_dashboard_details.data.forEach((dashboard, index) => {
                const dashboardKey = 'dashboard' + (index + 1);
                const currentDashboard = dashboard[dashboardKey];
                // Check if the profileID matches the selected dashboard
                const selectedDashboard = fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)][`dashboard${dashboardNumber}`];
                if (selectedDashboard['profileID'] === currentDashboard['profileID']) {
                    handleSelectedDashboard(d_dummy, currentDashboard, index, direction, chartIndex);
                } else {
                    //Add unchanged data to payload
                    pushDashboardData(d_dummy, currentDashboard, index, currentDashboard.profileDetails);
                }
            });

            setDashboardPinnedChartsData((prevState: any) => {
                return {
                    ...prevState,
                    dashboardPinnedChartsDataFetched: false
                }
            })

            createDashboardPostCall(d_dummy);
        } catch (error) {
            showBoundary(error)
        }
    }

    const setDefaultDashboard = (profileID) => {
        let d_dummy = [];
        fetched_dashboard_details.data.forEach((dashboard, index) => {
            const dashboardKey = 'dashboard' + (index + 1);
            const currentDashboard = dashboard[dashboardKey];
            pushDashboardData(d_dummy, currentDashboard, index, currentDashboard.profileDetails, profileID === currentDashboard['profileID'])
        });
        createDashboardPostCall(d_dummy, true);
    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const setAttributes = (sectionType: string) => {
        try {
            let params = {
                width1: '',
                width2: '',
                width3: '',
                sections: 1
            }

            switch (sectionType) {
                case 'type1':
                    params = { ...params, width1: 'col-sm-12 col-md-12 col-lg-12', sections: 1 }
                    break;

                case 'type2':
                    params = { ...params, width1: 'col-sm-6 col-md-6 col-lg-6  marginRight-10 outerDiv', width2: 'col-sm-6 col-md-6 col-lg-6 marginLeft-10 outerDiv', sections: 2 }
                    break;

                case 'type3':
                    params = { ...params, width1: 'col-sm-4 col-md-4 col-lg-4 marginRight-10 outerDiv', width2: 'col-sm-4 col-md-4 col-lg-4  outerDiv', width3: 'col-sm-4 col-md-4 col-lg-4 marginLeft-10 outerDiv', sections: 3 }
                    break;
            }
            return params;
        } catch (error) {
            showBoundary(error)
        }
    }

    const populateCharts = (chartData: any[], profileID: string) => {
        try {
            let cnt = 0;
    
            // Process and count relevant chartData
            // To manage old PowerForm chart
            const dummyData = chartData.map((data) => {
                const apiType = data.api[0];
                if (apiType === 'POWER_FORM_STACKED_CHART' || apiType === 'DUCT_TAPE') {
                    cnt++;
                }
    
                return {
                    chartData: data,
                    sectionType: data.sectionType,
                    profileID
                };
            });
    
            // Refine dummyData based on the condition
            let dummyData_refined = cnt === 2
                ? chartData
                      .filter((data) => data.api[0] !== 'POWER_FORM_STACKED_CHART')
                      .map((data) => ({
                          chartData: data,
                          sectionType: data.sectionType,
                          profileID
                      }))
                : dummyData;
    

                let count = dummyData_refined.filter(
                    (data) => data.chartData.sectionHeader === 'Power Form & Digital Duct Tape'
                ).length;
                
                if (count > 0) {
                    dummyData_refined = dummyData_refined.filter(
                        (data) =>
                            data.chartData.sectionHeader !== 'Duct Tape' &&
                            data.chartData.sectionHeader !== 'Power Form'
                    );
                }
    
            setDashboardPinnedChartsData((prevState: any) => ({
                ...prevState,
                dashboardData: dummyData_refined,
                dashboardPinnedChartsDataFetched: true,
                dateChange: true,
                dashLoader: false
            }));
        } catch (error) {
            showBoundary(error);
        }
    };
    
    //Post call for Create Dashboard
    const createDashboardPostCall = (payload, defaultDashbordValueSet = false) => {
        let path = ApiRelativePaths.CREATE_DASHBOARD;
        let params = {};
        let paramObj = {};
        let a = CommonUtils.URLGenerator(params, path, paramObj);

        postCall(payload, "CREATE_DASHBOARD").then((data: any) => {
            if (data.result === "success") {
                let dData = []
                for (let itr0 = 0; itr0 < data.data.length; itr0++) {
                    if (data.data.length < 3) {
                        dData.push({
                            ['dashboard' + (itr0 + 1)]: {
                                active: true,
                                name: data.data[itr0].profileName,
                                profileID: data.data[itr0].profileID,
                                isDefault: false,
                                dashboardIcon: data.data[itr0].dashboardIcon,
                                profileDetails: data.data[itr0].profileDetails
                            }
                        })
                    }
                }
                dispatch(setDashboards(dData))

                if (defaultDashbordValueSet) {
                    setDefaultDashboardValues((prevState: any) => {
                        return {
                            ...prevState,
                            flag: true
                        }
                    })
                }

                toast.success(insightsLbls.successfullyUpdated, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                if (!defaultDashbordValueSet) {
                    if (!window.location.href.includes('isMaintenance')) {
                        getDashboardData();
                    }
                }
            }
        });
    }

    const handleApiCalls = (serviceAvailable: boolean) => {
        try {
            setDashboardPinnedChartsData((prevData: any) => {
                return {
                    ...prevData,
                    serviceAvailable: (dashboardPinnedChartsData.serviceAvailable && serviceAvailable)
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    // fetched_date_details.fdate
    // const getDashboardName = useCallback(() => {
    //     try {
    //         if (fetched_dashboard_details) {
    //             return fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['name'];
    //         }
    //         else
    //             return "";
    //     } catch (error) {
    //         showBoundary(error)
    //     }

    // }, [fetched_dashboard_details])


    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // On props change call dashboard api
    // useEffect(() => {
    //     try {
    //         if (isInitialMount.current) {
    //             let title = fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['name'];
    //             let doc_title = (title !== null && title !== '' && title.length > 0) ? title : ('Dashboard-' + `${dashboardNumber}`);
    //             document.title = doc_title;
    //             getDashboardData('load');
    //             isInitialMount.current = false

    //             setTimeout(() => {
    //                 isInitialMount.current = true
    //             }, 10);
    //         }
    //     } catch (error) {
    //         showBoundary(error)
    //     }

    // }, [props.params, dashboardNumber]);

    useEffect(() => {
        let isMounted = true;
    
        try {
            const title = fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['name'];
            const doc_title = title?.length > 0 ? title : `Dashboard-${dashboardNumber}`;
            document.title = doc_title;
    
            const allInactive = fetched_dashboard_details?.data.every(item => {
                const key = Object.keys(item)[0];
                return item[key].active === false;
            });
    
            if (!allInactive) {
                const profile_id = fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['profileID'];
                const profile_details = fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['profileDetails'];
    
                if (isMounted) {
                    console.log(7);
                    setDashboardPinnedChartsData((prevState: any) => ({
                        ...prevState,
                        dashboardPinnedChartsDataFetched: false,
                        dashLoader: true,
                        dashboardData: [],
                        dateChange: false
                    }));
                }
    
                populateCharts(profile_details, profile_id);
    
                if (isMounted) {
                    setDefaultDashboardValues((prevState: any) => ({
                        ...prevState,
                        flag: fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['isDefault']
                    }));
                }
            }
        } catch (error) {
            showBoundary(error);
        }
    
        return () => {
            isMounted = false;
        };
    }, [fetched_dashboard_details, props.params, dashboardNumber]);

    return (
        <section className="demo guideAnalytics width100" ref={componentRef}>
            {/* Breadcrumb */}
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{fetched_dashboard_details ? fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]["name"] : ""}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            {/* <SectionButtons
                sectionTitle={getDashboardName}
                svgImage={"customDashboard"}
                type={{
                    component: "customDashboard",
                    option: +`${dashboardNumber}`,
                    iconNumber: fetched_dashboard_details ? fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]["dashboardIcon"] : ""
                }}
                className={'marginTop-40'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['name'] + " " + `${new Date()}`}
                    widthDividedBy={11}
                    disabled={!(dashboardPinnedChartsData.serviceAvailable)}
                ></ExportToPdf>
            </SectionButtons> */}

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['name'] + " " + `${new Date()}`}
                                widthDividedBy={12}
                                disabled={!(dashboardPinnedChartsData.serviceAvailable)}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                showUserInfo={false}
                messageText={fetched_dashboard_details ? fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]["name"] : ""}
            >

                <div className="textRight">
                    <button className="btn btn-primary"
                        id={fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['profileID']}
                        onClick={() => setDefaultDashboard(fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['profileID'])}
                        disabled={defaultDashbordValues.flag}>
                        Make Default
                    </button>
                </div>

            </UserInfoSection>

            {
                dashboardPinnedChartsData.dashLoader ?
                    (
                        <div className="displayFlex alignCenter margin-0-auto width100">
                            <Loader></Loader>
                        </div>
                    )
                    :
                    (
                        fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['profileDetails'].length !== 0 ?
                            (
                                <>
                                    {
                                        dashboardPinnedChartsData.dashboardPinnedChartsDataFetched && dashboardPinnedChartsData.dashboardData
                                            .sort(function (a, b) { return a['chartData']['chartIndex'] - b['chartData']['chartIndex'] })
                                            .map(
                                                (item: any, key: number) => {
                                                    return (
                                                        <Type1
                                                            data={item.chartData}
                                                            profileID={item.profileID}
                                                            keyVal={key}
                                                            type={item.sectionType}
                                                            width1Class={setAttributes(item.sectionType)?.width1}
                                                            width2Class={setAttributes(item.sectionType)?.width2}
                                                            width3Class={setAttributes(item.sectionType)?.width3}
                                                            sections={setAttributes(item.sectionType)?.sections}
                                                            date={dashboardPinnedChartsData.dateChange}
                                                            dispatchDelete={() => getDashboardData()}
                                                            dispatchDirection={moveByDirection}
                                                            lastElem={dashboardPinnedChartsData.dashboardData.length === (key + 1) ? true : false}
                                                            noError={handleApiCalls}
                                                            guide_filter={item.chartData.guide_filter}
                                                            event_filter={item.chartData.event_filter}
                                                            tooltip_filter={item.chartData.tooltip_filter}
                                                            key={item.profileID + key}
                                                        >
                                                        </Type1>
                                                    );
                                                }
                                            )
                                    }
                                </>
                            )
                            :
                            (
                                <div className="noChartsAdded">
                                    {insightsLbls['addChartinCustomDash']}
                                </div>
                            )
                    )
            }
            <div className="overlayCursor" id="overlayCursor"></div>
        </section>

    )
}

export default withRouter(CustomDashboard);

