// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect initial load

import React, { useRef, useEffect, useState, useCallback } from 'react';
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';

import { toast } from "react-toastify";



import manageSurveysReducer from "store/Reducers/Survey/manageSurveys";
import { withReducer } from 'store/withReducer';
import { AppsState, CommonComponentState, DatesState, LanguageState } from 'store/Reducers';
import { ManageSurveysState } from 'store/Reducers/Survey/manageSurveys';
import { useSelector, useDispatch } from 'react-redux';
import * as manageSueveyActions from "store/Actions/index";
import * as ActionTypes from "../../../store/Actions/ActionTypes";



import qs from 'querystring';
import { CommonUtils } from 'utils/CommonUtils';
import { getCall, deleteCall } from "utils/ApiCallActions";



import SurveyList from 'components/ManageSurvey/SurveyList';
import SurveyForm from 'components/ManageSurvey/SurveyForm';
import CommonModal from 'components/CommonModal/CommonModal';
import Loader from "components/Loader/Loader";
import SurveyShareModal from 'components/ManageSurvey/SurveyShareModal';




import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import { InsightLabels } from 'labels';

import { useErrorBoundary } from 'react-error-boundary';
import { v4 as uuidv4 } from 'uuid';

// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }

interface CurrentState {
    apps: AppsState,
    dates: DatesState,
    manage: ManageSurveysState,
    languageData: LanguageState,
    commonComponentData: CommonComponentState,
}

const Manage: React.FC = (props: any) => {
    const isInitialMount = useRef(true);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    const location = useLocation();
    const navigate = useNavigate();

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const SurveyListMount = useRef(false);
    const firstTimeMount = useRef(true)
    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    const dispatch = useDispatch();

    const [shareModal, setShareModal] = useState({
        open: false,
        sentimentTitle: "",
        sentimentCode: "",
        publicUrlString: "Generating...",
        publicUrlFlag: false,
    })

    const [surveyList, setSurveyList] = useState({
        data: [],
        totalCount: 0,
        listSection: true,
        listDataFetched: false,
        apiError: 0,
        searchTxt: '',
        firstLoad: true,
        callFrom:''
    })

    const [editSurveyData, setEditSurveyData] = useState({
        data: [],
    })

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        sentimentCode: "",
    })

    let urlData: any = {};
    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });

    const [request_uuid, setUuid] = useState({
        value: ''
    })
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
        CommonUtils.LeftPanelHighlight(3, 1, 0, true, false);
    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.manageSurvey ? insightsLbls.manageSurvey : "";
        CommonUtils.LeftPanelHighlight(3, 1, 0, true, false);
    }, [insightsLbls])

    useEffect(() => {
    if (isInitialMount.current) {
        try {
            window.scrollTo(0, 0);
            let surveyformFlag: number = 0;

            const reqUUID = uuidv4();
            setUuid((prevState: any) => {
                return {
                    ...prevState,
                    value: reqUUID
                }
            })

            if (urlData?.survey_form !== null && urlData?.survey_form !== undefined && urlData?.survey_form !== '' &&
                (urlData?.survey_form === '1' || urlData?.survey_form === 1)) {
                // firstTimeMount.current = false;
                surveyformFlag = 1;
                navigationFn(surveyformFlag)

                setSurveyList((prevState: any) => {
                    return {
                        ...prevState,
                        listSection: false,
                    }
                })
            } else {
                navigationFn(surveyformFlag)

                setSurveyList((prevState: any) => {
                    return {
                        ...prevState,
                        listSection: true
                    }
                })
            }
            getSurveyList(reqUUID);
            isInitialMount.current = false;
        } catch (error) {
            showBoundary(error)
        }
    }

    setTimeout(() => {
        isInitialMount.current = true;
    },100)
  }, [fetched_details.apps.appId]);



    const navigationFn = (surveyformFlag: any) => {
        try {
            let urlData: any = {};
            const query = new URLSearchParams(location.search);
            query.forEach((v, k) => {
                urlData[k] = v;
            });

            let queryParams = {
                app_id: fetched_details?.apps?.appId,
                app_code: fetched_details?.apps?.appCode,
                survey_form: surveyformFlag
            }
            navigate({
                pathname: `/survey/manage-survey/`,
                search: qs.stringify(queryParams)
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getSurveyList = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            search_text: encodeURIComponent(surveyList.searchTxt),
            time_zone: timeZone,
            app_code: localStorage.getItem('switch_app_code'),
            sort_by: 'last_updated_on',
            order: 'desc',
            page_index: 1,
            page_size: 100,
            'request_uuid': reqUUID
        };


        manageSueveyActions.getManageSurveyData(
            params,
            "SENTIMENT_LIST",
            ActionTypes.SET_SURVEY_LIST_DATA,
            apiErrorFlag ?? '',
            surveyList.firstLoad
        ).then((res: any) => dispatch(res));
    }

    const deleteSurvey = useCallback(() => {
        let params = {
            "app_code": localStorage.getItem('switch_app_code'),
            "sentiment_code": deleteModal.sentimentCode
        };
        deleteCall(params, "CREATE_SURVEY", "").then((data: any) => {
            if (data.result === "success") {
                toast.error(insightsLbls.successfullyDeleted, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setDeleteModal((prevState: any) => {
                    return {
                        ...prevState,
                        open: false,
                    }
                })

                getSurveyList(request_uuid.value);

            }
        });
    }, [deleteModal.open])

    const getPublicUrl = useCallback((data: any) => {
        let params = {
            "time_zone": timeZone,
            "app_code": localStorage.getItem('switch_app_code'),
            "sentiment_code": data.surveyCode,
            "allow_anonymous_response": data.viewFlag,
            "link_expiry": data.linkExpiry,
            "sentiment_title": shareModal.sentimentTitle,
        };

        getCall(params, 'PUBLIC_URL', '').then((data: any) => {
            if (data.result === 'success') {
                setShareModal((prevData: any) => {
                    return {
                        ...prevData,
                        publicUrlString: data.data.url,
                        publicUrlFlag: true,
                    }
                })
            }
            else if (
                data.result === "error"
            ) {
                setShareModal((prevData: any) => {
                    return {
                        ...prevData,
                        publicUrlString: "Couldn't fetch the URL.",
                        publicUrlFlag: false,
                    }
                })
            }
        });
    }, []);

    const handleEditSurvey = (data: any, apiErrorFlag?: string) => {
        try {
            let params = {
                app_code: localStorage.getItem('switch_app_code'),
                time_zone: timeZone,
                sentiment_code: data.sentimentCode,
                'request_uuid': request_uuid.value
            };


            manageSueveyActions.getManageSurveyData(
                params,
                "CREATE_SURVEY",
                ActionTypes.SET_SURVEY_DATA,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));
        } catch (error) {
            showBoundary(error)
        }

    }

    //Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const openDeleteModal = (data: any) => {
        try {
            setDeleteModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                    sentimentCode: data,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const openShareModal = (title: string, code: string) => {
        try {
            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                    sentimentTitle: title,
                    sentimentCode: code,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const closeDeleteModal = () => {
        try {
            setDeleteModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const closeShareModal = () => {
        try {
            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const serachSurveyList = useCallback((data: any) => {
        try {
            setSurveyList((prevState: any) => {
                return {
                    ...prevState,
                    searchTxt: data,
                    firstLoad: false,
                    callFrom:'search'
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, [surveyList.searchTxt])

    const handleClick = useCallback((data?: any) => {
        try {
            setEditSurveyData((prevState: any) => {
                return {
                    ...prevState,
                    data: []
                }
            })
            setSurveyList((prevState: any) => {
                return {
                    ...prevState,
                    listSection: false,
                    firstLoad: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

        navigationFn(1)
    }, [surveyList.listSection]);

    const handleBack = useCallback((data: any) => {
        try {
            setSurveyList((prevState: any) => {
                return {
                    ...prevState,
                    listSection: true,
                    firstLoad: false,
                    searchTxt:'',
                    callFrom: data

                }
            })

            if (data === 'fromApi') {
                setSurveyList((prevState: any) => {
                    return {
                        ...prevState,
                        listSection: false,
                        firstLoad: false,
                        callFrom: data

                    }
                })
                getSurveyList(request_uuid.value);

            }
            navigationFn(0)
        } catch (error) {
            showBoundary(error)
        }


    }, [surveyList.listSection]);


    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        try {
            if (
                fetched_details.manage.surveyListData.data !== undefined &&
                fetched_details.manage.surveyListData.result === "success" &&
                fetched_details?.manage.surveyListData?.requestUUID === request_uuid.value
            ) {
                SurveyListMount.current = true;
                if (urlData?.survey_form !== null && urlData?.survey_form !== undefined && urlData?.survey_form !== '' &&
                    (urlData?.survey_form === '1' || urlData?.survey_form === 1) && firstTimeMount.current === true) {
                    firstTimeMount.current = false;
                    setSurveyList((prevState: any) => {
                        return {
                            ...prevState,
                            data: fetched_details.manage.surveyListData.data.sentiments,
                            totalCount: fetched_details.manage.surveyListData.data.totalCount,
                            listDataFetched: true,
                            apiError: 0,
                        }
                    })
                    handleClick();
                } else {

                    setSurveyList((prevState: any) => {
                        return {
                            ...prevState,
                            data: fetched_details.manage.surveyListData.data.sentiments,
                            totalCount: fetched_details.manage.surveyListData.data.totalCount,
                            listSection: true,
                            listDataFetched: true,
                            apiError: 0,
                        }
                    })
                }
            } else if (fetched_details.manage.surveyListData.result === 'retry') {
                getSurveyList('retry');
            } else if (fetched_details.manage.surveyListData.result === "error") {
                setSurveyList((prevState: any) => {
                    return {
                        ...prevState,
                        listDataFetched: true,
                        apiError: 1,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.manage.surveyListData])

    useEffect(() => {
        try {
            if (
                fetched_details.manage.surveyData.data !== undefined &&
                fetched_details.manage.surveyData.result === "success"  
            ) {
                let questionsData: any = fetched_details.manage.surveyData.data;
                let questionsDataLength = fetched_details.manage.surveyData.data?.questions.length;
                for (let i = 0; i < questionsDataLength; i++) {
                    if (fetched_details.manage.surveyData.data?.questions[i].type === 'yes-no' && fetched_details.manage.surveyData.data?.questions[i].options.length === 0) {
                        questionsData.questions[i].options.push({ option: "Yes", optionReferenceLink: "" }, { option: "No", optionReferenceLink: "" });
                    }
                }
                setEditSurveyData((prevState: any) => {
                    return {
                        ...prevState,
                        data: questionsData
                    }
                })

                setSurveyList((prevState: any) => {
                    return {
                        ...prevState,
                        listSection: false,
                    }
                })
                // navigationFn(1)
            } else if (fetched_details.manage.surveyData.result === 'retry') {
                handleEditSurvey(shareModal.sentimentCode, 'retry');
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.manage.surveyData.data])

    useEffect(() => {
        try {
            if (SurveyListMount.current === true) {
                getSurveyList(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [surveyList.searchTxt])

    return (
        
        <section className="width100" ref={componentRef}>
           
            {surveyList.listSection
                ? (
                    <div className="categoryDiv">
                        {
                            (surveyList.listDataFetched === true) ?
                                (
                                    <SurveyList
                                        listData={surveyList.data}
                                        listCount={surveyList.totalCount}
                                        apiError={surveyList.apiError}
                                        searchList={serachSurveyList}
                                        dispatchEditSurvey={handleEditSurvey}
                                        deleteSentiment={openDeleteModal}
                                        shareSentiment={openShareModal}
                                        dispatchClick={handleClick}
                                        firstTimeLoad={fetched_details?.manage?.surveyListData?.firstTimeload}
                                        initialMount = {isInitialMount.current}
                                        callFrom = {surveyList.callFrom}>
                                    </SurveyList>
                                )
                                :
                                (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                )
                : (<SurveyForm
                    dispatchBack={handleBack} formData={editSurveyData.data}>
                </SurveyForm>)


            }

            <CommonModal
                modalState={deleteModal.open}
                dispatchModalState={closeDeleteModal}
                modalTitle={insightsLbls.deleteSurvey}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={false}
                dispatchModalOk={deleteSurvey}
                dispatchModalCancel={closeDeleteModal}
                okBtn={insightsLbls.confirm}
                cancelBtn={insightsLbls.cancel}
            >
                <div className="deleteModalBody">
                    <p>{insightsLbls.deleteSurveyConfirmMsg}</p>
                </div>
            </CommonModal>

            <CommonModal
                modalState={shareModal.open}
                dispatchModalState={closeShareModal}
                modalTitle={insightsLbls.shareSurvey}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={true}
                dispatchModalOk={closeShareModal}
                okBtn={insightsLbls.close}
                dialogClassName={'maxWidth650'}

            >
                <SurveyShareModal
                    surveyTitle={shareModal.sentimentTitle}
                    surveyCode={shareModal.sentimentCode}
                    surveyURL={shareModal.publicUrlString}
                    publicURLFlag={shareModal.publicUrlFlag}
                    getEvent={getPublicUrl}>
                </SurveyShareModal>
            </CommonModal>
        </section>
    )
};

export default withRouter(withReducer("manage", manageSurveysReducer)(Manage));

