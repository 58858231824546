import React, { useRef, useEffect, useState, useCallback } from 'react';
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';

import { toast } from "react-toastify";



import manageChatBotReducer from "store/Reducers/ChatBot/manageChatBot";
import { withReducer } from 'store/withReducer';
import { AppsState, DatesState, LanguageState } from 'store/Reducers';
import { ManageChatBotState } from 'store/Reducers/ChatBot/manageChatBot';
import { useSelector, useDispatch } from 'react-redux';
import * as manageChatbotActions from "store/Actions/index";
import * as ActionTypes from "../../../store/Actions/ActionTypes";



import qs from 'querystring';
import { CommonUtils } from 'utils/CommonUtils';
import { getCall, deleteCall } from "utils/ApiCallActions";



import CommonModal from 'components/CommonModal/CommonModal';
import Loader from "components/Loader/Loader";
import SurveyShareModal from 'components/ManageSurvey/SurveyShareModal';
import ChatBotList from 'components/ManageChatBot/ChatBotList';
import ChatBotForm from 'components/ManageChatBot/ChatBotForm';
import { useErrorBoundary } from 'react-error-boundary';



import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import { InsightLabels } from 'labels';
import { v4 as uuidv4 } from 'uuid';
import Datepicker from 'components/Calender/Datepicker';



// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }

interface CurrentState {
    apps: AppsState,
    dates: DatesState,
    manage: ManageChatBotState,
    languageData: LanguageState

}

const ChatbotManage: React.FC = (props: any) => {
    const isInitialMount = useRef(true);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();
    const location = useLocation();
    const navigate = useNavigate();

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const SurveyListMount = useRef(false);
    const firstTimeMount = useRef(true)
    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    const dispatch = useDispatch();

    const [shareModal, setShareModal] = useState({
        open: false,
        sentimentTitle: "",
        conversationCode: "",
        publicUrlString: "Generating...",
        publicUrlFlag: false,
    })

    const [surveyList, setSurveyList] = useState({
        data: [],
        totalCount: 0,
        listSection: true,
        listDataFetched: false,
        apiError: 0,
        searchTxt: '',
        firstLoad: true
    })

    const [editSurveyData, setEditSurveyData] = useState({
        data: [],
    })

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        conversationCode: "",
    })

    let urlData: any = {};
    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });

    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.manageChatBot ? insightsLbls.manageChatBot : "";
        CommonUtils.LeftPanelHighlight(4, 1, 0, true, false);
    }, [insightsLbls])

    useEffect(() => {
        try {

            if (isInitialMount.current) {
                window.scrollTo(0, 0);
                let surveyformFlag: number = 0;

                const reqUUID = uuidv4();
                setUuid((prevState: any) => {
                    return {
                        ...prevState,
                        value: reqUUID
                    }
                })

                if (urlData?.chatbot_form !== null && urlData?.chatbot_form !== undefined && urlData?.chatbot_form !== '' &&
                    (urlData?.chatbot_form === '1' || urlData?.chatbot_form === 1)) {
                    // firstTimeMount.current = false;
                    surveyformFlag = 1;
                    navigationFn(surveyformFlag)

                    setSurveyList((prevState: any) => {
                        return {
                            ...prevState,
                            listSection: false,
                        }
                    })


                } else {
                    navigationFn(surveyformFlag)

                    setSurveyList((prevState: any) => {
                        return {
                            ...prevState,
                            listSection: true
                        }
                    })

                    getSurveyList(reqUUID);

                }

                CommonUtils.LeftPanelHighlight(4, 1, 0, true, false);
                isInitialMount.current = false;
            }

            setTimeout(() => {
                isInitialMount.current = true;
            },100)
            
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.apps?.appCode])



    const navigationFn = (surveyformFlag: any) => {
        try {
            let urlData: any = {};
            const query = new URLSearchParams(location.search);
            query.forEach((v, k) => {
                urlData[k] = v;
            });

            let queryParams = {
                app_id: fetched_details?.apps?.appId,
                app_code: fetched_details?.apps?.appCode,
                chatbot_form: surveyformFlag
            }
            navigate({
                pathname: `/chatbot/manage/`,
                search: qs.stringify(queryParams)
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getSurveyList = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            search_text: encodeURIComponent(surveyList.searchTxt),
            time_zone: timeZone,
            app_code: localStorage.getItem('switch_app_code'),
            sort_by: 'last_updated_on',
            order: 'desc',
            page_index: 1,
            page_size: 100,
            'request_uuid': reqUUID
        };


        manageChatbotActions.getManageChatBotData(
            params,
            "CHATBOT_LIST",
            ActionTypes.SET_CHATBOT_LIST,
            apiErrorFlag ?? '',
            surveyList.firstLoad
        ).then((res: any) => dispatch(res));
    }

    const deleteSurvey = useCallback(() => {
        try {
            let params = {
                "app_code": '',
                "conversation_code": deleteModal.conversationCode,
                time_zone: timeZone,
            };
            deleteCall(params, "CREATE_CHATBOT", "").then((data: any) => {
                if (data.result === "success") {
                    toast.error(insightsLbls.successfullyDeleted, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setDeleteModal((prevState: any) => {
                        return {
                            ...prevState,
                            open: false,
                        }
                    })

                    getSurveyList(request_uuid.value);

                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }, [deleteModal.open])

    const getPublicUrl = useCallback((data: any) => {
        try {
            let params = {
                "time_zone": timeZone,
                "app_code": localStorage.getItem('switch_app_code'),
                "sentiment_code": data.surveyCode,
                "allow_anonymous_response": data.viewFlag,
                "link_expiry": data.linkExpiry,
                "sentiment_title": shareModal.sentimentTitle,
            };

            getCall(params, 'PUBLIC_URL', '').then((data: any) => {
                if (data.result === 'success') {
                    setShareModal((prevData: any) => {
                        return {
                            ...prevData,
                            publicUrlString: data.data.url,
                            publicUrlFlag: true,
                        }
                    })

                }
                else if (
                    data.result === "error"
                ) {
                    setShareModal((prevData: any) => {
                        return {
                            ...prevData,
                            publicUrlString: "Couldn't fetch the URL.",
                            publicUrlFlag: false,
                        }
                    })
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }, []);

    const handleEditSurvey = (data: any, apiErrorFlag?: string) => {
        try {
            let params = {
                app_code: localStorage.getItem('switch_app_code'),
                time_zone: timeZone,
                conversation_code: data.conversationCode,
            };


            manageChatbotActions.getManageChatBotData(
                params,
                "CREATE_CHATBOT",
                ActionTypes.SET_CHATBOT_DATA,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));
        } catch (error) {
            showBoundary(error)
        }

    }

    //Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const openDeleteModal = (data: any) => {
        try {
            setDeleteModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                    conversationCode: data,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const openShareModal = (title: string, code: string) => {
        try {
            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                    sentimentTitle: title,
                    conversationCode: code,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const closeDeleteModal = () => {
        try {
            setDeleteModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const closeShareModal = () => {
        try {
            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const serachSurveyList = useCallback((data: any) => {
        try {
            setSurveyList((prevState: any) => {
                return {
                    ...prevState,
                    searchTxt: data,
                    firstLoad: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, [surveyList.searchTxt])

    const handleClick = useCallback((data?: any) => {
        try {
            setEditSurveyData((prevState: any) => {
                return {
                    ...prevState,
                    data: []
                }
            })
            setSurveyList((prevState: any) => {
                return {
                    ...prevState,
                    listSection: false,
                    firstLoad: false
                }
            })
            navigationFn(1)
        } catch (error) {
            showBoundary(error)
        }

    }, [surveyList.listSection]);

    const handleBack = useCallback((data: any) => {
        try {
            setSurveyList((prevState: any) => {
                return {
                    ...prevState,
                    listSection: true,
                    firstLoad: false,
                    searchTxt: ''
                }
            })

            if (data === 'fromApi') {
                setSurveyList((prevState: any) => {
                    return {
                        ...prevState,
                        listSection: false,
                        firstLoad: false
                    }
                })
                getSurveyList(request_uuid.value);

            }
            navigationFn(0)
        } catch (error) {
            showBoundary(error)
        }


    }, [surveyList.listSection]);


    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        try {
            if (
                fetched_details?.manage?.chatbotListData?.data !== undefined &&
                fetched_details?.manage?.chatbotListData?.result === "success" &&
                fetched_details?.manage?.chatbotListData?.requestUUID === request_uuid.value
            ) {
                SurveyListMount.current = true;
                if (urlData?.chatbot_form !== null && urlData?.chatbot_form !== undefined && urlData?.chatbot_form !== '' &&
                    (urlData?.chatbot_form === '1' || urlData?.chatbot_form === 1) && firstTimeMount.current === true) {
                    firstTimeMount.current = false;
                    setSurveyList((prevState: any) => {
                        return {
                            ...prevState,
                            data: fetched_details.manage.chatbotListData.data.conversations,
                            totalCount: fetched_details.manage.chatbotListData.data.totalCount,
                            listDataFetched: true,
                            apiError: 0,
                        }
                    })
                    handleClick();
                } else {

                    setSurveyList((prevState: any) => {
                        return {
                            ...prevState,
                            data: fetched_details?.manage?.chatbotListData?.data?.conversations,
                            totalCount: fetched_details?.manage?.chatbotListData?.data?.totalCount,
                            listSection: true,
                            listDataFetched: true,
                            apiError: 0,
                        }
                    })
                }
            } else if (fetched_details?.manage?.chatbotListData?.result === 'retry') {
                getSurveyList(request_uuid.value, 'retry');
            } else if (fetched_details?.manage?.chatbotListData?.result === "error") {
                setSurveyList((prevState: any) => {
                    return {
                        ...prevState,
                        listDataFetched: true,
                        apiError: 1,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.manage?.chatbotListData])

    useEffect(() => {
        try {
            if (
                fetched_details?.manage?.chatbotData?.data !== undefined &&
                fetched_details?.manage?.chatbotData?.result === "success"
            ) {
                let questionsData: any = fetched_details.manage.chatbotData.data;
                let questionsDataLength = fetched_details.manage.chatbotData.data?.questions.length;
                for (let i = 0; i < questionsDataLength; i++) {
                    if (fetched_details.manage.chatbotData.data?.questions[i].type === 'yes-no' && fetched_details.manage.chatbotData.data?.questions[i].options.length === 0) {
                        questionsData.questions[i].options.push({ option: "Yes", optionReferenceLink: "" }, { option: "No", optionReferenceLink: "" });
                    }
                }

                setEditSurveyData((prevState: any) => {
                    return {
                        ...prevState,
                        data: questionsData
                    }
                })

                setSurveyList((prevState: any) => {
                    return {
                        ...prevState,
                        listSection: false,
                    }
                })

                // navigationFn(1)
            } else if (fetched_details?.manage?.chatbotData?.result === 'retry') {
                handleEditSurvey(shareModal.conversationCode, 'retry');
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.manage?.chatbotData?.data])

    useEffect(() => {
        if (SurveyListMount.current === true) {
            getSurveyList(request_uuid.value);
        }

    }, [surveyList.searchTxt])

    return (
        <section className="width100" ref={componentRef}>
            {surveyList.listSection
                ? (
                    <div className="categoryDiv">
                        {
                            surveyList.listDataFetched === true ?
                                (
                                    <ChatBotList
                                        listData={surveyList.data}
                                        listCount={surveyList.totalCount}
                                        apiError={surveyList.apiError}
                                        searchList={serachSurveyList}
                                        dispatchEditSurvey={handleEditSurvey}
                                        deleteSentiment={openDeleteModal}
                                        shareSentiment={openShareModal}
                                        dispatchClick={handleClick}
                                        firstTimeLoad={fetched_details?.manage?.chatbotListData?.firstTimeload}>
                                    </ChatBotList>
                                )
                                :
                                (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                )
                : (<ChatBotForm
                    dispatchBack={handleBack} formData={editSurveyData.data}>
                </ChatBotForm>)


            }

            <CommonModal
                modalState={deleteModal.open}
                dispatchModalState={closeDeleteModal}
                modalTitle={insightsLbls.deleteChatBot}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={false}
                dispatchModalOk={deleteSurvey}
                dispatchModalCancel={closeDeleteModal}
                okBtn={insightsLbls.confirm}
                cancelBtn={insightsLbls.cancel}
            >
                <div className="deleteModalBody">
                    <p>{insightsLbls.deleteChatBotConfirmMsg}</p>
                </div>
            </CommonModal>

            <CommonModal
                modalState={shareModal.open}
                dispatchModalState={closeShareModal}
                modalTitle={insightsLbls.shareSurvey}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={true}
                dispatchModalOk={closeShareModal}
                okBtn={insightsLbls.close}
                dialogClassName={'maxWidth650'}

            >
                <SurveyShareModal
                    surveyTitle={shareModal.sentimentTitle}
                    surveyCode={shareModal.conversationCode}
                    surveyURL={shareModal.publicUrlString}
                    publicURLFlag={shareModal.publicUrlFlag}
                    getEvent={getPublicUrl}>
                </SurveyShareModal>
            </CommonModal>
        </section>
    )
};

export default withRouter(withReducer("manage", manageChatBotReducer)(ChatbotManage));

