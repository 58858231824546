export const SET_LOGIN_DATA = 'SET_LOGIN_DATA';
export const SET_OTP_DATA = 'SET_OTP_DATA';
export const SET_RESEND_OTP_DATA = 'SET_RESEND_OTP_DATA';
export const SET_FORGOT_PASSWORD_DATA = 'SET_FORGOT_PASSWORD_DATA';

//DASHBOARD
export const SET_DASHBOARD_GUIDEME_CHART_DATA = 'SET_DASHBOARD_GUIDEME_CHART_DATA';
export const SET_DASHBOARD_SHOWME_CHART_DATA ='SET_DASHBOARD_SHOWME_CHART_DATA';
export const SET_DASHBOARD_TESTME_CHART_DATA = 'SET_DASHBOARD_TESTME_CHART_DATA';
export const SET_DASHBOARD_DO_IT_FOR_ME_CHART_DATA = 'SET_DASHBOARD_DO_IT_FOR_ME_CHART_DATA';
export const SET_DASHBOARD_TOOLTIP_CHART_DATA = 'SET_DASHBOARD_TOOLTIP_CHART_DATA';
export const SET_DASHBOARD_PUSHNOTIFICATION_CHART_DATA = 'SET_DASHBOARD_PUSHNOTIFICATION_CHART_DATA';
export const SET_DASHBOARD_BEACON_CHART_DATA = 'SET_DASHBOARD_BEACON_CHART_DATA';
export const SET_DASHBOARD_SURVEY_CHART_DATA = 'SET_DASHBOARD_SURVEY_CHART_DATA';
export const SET_DASHBOARD_VALIDATION_CHART_DATA = 'SET_DASHBOARD_VALIDATION_CHART_DATA';
export const SET_DASHBOARD_POWERHTML_CHART_DATA = 'SET_DASHBOARD_POWERHTML_CHART_DATA';
export const SET_DASHBOARD_DUCTTAPE_CHART_DATA = 'SET_DASHBOARD_DUCTTAPE_CHART_DATA';
export const SET_DASHBOARD_GUIDANCEVALIDATION_CHART_DATA = 'SET_DASHBOARD_GUIDANCEVALIDATION_CHART_DATA';
export const SET_DASHBOARD_GUIDANCE_CHART_DATA = 'SET_DASHBOARD_GUIDANCE_CHART_DATA';
export const SET_DASHBOARD_USERENGAGEMENT_CHART_DATA = 'SET_DASHBOARD_USERENGAGEMENT_CHART_DATA';




export const SET_DASHBOARD_GUIDE_TUTORIALS_CHART_DATA = 'SET_DASHBOARD_GUIDE_TUTORIALS_CHART_DATA';
export const SET_DASHBOARD_GUIDES_TABLE_DATA = 'SET_DASHBOARD_GUIDES_TABLE_DATA';
export const SET_DASHBOARD_WORKFLOWS_CHART_DATA ='SET_DASHBOARD_WORKFLOWS_CHART_DATA';
export const SET_DASHBOARD_WORKFLOWS_TABLE_DATA ='SET_DASHBOARD_WORKFLOWS_TABLE_DATA';
export const SET_DASHBOARD_SURVEY_DATA = 'SET_DASHBOARD_SURVEY_DATA';
export const SET_DASHBOARD_SURVEYS_TABLE_DATA = 'SET_DASHBOARD_SURVEYS_TABLE_DATA';
export const SET_DASHBOARD_CONTRIES_CHART_DATA = 'SET_DASHBOARD_CONTRIES_CHART_DATA';
export const SET_DASHBOARD_GUIDES_CHART_DATA = 'SET_DASHBOARD_GUIDES_CHART_DATA';
export const SET_DASHBOARD_GUIDES_DONUT_CHART_DATA = 'SET_DASHBOARD_GUIDES_DONUT_CHART_DATA';
export const SET_DASHBOARD_USER_ENGAGEMENT = 'SET_DASHBOARD_USER_ENGAGEMENT';
export const SET_DASHBOARD_APPS_SUMMARY = 'SET_DASHBOARD_APPS_SUMMARY';
export const SET_DASHBOARD_GUIDE_SUMMARY_TABLE_DATA = 'SET_DASHBOARD_GUIDE_SUMMARY_TABLE_DATA';
export const SET_DASHBOARD_TOOLTIP_SUMMARY_TABLE_DATA = 'SET_DASHBOARD_TOOLTIP_SUMMARY_TABLE_DATA';
export const SET_DASHBOARD_GUIDE_COUNT = 'SET_DASHBOARD_GUIDE_COUNT';

//page
export const GET_BLOCK_CHART = 'GET_BLOCK_CHART';
export const SET_BLOCK_CHART = 'SET_BLOCK_CHART';
export const SET_USAGE_DATA = 'SET_USAGE_DATA';
export const SET_PAGE_SUMMARY_UNTAGGED = 'SET_PAGE_SUMMARY_UNTAGGED';
export const SET_PAGE_SUMMARY_TAGGED = 'SET_PAGE_SUMMARY_TAGGED';
export const SET_LABEL_LIST = 'SET_LABEL_LIST';
export const POST_TAG = 'POST_TAG';
//page detail
export const USER_STATS = 'USER_STATS';
export const PAGE_BLOCK_CHART = 'PAGE_BLOCK_CHART';
export const INSIGHTS_LOADTIME = 'INSIGHTS_LOADTIME';
export const USAGE_STATS = 'USAGE_STATS';
export const SET_PAGE_USER_ENGAGEMENT_STATS_CHART = 'SET_PAGE_USER_ENGAGEMENT_STATS_CHART';
export const SET_PAGE_USER_ENGAGEMENT_SUMMARY = 'SET_PAGE_USER_ENGAGEMENT_SUMMARY';
export const SET_PAGE_USER_ENGAGEMENT_MODAL_CHART = 'SET_PAGE_USER_ENGAGEMENT_MODAL_CHART';
// page tags
export const TAG_PAGE = 'TAG_PAGE';
//labels
export const GET_LABEL_LIST = 'GET_LABEL_LIST';


export const SET_FEATURE_STATS_CHART = 'SET_FEATURE_STATS_CHART';
export const SET_FEATURE_STATS_SUMMARY = 'SET_FEATURE_STATS_SUMMARY';

//Feature Detail
export const SET_FEATURE_DETAIL_STATS_CHART = 'SET_FEATURE_STATS_CHART';
export const SET_FEATURE_DETAIL_HEATMAP = 'SET_FEATURE_DETAIL_HEATMAP';
export const SET_FEATURE_LIST = 'SET_FEATURE_LIST';
export const SET_USER_ENGAGEMENT_STATS_CHART = 'SET_USER_ENGAGEMENT_STATS_CHART';
export const SET_USER_ENGAGEMENT_STATS_SUMMARY = 'SET_USER_ENGAGEMENT_STATS_SUMMARY';
export const SET_USER_ENGAGEMENT_FEATURE_DETAIL_STATS = 'SET_USER_ENGAGEMENT_FEATURE_DETAIL_STATS';

//WORKFLOW
export const SET_WORKFLOW_CHART_DATA = 'SET_WORKFLOW_CHART_DATA';
export const SET_WORKFLOW_SUMMARY_DATA ='SET_WORKFLOW_SUMMARY_DATA';
/////WORKFLOW DETAIL
export const SET_WORKFLOW_PLAYS_CHART_DATA = 'SET_WORKFLOW_PLAYS_CHART_DATA';
export const SET_WORKFLOW_USAGE_HEATMAP_DATA ='SET_WORKFLOW_USAGE_HEATMAP_DATA';
export const SET_WORKFLOW_USER_TIME_PLOT_DATA = 'SET_WORKFLOW_USER_TIME_PLOT_DATA';
export const SET_WORKFLOW_DETAIL_TABLE_DATA = 'SET_WORKFLOW_DETAIL_TABLE_DATA';
export const SET_WORKFLOW_USER_PATH_DATA = 'SET_WORKFLOW_USER_PATH_DATA';
export const SET_WORKFLOW_USER_LIST_DATA = 'SET_WORKFLOW_USER_LIST_DATA';
export const SET_WORKFLOW_USER_SPECIFIC_DATA = 'SET_WORKFLOW_USER_SPECIFIC_DATA';
export const SET_WORKFLOW_STEPS_DATA = 'SET_WORKFLOW_STEPS_DATA';

//DYNAMIC WORKFLOWS
export const SET_DYNAMIC_WORKFLOW_CHART_DATA = 'SET_DYNAMIC_WORKFLOW_CHART_DATA';
export const SET_DYNAMIC_WORKFLOW_SUMMARY_DATA ='SET_DYNAMIC_WORKFLOW_SUMMARY_DATA';

// DYNAMIC WORKFLOWS DETAIL
export const SET_DYNAMIC_WORKFLOW_DETAIL_SUMMARY_DATA = 'SET_DYNAMIC_WORKFLOW_DETAIL_SUMMARY_DATA';
export const SET_DYNAMIC_WORKFLOW_DETAIL_TIMEPLOT = 'SET_DYNAMIC_WORKFLOW_DETAIL_TIMEPLOT';
export const SET_DYNAMIC_WORKFLOW_DETAIL_GRAPH_DATA = 'SET_DYNAMIC_WORKFLOW_DETAIL_GRAPH_DATA';
export const SET_DYNAMIC_WORKFLOW_DETAIL_HEATMAP ='SET_DYNAMIC_WORKFLOW_DETAIL_HEATMAP';
export const SET_DYNAMIC_WORKFLOW_DETAIL_PATH = 'SET_DYNAMIC_WORKFLOW_DETAIL_PATH';
export const SET_DYNAMIC_WORKFLOW_DETAIL_USER_LIST = 'SET_DYNAMIC_WORKFLOW_DETAIL_USER_LIST';
export const SET_DYNAMIC_WORKFLOW_DETAIL_USER_SPECIFIC = 'SET_DYNAMIC_WORKFLOW_DETAIL_USER_SPECIFIC';
export const SET_DYNAMIC_WORKFLOW_STEPS_DATA = 'SET_DYNAMIC_WORKFLOW_STEPS_DATA';

// COMBINED WORKFLOWS

export const SET_COMBINED_WORKFLOW_CHART_DATA = 'SET_COMBINED_WORKFLOW_CHART_DATA';
export const SET_COMBINED_WORKFLOW_SUMMARY_DATA ='SET_COMBINED_WORKFLOW_SUMMARY_DATA';
export const SET_COMBINED_WORKFLOW_BAR_CHART = 'SET_COMBINED_WORKFLOW_BAR_CHART';

// COMBINED WORKFLOWS DETAILS
export const SET_COMBINED_WORKFLOWS_DETAILS_GRAPH_DATA = 'SET_COMBINED_WORKFLOWS_DETAILS_GRAPH_DATA';
export const SET_COMBINED_WORKFLOWS_DETAILS_HEATMAP_DATA = 'SET_COMBINED_WORKFLOWS_DETAILS_HEATMAP_DATA';
export const SET_COMBINED_WORKFLOWS_DETAILS_SUMMARY_DATA = 'SET_COMBINED_WORKFLOWS_DETAILS_SUMMARY_DATA';
export const SET_COMBINED_WORKFLOWS_DETAILS_TIMEPLOT = 'SET_COMBINED_WORKFLOWS_DETAILS_TIMEPLOT';
export const SET_COMBINED_WORKFLOWS_DETAILS_PATH_CHART_DATA ='SET_COMBINED_WORKFLOWS_DETAILS_PATH_CHART_DATA';
export const SET_COMBINED_WORKFLOWS_DETAILS_USER_LIST = 'SET_COMBINED_WORKFLOWS_DETAILS_USER_LIST';
export const SET_COMBINED_WORKFLOWS_DETAILS_USER_SPECIFIC = 'SET_COMBINED_WORKFLOWS_DETAILS_USER_SPECIFIC';
export const SET_COMBINED_WORKFLOWS_STEPS_DATA = 'SET_COMBINED_WORKFLOWS_STEPS_DATA';
export const SET_COMBINED_WORKFLOWS_DETAILS_FOOTFALL = 'SET_COMBINED_WORKFLOWS_DETAILS_FOOTFALL';

export const ON_PAGE_ENTER = "ON_PAGE_ENTER";
export const ON_WORKFLOW_ENTER = "ON_WORKFLOW_ENTER";

// EXPORT EXCEL
export const EXPORT_EXCEL = 'EXPORT_EXCEL';

// TOOLTIPS
export const SET_TOOLTIP_ACTIVITY_CHART = 'SET_TOOLTIP_ACTIVITY_CHART';
export const SET_POWER_FORM_CHART = 'SET_POWER_FORM_CHART';
export const SET_TOOLTIP_LIST = 'SET_TOOLTIP_LIST';
export const SET_POWER_FORM_SUMMARY = 'SET_POWER_FORM_SUMMARY';
export const SET_SMART_TIP_SUMMARY = 'SET_SMART_TIP_SUMMARY';

// TOOLTIPS DETAIL

export const SET_TOOLTIPS_DETAIL_LIST_DATA = 'SET_TOOLTIPS_DETAIL_LIST_DATA';
export const SET_TOOLTIPS_DETAIL_CHART = 'SET_TOOLTIPS_DETAIL_CHART';
export const SET_TOOLTIPS_DETAIL_POWERFORM_CHART = 'SET_TOOLTIPS_DETAIL_POWERFORM_CHART';
export const SET_TOOLTIPS_DETAIL_USER_ACTIVITY = 'SET_TOOLTIPS_DETAIL_USER_ACTIVITY';
// export const SET_TOOLTIPS_DETAIL_POWERFORM_CHART = 'SET_TOOLTIPS_DETAIL_POWERFORM_CHART';
export const SET_TOOLTIPS_DETAIL_LINK_CLICK_DATA = 'SET_TOOLTIPS_DETAIL_LINK_CLICK_DATA';

// TASK LIST
export const SET_TASK_LIST_CHART = 'SET_TASK_LIST_CHART';
export const SET_TASK_LIST_SUMMARY = 'SET_TASK_LIST_SUMMARY';
export const SET_TASK_LIST_DETAIL_GRAPH = 'SET_TASK_LIST_DETAIL_GRAPH';
export const SET_TASK_LIST_DETAIL_GUIDE_PLAY = 'SET_TASK_LIST_DETAIL_GUIDE_PLAY';
export const SET_TASK_LIST_DETAIL_USER_ENGAGEMENT_SUMMARY = 'SET_TASK_LIST_DETAIL_USER_ENGAGEMENT_SUMMARY';

// SEARCHES
export const SET_SEARCH_CHART_DATA = 'SET_SEARCH_CHART_DATA';
export const SET_SEARCH_STATS_DATA = 'SET_SEARCH_STATS_DATA';
export const SET_SEARCH_SUMMARY = 'SET_SEARCH_SUMMARY';
export const SET_SEARCH_USER_LIST = 'SET_SEARCH_USER_LIST';

//USER ENGAGEMENT
export const SET_USER_ENGAGEMENT_GUIDEME_CHART_DATA = 'SET_USER_ENGAGEMENT_GUIDEME_CHART_DATA';
export const SET_USER_ENGAGEMENT_SHOWME_CHART_DATA ='SET_USER_ENGAGEMENT_SHOWME_CHART_DATA';
export const SET_USER_ENGAGEMENT_TESTME_CHART_DATA = 'SET_USER_ENGAGEMENT_TESTME_CHART_DATA';
export const SET_USER_ENGAGEMENT_DO_IT_FOR_ME_CHART_DATA = 'SET_USER_ENGAGEMENT_DO_IT_FOR_ME_CHART_DATA';
export const SET_USER_ENGAGEMENT_TUTORIAL_GUIDES_CHART_DATA = 'SET_USER_ENGAGEMENT_TUTORIAL_GUIDES_CHART_DATA';
export const SET_USER_ENGAGEMENT_SUMMARY_TABLE_DATA ='SET_USER_ENGAGEMENT_SUMMARY_TABLE_DATA';
export const SET_USER_ENGAGEMENT_USER_CHART_DATA = 'SET_USER_ENGAGEMENT_USER_CHART_DATA';

//USER ENGAGEMENT DETAIL
export const SET_USER_ENGAGEMENT_DETAIL_SUMMARY_TABLE_DATA ='SET_USER_ENGAGEMENT_DETAIL_SUMMARY_TABLE_DATA';
export const SET_USER_ENGAGEMENT_DETAIL_LIST_DATA = 'SET_USER_ENGAGEMENT_DETAIL_LIST_DATA';
export const SET_USER_ENGAGEMENT_DETAIL_TIMELINE_DATA ='SET_USER_ENGAGEMENT_DETAIL_TIMELINE_DATA';
export const SET_USER_ENGAGEMENT_DETAIL_GUIDEME_CHART ='SET_USER_ENGAGEMENT_DETAIL_GUIDEME_CHART';
export const SET_USER_ENGAGEMENT_DETAIL_SHOWME_CHART ='SET_USER_ENGAGEMENT_DETAIL_SHOWME_CHART';
export const SET_USER_ENGAGEMENT_DETAIL_TESTME_CHART ='SET_USER_ENGAGEMENT_DETAIL_TESTME_CHART';
export const SET_USER_ENGAGEMENT_DETAIL_DO_IT_FOR_ME_CHART ='SET_USER_ENGAGEMENT_DETAIL_DO_IT_FOR_ME_CHART';
export const SET_USER_ENGAGEMENT_DETAIL_TUTORIAL_GUIDES_CHART_DATA ='SET_USER_ENGAGEMENT_DETAIL_TUTORIAL_GUIDES_CHART_DATA';

//GUIDE ANALYTICS
export const SET_GUIDE_ANALYTICS_GUIDEME_CHART_DATA = 'SET_GUIDE_ANALYTICS_GUIDEME_CHART_DATA';
export const SET_GUIDE_ANALYTICS_SHOWME_CHART_DATA ='SET_GUIDE_ANALYTICS_SHOWME_CHART_DATA';
export const SET_GUIDE_ANALYTICS_TESTME_CHART_DATA = 'SET_GUIDE_ANALYTICS_TESTME_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DOITFORME_CHART_DATA = 'SET_GUIDE_ANALYTICS_DOITFORME_CHART_DATA';
export const SET_GUIDE_ANALYTICS_SURVEY_CHART_DATA = 'SET_GUIDE_ANALYTICS_SURVEY_CHART_DATA';
export const SET_GUIDE_ANALYTICS_PB_CHART_DATA = 'SET_GUIDE_ANALYTICS_PB_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DOITFORME_PB_CHART_DATA = 'SET_GUIDE_ANALYTICS_DOITFORME_PB_CHART_DATA';
export const SET_GUIDE_ANALYTICS_SUMMARY_TABLE_DATA ='SET_GUIDE_ANALYTICS_SUMMARY_TABLE_DATA';
export const SET_USER_ENGAGEMENT_DETAIL_USER_NAME = 'SET_USER_ENGAGEMENT_DETAIL_USER_NAME';



//GUIDE ANALYTICS DETAIL
export const SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_CHART_DATA = 'SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_SOURCES_CHART_DATA = 'SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_SOURCES_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_SUMMARY_TABLE_DATA ='SET_GUIDE_ANALYTICS_DETAIL_SUMMARY_TABLE_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_LINKED_TABLE_DATA = 'SET_GUIDE_ANALYTICS_DETAIL_LINKED_TABLE_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_STEPS_FLOW_CHART = 'SET_GUIDE_ANALYTICS_DETAIL_STEPS_FLOW_CHART';
export const SET_GUIDE_ANALYTICS_DETAIL_DROPOFF_FUNNEL_CHART = 'SET_GUIDE_ANALYTICS_DETAIL_DROPOFF_FUNNEL_CHART';
export const SET_GUIDE_ANALYTICS_DETAIL_FUNNEL_STEPS_LIST = 'SET_GUIDE_ANALYTICS_DETAIL_FUNNEL_STEPS_LIST';
export const SET_GUIDE_ANALYTICS_DETAIL_SHOWME_CHART_DATA ='SET_GUIDE_ANALYTICS_DETAIL_SHOWME_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_SHOWME_DOWNLOADS_CHART_DATA ='SET_GUIDE_ANALYTICS_DETAIL_SHOWME_DOWNLOADS_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_TESTME_CHART_DATA = 'SET_GUIDE_ANALYTICS_DETAIL_TESTME_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_CHART_DATA = 'SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_TABLE_DATA = 'SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_TABLE_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_SOURCES_CHART_DATA = 'SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_SOURCES_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_TUTORIAL_GUIDES_CHART_DATA = 'SET_GUIDE_ANALYTICS_DETAIL_TUTORIAL_GUIDES_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_SURVEY_CHART_DATA = 'SET_GUIDE_ANALYTICS_DETAIL_SURVEY_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_SURVEY_MODAL =  'SET_GUIDE_ANALYTICS_DETAIL_SURVEY_MODAL';
export const SET_GUIDE_USER_ACTIVITY = 'SET_GUIDE_USER_ACTIVITY';
export const SET_GUIDE_ANALYTICS_DETAIL_SHOWME_SOURCES_CHART_DATA = 'SET_GUIDE_ANALYTICS_DETAIL_SHOWME_SOURCES_CHART_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_SHOWME_STEPS_DATA = 'SET_GUIDE_ANALYTICS_DETAIL_SHOWME_STEPS_DATA';
export const SET_GUIDE_ANALYTICS_DETAIL_AUDIO_CHART = 'SET_GUIDE_ANALYTICS_DETAIL_AUDIO_CHART';
export const SET_GUIDE_ANALYTICS_ERROR_DETAILS = 'SET_GUIDE_ANALYTICS_ERROR_DETAILS';

// GUIDE ERRORS
export const SET_GUIDE_ERRORS_CHART_DATA = 'SET_GUIDE_ERRORS_CHART_DATA';
export const SET_GUIDE_ERRORS_SUMMARY = 'SET_GUIDE_ERRORS_SUMMARY';
export const SET_GUIDE_ERRORS_DETAIL_SUMMARY = 'SET_GUIDE_ERRORS_DETAIL_SUMMARY';



//TUTORIAL GUIDES
export const SET_GUIDE_ANALYTICS_TUTORIAL_GUIDES_CHART_DATA = 'SET_GUIDE_ANALYTICS_TUTORIAL_GUIDES_CHART_DATA';
export const SET_GUIDE_TUTORIAL_SUMMARY_TABLE_DATA ='SET_GUIDE_TUTORIAL_SUMMARY_TABLE_DATA';

//TUTORIAL DETAIL GUIDES
export const SET_GUIDE_ANALYTICS_DETAILS_TUTORIAL_GUIDES_CHART_DATA = 'SET_GUIDE_ANALYTICS_DETAILS_TUTORIAL_GUIDES_CHART_DATA';
export const SET_GUIDE_DETAIL_TUTORIAL_SUMMARY_TABLE_DATA ='SET_GUIDE_DETAIL_TUTORIAL_SUMMARY_TABLE_DATA';

//PUSH-NOTIFICATIONS BEACONS
export const SET_PUSH_NOTIFICATIONS_CHART = 'SET_PUSH_NOTIFICATIONS_CHART';
export const SET_BEACONS_CHART = 'SET_BEACONS_CHART';



//EXPORT
export const SET_EXPORT_METADATA = 'SET_EXPORT_METADATA';
export const SET_EXPORT_LIST_METADATA = 'SET_EXPORT_LIST_METADATA';

// SURVEY_STATS
export const SET_SURVEY_STATS_BAR_CHART_DATA = 'SET_SURVEY_STATS_BAR_CHART_DATA';
export const SET_SURVEY_STATS_SUMMARY_TABLE_DATA = 'SET_SURVEY_STATS_SUMMARY_TABLE_DATA';


// SURVEY_STATS_DETAILS
export const SET_SURVEY_STATS_DETAILS_BAR_CHART_DATA = 'SET_SURVEY_STATS_DETAILS_BAR_CHART_DATA';
export const SET_SURVEY_STATS_DETAILS_QUESTIONS_LIST_DATA = 'SET_SURVEY_STATS_DETAILS_QUESTIONS_LIST_DATA';
export const SET_SURVEY_LIST_DATA = 'SET_SURVEY_LIST_DATA';
export const SET_SURVEY_DATA = 'SET_SURVEY_DATA';

// MANAGE CHATBOT
export const SET_CHATBOT_LIST = 'SET_CHATBOT_LIST';
export const SET_CHATBOT_DATA = 'SET_CHATBOT_DATA';
export const SET_CHATBOT_STATS_CHART = 'SET_CHATBOT_STATS_CHART';
export const SET_CHATBOT_STATS_SUMMARY = 'SET_CHATBOT_STATS_SUMMARY';
export const SET_CHATBOT_STATS_DETAILS_CHART_DATA = 'SET_CHATBOT_STATS_DETAILS_CHART_DATA';
export const SET_CHATBOT_STATS_DETAILS_RESPONSE_LIST_DATA = 'SET_CHATBOT_STATS_DETAILS_RESPONSE_LIST_DATA';

// GUIDE AUTOMATION
export const SET_GUIDE_AUTOMATION_LINE_CHART_DATA = 'SET_GUIDE_AUTOMATION_LINE_CHART_DATA';
export const SET_GUIDE_AUTOMATION_SUMMARY_TABLE_DATA = 'SET_GUIDE_AUTOMATION_SUMMARY_TABLE_DATA';

//GUIDE AUTOMATION DETAILS

export const SET_GUIDE_AUTOMATION_DETAILS_DESCRIPTION_TABLE_DATA = 'SET_GUIDE_AUTOMATION_DETAILS_DESCRIPTION_TABLE_DATA';

// QA AUTOMATOR
export const SET_AUTOMATOR_CHART = 'SET_AUTOMATOR_CHART';
export const SET_AUTOMATOR_SUMMARY = 'SET_AUTOMATOR_SUMMARY';

// QA AUTOMATOR DETAIL
export const SET_AUTOMATOR_DETAIL_CHART = 'SET_AUTOMATOR_DETAIL_CHART';
export const SET_AUTOMATOR_DETAIL_LIST = 'SET_AUTOMATOR_DETAIL_LIST';
export const SET_AUTOMATOR_DETAIL_JOBS_SUMMARY = 'SET_AUTOMATOR_DETAIL_JOBS_SUMMARY';
export const SET_AUTOMATOR_DETAIL_JOBS_RUN_MODAL = 'SET_AUTOMATOR_DETAIL_JOBS_RUN_MODAL';
export const SET_AUTOMATOR_DETAIL_SUITES_MODAL = 'SET_AUTOMATOR_DETAIL_SUITES_MODAL';

// EXPORT
export const SET_EXPORT_LIST_DATA = 'SET_EXPORT_LIST_DATA';
export const SET_EXPORT_TEMPLATE_DATA = 'SET_EXPORT_TEMPLATE_DATA';
export const SET_EXPORT_SCHEDULER_DATA = 'SET_EXPORT_SCHEDULER_DATA';

// ORGANIZATION VIEW
export const SET_ORG_VIEW = 'SET_ORG_VIEW';
export const SET_FILTER_VIEW = 'SET_FILTER_VIEW';

// ORGANISATION VIEW DETAIL
export const SET_ORG_DETAIL = 'SET_ORG_DETAIL';
export const SET_ORGANISATION_VIEW_DETAIL_CHART_DATA = 'SET_ORGANISATION_VIEW_DETAIL_CHART_DATA';
export const SET_ORGANISATION_VIEW_DETAIL_PNBT_CHART_DATA = 'SET_ORGANISATION_VIEW_DETAIL_PNBT_CHART_DATA';
export const SET_ORGANISATION_VIEW_DETAIL_GUIDE_MODIFICATION_CHART = 'SET_ORGANISATION_VIEW_DETAIL_GUIDE_MODIFICATION_CHART';
export const SET_EXPORT_LIST = 'SET_EXPORT_LIST';
export const SET_PENDING_EVENT_COUNT = 'SET_PENDING_EVENT_COUNT';

//GOAL
export const SET_GOAL_LIST_DATA = 'SET_GOAL_LIST_DATA';
export const SET_GOAL_DATA = 'SET_GOAL_DATA';
export const SET_GOAL_DETAILS_WATCHER_DATA = 'SET_GOAL_DETAILS_WATCHER_DATA';
export const SET_GOAL_GUIDE_LIST = 'SET_GOAL_GUIDE_LIST';
export const SET_GOAL_TOOLTIP_LIST = 'SET_TOOLTIP_GUIDE_LIST';
export const SET_GOAL_DETAILS_LINE_CHART_DATA = 'SET_GOAL_DETAILS_LINE_CHART_DATA';
export const SET_GOAL_DETAILS_PROGRESS_BAR_DATA = 'SET_GOAL_DETAILS_PROGRESS_BAR_DATA';
export const SET_CHECK_GOAL_TITLE = 'SET_CHECK_GOAL_TITLE';


// CONTROL CENTER
export const SET_PREFERENCE_DATA = 'SET_PREFERENCE_DATA'








