
const realtime_flag = true;
let flag_value = 'v1';
if (realtime_flag) {
    flag_value = 'v1_1';
} else {
    flag_value = 'v1';
}


export class ApiRelativePaths {
    //for ASSIMA IBM 
    static UPDATED_IMAGE_URL = '/v3/file/presigned/url';

    // ACCOUNT
    static LOGIN = 'accounts/v1/signin/';
    static LOGOUT = 'accounts/v1/signout/';
    static OTP_VERIFY = 'accounts/v1/otp/verify/';
    static REFRESH_TOKEN = '/accounts/v1/refreshtoken/';
    static APP_LIST = 'metadata/v1/apps';
    static FORGOT_PASSWORD = 'accounts/v1/resetpassword/';
    static HANDOFF_TOKEN = 'accounts/v1/handoff-token/redeem/';
    static ORGANIZATION_LIST = 'accounts/v1/organization/';
    static SWITCH_ORGANIZATION = 'accounts/v1/organization/switch/';
    static GET_SIGNATURE = '/organization/signature';
    static CHANGE_PASSWORD = 'accounts/v1/changepassword/';
    static GENERATE_HANDOFF_TOKEN: '/accounts/v1/handoff-token/generate/';
    static GET_LANGUAGE = '/metadata/v1/user/language/'


    // DASHBOARD
    static DASHBOARD_GUIDE_ME_CHART = 'dashboard/v1_1/multiapp/graph/guideme/';
    static DASHBOARD_SHOW_ME_CHART = 'dashboard/v1_1/multiapp/graph/showme/';
    static DASHBOARD_TEST_ME_CHART = 'dashboard/v1_1/multiapp/graph/testme/';
    static DASHBOARD_DO_IT_FOR_ME_CHART = 'dashboard/v1_1/multiapp/graph/doitforme/';
    static DASHBOARD_GUIDE_TUTORIALS_CHART_DATA = 'dashboard/v1_1/multiapp/graph/tutorial/';
    static DASHBOARD_TOOLTIP_CHART_DATA = 'dashboard/v1_1/multiapp/graph/tooltip/';
    static DASHBOARD_PUSHNOTIFICATION_CHART_DATA = 'dashboard/v1_1/multiapp/graph/pushguideplay/';
    static DASHBOARD_BEACON_CHART_DATA = 'dashboard/v1_1/multiapp/graph/beaconguideplay/';
    static DASHBOARD_SURVEY_CHART_DATA = 'dashboard/v1_1/multiapp/graph/survey/';
    static DASHBOARD_VALIDATION_CHART_DATA = 'dashboard/v1_1/multiapp/graph/tooltip/validation/';
    static DASHBOARD_POWERHTML_CHART_DATA = 'dashboard/v1_1/multiapp/graph/tooltip/power_html/';
    static DASHBOARD_DUCTTAPE_CHART_DATA = 'dashboard/v1_1/multiapp/graph/tooltip/digital_duct_tape/';
    static DASHBOARD_GUIDANCEVALIDATION_CHART_DATA = 'dashboard/v1_1/multiapp/graph/tooltip/guidance_validation';
    static DASHBOARD_GUIDANCE_CHART_DATA = 'dashboard/v1_1/multiapp/graph/tooltip/guidance/';
    static DASHBOARD_USER_ENGAGEMENT = 'dashboard/v1_1/multiapp/graph/userengagement';


    static GET_USER_ENGAGEMENT = 'dashboard/v1_1/insight/graph/user/engagement/';
    // static GET_APPS_SUMMARY = 'dashboard/v1_1/insight/summary/detail/';
    static GET_COUNTRY_DATA = 'dashboard/v1_1/multiapp/graph/location/';
    // static GET_WORKFLOW_SUMMARY = 'dashboard/v1/insight/summary/workflows';
    static GET_WORKFLOW_MULTIAPP_GRAPH = '/dashboard/v1_1/multiapp/workflow/graph/';
    static GET_WORKFLOW_MULTIAPP_SUMMARY = '/dashboard/v1_1/multiapp/workflow/summary/';
    static GET_SURVEY_MULTIAPP_GRAPH = '/dashboard/v1_1/multiapp/workflow/graph/';
    // static GET_WORKFLOW_SUMMARY = 'workflow/v1_1/insight/summary/top/';
    static GET_SENTIMENT_SUMMARY = '/dashboard/v1_1/multiapp/sentiment/summary/';
    // static GET_GUIDE_SUMMARY = 'dashboard/v1_1/insight/summary/guides/';
    // static GET_GUIDE_LINE_CHART = 'dashboard/v1_1/insight/graph/guides/';
    static DASHBOARD_EXPORT_DATA = 'dashboard/v1_1/multiapp/summary/export/';
    static GET_DASHBOARD_GUIDE_SUMMARY = 'dashboard/v1_1/multiapp/guide/summary/';
    static GET_DASHBOARD_TOOLTIP_SUMMARY = 'dashboard/v1_1/multiapp/tooltip/summary/';

    static GET_DASHBOARD_GUIDE_COUNT = '/metadata/v1/multiapp/guides/count/';

    // CUSTOM DASHBOARDS
    static CREATE_DASHBOARD = 'metadata/v1/dashboard/profile/';

    // SEGMENTS FILTER
    static SEGMENTS_FILTER = 'metadata/v1/segments/list/';

    // ENVIRONMENT FILTER
    static ENVIRONMENT_FILTER = 'metadata/v1/app/env/list/';

    // GUIDEME
    static GUIDE_ANALYTICS_GUIDE_ME_CHART = 'guide/' + flag_value + '/insight/graph/guideme/';
    static GUIDE_ANALYTICS_SHOW_ME_CHART = 'guide/' + flag_value + '/insight/graph/showme/downloads/';
    static GUIDE_ANALYTICS_TEST_ME_CHART = 'guide/' + flag_value + '/insight/graph/testme/';
    static GUIDE_ANALYTICS_DOITFOR_ME_CHART = 'guide/' + flag_value + '/insight/graph/doitforme/';
    static GUIDE_ANALYTICS_SURVEY_CHART = 'guide/' + flag_value + '/insight/graph/survey/';
    static GUIDE_ANALYTICS_PB_CHART = 'guide/' + flag_value + '/insight/graph/page/components/';
    static GUIDE_ANALYTICS_DOITFORME_PB_CHART = 'guide/v1_1/insight/graph/page/components/doitforme/';
    static GUIDE_ANALYTICS_SUMMARY_TABLE = 'guide/' + flag_value + '/insight/summary/';
    static GUIDE_ANALYTICS_TUTORIAL_GUIDES_CHART = 'tutorial/' + flag_value + '/insight/summary/graph/'


    //GUIDEME DETAIL
    static GUIDE_STATUS = 'metadata/v1/guide/status/';
    static SHOW_SUMMARY_USER = 'metadata/v1/showme/user/activity';
    static ACTIVITY = 'guide/v1_1/user/activity';
    static GUIDE_ANALYTICS_DETAILS_GUIDE_ME_CHART = 'guide/' + flag_value + '/insight/graph/guideme/detail/';
    static GUIDE_ANALYTICS_DETAILS_GUIDE_ME_SOURCES_CHART = 'guide/' + flag_value + '/insight/graph/trigger/sources/';
    static GUIDE_ANALYTICS_DETAILS_SUMMARY_TABLE = 'guide/' + flag_value + '/insight/summary/steps/';
    static GUIDE_ANALYTICS_DETAILS_STEPS_FLOW_CHART = 'guide/v1_1/insight/graph/steps/sankey/';
    static GUIDE_ANALYTICS_DETAILS_DROPOFF_FUNNEL_CHART = 'guide/v1_1/insight/graph/steps/dropoff/';
    static GUIDE_ANALYTICS_DETAILS_FUNNEL_STEPS_LIST = 'guide/v1_1/insight/steps/list/';
    static GUIDE_ANALYTICS_DETAILS_SHOW_ME_CHART = 'guide/' + flag_value + '/insight/graph/showme/detail/';
    static GUIDE_ANALYTICS_DETAILS_SHOW_ME_DOWNLOADS_CHART = 'guide/' + flag_value + '/insight/graph/showme/detail/downloads/';
    static GUIDE_ANALYTICS_DETAILS_TEST_ME_CHART = 'guide/' + flag_value + '/insight/graph/testme/detail/';
    static GUIDE_ANALYTICS_DETAILS_DOITFOR_ME_CHART = 'guide/' + flag_value + '/insight/graph/doitforme/detail/';
    static GUIDE_ANALYTICS_DETAILS_DOITFOR_ME_SUMMARY_TABLE = 'guide/' + flag_value + '/insight/summary/doitforme/steps/';
    static GUIDE_ANALYTICS_DETAILS_DOITFOR_ME_SOURCES_CHART = 'guide/' + flag_value + '/insight/graph/trigger/sources/doitforme/';
    static GUIDE_ANALYTICS_DETAILS_SURVEY_CHART = 'guide/' + flag_value + '/insight/graph/survey/detail/';
    static GUIDE_ANALYTICS_DETAILS_SURVEY_MODAL = 'guide/v1/insight/summary/survey/detail/';
    static GUIDE_ANALYTICS_DETAIL_SHOWME_SOURCES_CHART_DATA = 'guide/v1_1/insight/graph/showme/trigger/sources/';
    static GUIDE_ANALYTICS_DETAIL_SHOWME_STEPS_DATA = 'guide/v1_1/insight/showme/detail/steps/graph/';
    static GUIDE_ANALYTICS_DETAIL_AUDIO_CHART = 'guide/v1_1/insight/graph/guideme/audio/detail/';
    static GUIDE_ANALYTICS_DETAILS_TUTORIAL_GUIDES_SOURCES_CHART = 'guide/' + flag_value + '/insight/graph/doitforme/detail/';
    static GUIDE_ANALYTICS_ERROR_DETAILS = 'guide/v1_1/insight/summary/steps/error/';
    static GUIDE_ANALYTICS_DOITFORME_ERROR_DETAILS = 'guide/v1_1/insight/summary/doitforme/steps/error';


    // GUIDE ERRORS
    static GUIDE_ERRORS_CHART_DATA = 'guide/v1_1/guide_error/graph';
    static GUIDE_ERRORS_SUMMARY = 'guide/v1_1/guide_error/summary/';
    static GUIDE_ERRORS_DETAIL_SUMMARY = 'guide/v1_1/guide_error/steps/detail/';



    //TUTORIAL GUIDE
    static TUTORIAL_GUIDES_CHART = 'tutorial/' + flag_value + '/insight/summary/graph/';
    static TUTORIAL_GUIDES_SUMMARY_TABLE = 'tutorial/' + flag_value + '/insight/summary/table/';

    //TUTORIAL GUIDE DETAIL
    static TUTORIAL_GUIDES_DETAILS_CHART = 'tutorial/' + flag_value + '/insight/detail/graph/';
    static TUTORIAL_GUIDES_DETAILS_SUMMARY_TABLE = 'tutorial/' + flag_value + '/insight/detail/table';

    //PUSH-NOTIFICATIONS BEACONS
    static PUSH_NOTIFICATIONS_CHART = 'guide/v1_1/push_notification/stats/';
    static BEACONS_CHART = 'guide/v1_1/beacon/stats/';


    // USER ENGAGEMENT
    static GUIDEME_USER_ENGAGEMENT = 'user/v1_1/insight/graph/engagement/';
    static USER_ENGAGEMENT_GUIDE_ME_CHART = 'user/v1_1/insight/graph/guideme/';
    static USER_ENGAGEMENT_SHOW_ME_CHART = 'user/v1_1/insight/graph/showme/';
    static USER_ENGAGEMENT_TEST_ME_CHART = 'user/v1_1/insight/graph/testme/';
    static USER_ENGAGEMENT_DO_IT_FOR_ME_CHART = 'user/v1_1/insight/graph/doitforme/';
    static USER_ENGAGEMENT_TUTORIAL_GUIDES_CHART = 'user/v1_1/insight/graph/tutorial/';
    static USER_ENGAGEMENT_SUMMARY_TABLE = 'user/v1_1/insight/summary/';

    // USER ENGAGEMENT DETAIL
    static USER_ENGAGEMENT_DETAIL_SUMMARY_TABLE = 'user/v1_1/insight/detail/summary/';
    static USER_ENGAGEMENT_DETAIL_GUIDEME_CHART = 'user/v1_1/insight/detail/graph/guide/live/';
    static USER_ENGAGEMENT_DETAIL_TIMELINE_DATA = 'user/v1_1/insight/detail/timeline/'
    static USER_ENGAGEMENT_DETAIL_SHOWME_CHART = 'user/v1_1/insight/detail/graph/showme/';
    static USER_ENGAGEMENT_DETAIL_TESTME_CHART = 'user/v1_1/insight/detail/graph/testme/';
    static USER_ENGAGEMENT_DETAIL_DO_IT_FOR_ME_CHART = 'user/v1_1/insight/detail/graph/doitforme/';
    static USER_ENGAGEMENT_DETAIL_TUTORIAL_GUIDES_CHART = 'user/v1_1/insight/detail/graph/tutorial/';
    static USER_ENGAGEMENT_DETAIL_USER_NAME = 'metadata/v1/user/name/';

    // TOOLTIP
    static POWER_FORM_STACKED_CHART = 'tooltip/v1_1/insight/graph/';
    static TOOLTIP_VALIDATION = 'tooltip/v1_1/insight/graph/';
    static DUCT_TAPE = 'tooltip/v1_1/insight/graph/';
    static POWER_FORM_SUMMARY = 'tooltip/v1_1/insight/summary/';
    static TOOLTIP_LIST = 'tooltip/v1/list/';
    static SMART_TIP_SUMMARY = 'tooltip/v1_1/insight/guide/summary/';

    //TOOLTIP SUMMARY
    static TOOLTIPS_DETAIL_LIST_DATA = 'tooltip/v1/detail/list/';
    static TOOLTIPS_DETAIL_CHART = 'tooltip/v1_1/insight/detail/graph/';
    static TOOLTIPS_DETAIL_POWERFORM_CHART = 'tooltip/v1_1/insight/detail/graph/powerform/';
    static TOOLTIPS_DETAIL_USER_ACTIVITY = 'tooltip/v1_1/insight/detail/user';
    static TOOLTIPS_DETAIL_LINK_CLICK = 'tooltip/v1_1/insight/detail/link/clicks';

    // TASK LIST
    static TASK_LIST_CHART = 'tasklist/v1/summary/graph/';
    static TASK_LIST_SUMMARY = 'tasklist/v1/summary/insights/';
    static TASK_LIST_DETAIL_GRAPH = '/tasklist/v1/detail/graph/';
    static TASK_LIST_DETAIL_GUIDE_PLAY = '/tasklist/v1/detail/guide/play/';
    static TASK_LIST_DETAIL_USER_ENGAGEMENT_SUMMARY = '/tasklist/v1/user/engagement/summary/';
    static TASK_LIST_DETAIL_USER_GUIDE_PLAY = '/tasklist/v1/user/guide/plays/';


    // SEARCH ACTIVITIES 
    static SEARCH_SUMMARY = 'search/v1_1/insight/guide/summary/';
    static SEARCH_CHART = 'search/v1_1/insight/guide/wordcloud/';
    static SEARCH_STATS = 'search/v1_1/insight/guide/stats/';
    static SEARCH_USER_LIST = 'search/v1_1/insight/guide/user/list/';

    //EXPORT SUMMARY- GUIDE ANALYTICS
    static EXPORT_ALL = 'summary/v1/export/';

    // PAGE
    static PAGE_SUMMARY_UNTAGGED = 'page/v1/insight/summary/untagged/';
    static PAGE_SUMMARY_TAGGED = 'page/v1/insight/summary/tagged/';
    static USAGE_STATS = 'page/v1/insight/browser/stats/';
    static BLOCK_DATA = 'page/v1/insight/engagement/volume/';
    static INSIGHTS_LOADTIME = 'page/v1/insight/loadtime/';
    static TAG_PAGE = 'tracker/v1/tag';
    static PAGE_USER_ENGAGEMENT_STATS = '/page/v1/insight/user/engagement/graph'
    static PAGE_USER_ENGAGEMENT_SUMMARY = '/page/v1/insight/user/engagement/summary/'
    static PAGE_USER_ENGAGEMENT_MODAL_CHART = '/page/v1/insight/user/engagement/individual/graph/'
    // ACTIVE USERS
    static USER_STATS = 'page/v1/insight/users/activity/';
    static PAGE_BLOCK_CHART = 'page/v1/insight/users/heatmap/';


    // FEATURE
    static FEATURE_STATS_SUMMARY = 'feature/v1/insight/summary';
    static FEATURE_STATS_CHART = 'feature/v1/insight/graph/';
    static FEATURE_DETAIL_HEATMAP = '/feature/v1/insight/users/heatmap/';
    static FEATURE_DETAIL_USAGE_STATS = '/feature/v1/insight/browser/stats/';
    static FEATURE_LIST = 'metadata/v1/features/list/';
    static USER_ENGAGEMENT_STATS = '/feature/v1/insight/user/engagement/graph';
    static USER_ENGAGEMENT_STATS_SUMMARY = '/feature/v1/insight/user/engagement/summary/';
    static USER_ENGAGEMENT_FEATURE_DETAIL_STATS = '/feature/v1/insight/user/engagement/individual/graph/';



    // COMBINED WORKFLOWS
    static COMBINED_WORKFLOW_CHART = "workflow/v1_1/insight/graph/";
    static COMBINED_WORKFLOW_TABLE = 'workflow/v1_1/insight/summary/';
    static COMBINED_WORKFLOW_BAR_CHART = 'workflow/v1_1/insight/summary/top/';

    // COMBINED WORKFLOW DETAIL
    static COMBINED_WORKFLOWS_DETAILS_GRAPH_DATA = 'workflow/v1_1/insight/graph/detail/';
    static COMBINED_WORKFLOWS_DETAILS_HEATMAP_DATA = 'workflow/v1_1/usage/heatmap/';
    static COMBINED_WORKFLOWS_DETAILS_SUMMARY_DATA = 'workflow/v1_1/insight/summary/detail/';
    static COMBINED_WORKFLOWS_DETAILS_TIMEPLOT = 'workflow/v1_1/user/time/plot/';
    static COMBINED_WORKFLOWS_DETAILS_PATH_CHART_DATA = 'workflow/v1_1/insight/steps/path/';
    static GET_COMBINED_WORKFLOWS_FEATURE_FUNNEL = 'workflow/v1_1/insight/funnel/';
    static COMBINED_WORKFLOWS_STEPS = 'workflow/v1_1/insight/steps/list/';
    static COMBINED_WORKFLOWS_DETAILS_FOOTFALL = 'workflow/v1_1/footfall/graph/';
    // static COMBINED_WORKFLOWS_DETAILS_PATH = 'dynamic_workflow/v1/user/path/flow/';
    // static COMBINED_WORKFLOWS_DETAILS_USER_LIST = 'dynamic_workflow/v1/user/list/';
    // static COMBINED_WORKFLOWS_DETAILS_USER_SPECIFIC = 'dynamic_workflow/v1/user/proficiency/'

    //SENTIMENTS
    static CREATE_SURVEY = 'sentiments/v1/questionaire';
    // EXPORT_DATA = 'sentiments/v1/responses/insight/summary/';
    static URL = 'sentiments/v1/url/';
    static PUBLIC_URL = 'sentiments/v1/public/url/';
    static SENTIMENT_STATS_FILTER = 'sentiments/v1/filter/dynamic/metadata/';
    //QUESTION LIST EXPORT
    // static EXPORT_QUESTION_LIST = 'sentiments/v1/responses/insight/summary/';
    //MANAGE SENTIMENTS
    static SENTIMENT_LIST = 'sentiments/v1/list/';
    //SURVEY STATS
    static SURVEY_STATS_BAR_CHART = 'sentiments/v1/insight/graph/';
    static SURVEY_STATS_SUMMARY_TABLE = 'sentiments/v1/insight/summary/';
    //SURVEY DETAILS STATS
    static SURVEY_STATS_DETAILS_BAR_CHART = 'sentiments/v1/insight/graph/';
    static SURVEY_STATS_DETAILS_QUESTIONS_LIST = 'sentiments/v1/responses/insight/summary/';
    static EXIT_SURVEY = 'metadata/v1/org/exit_survey/';


    // CHATBOT
    static CHATBOT_LIST = 'conversations/v1/list/';
    static CREATE_CHATBOT = 'conversations/v1/questionaire/';
    static CHATBOT_STATS_CHART = 'conversations/v1/insight/graph/';
    static CHATBOT_STATS_SUMMARY = 'conversations/v1/insight/summary/';
    static CHATBOT_STATS_DETAILS_RESPONSE_LIST = 'conversations/v1/insight/detail/list/';
    static CHATBOT_DETAIL_BY_RESPONSE_CODE = 'conversations/v1/insight/detail/instance/';
    static CHATBOT_POST_REMARK = '/conversations/v1/remark/create/'


    // EXPORT METADATA
    static EXPORT_METADATA = 'metadata/v1_1/export_request/place/';
    static EXPORT_LIST_METADATA = 'metadata/v1/export_request/list/';


    //TABLE 
    static GET_LABEL_LIST = 'tracker/v1/labels';

    //GUIDE AUTOMATION
    static GUIDE_AUTOMATION_LINE_CHART = 'guide_automation/v1/graph/';
    static GUIDE_AUTOMATION_SUMMARY_TABLE = 'guide_automation/v1/summary/'
    //GUIDE AUTOMATION DETAIL
    static GUIDE_AUTOMATION_DETAIL_DESCRIPTION_TABLE = 'guide_automation/v1/detail/'


    // QA AUTOMATOR
    // static AUTOMATOR_CHART = 'qa_automator/v1/graph/suites/';
    // static AUTOMATOR_SUMMARY = 'qa_automator/v1/summary/suites/list/';
    //QAAUTOMATOR DETAIL
    // static AUTOMATOR_DETAIL_SUITE_CHART = 'qa_automator/v1/graph/suites/';
    // static AUTOMATOR_DETAIL_SUITE_SUMMARY = 'qa_automator/v1/summary/suites/';
    // static AUTOMATOR_DETAIL_JOBS_SUMMARY = 'qa_automator/v1/summary/job/';
    // static AUTOMATOR_DETAIL_JOBS_RUN_MODAL = 'qa_automator/v1/detail/job/suite/';
    // static AUTOMATOR_DETAIL_SUITES_MODAL = '/qa_automator/v1/detail/suite/';


    // ORGANIZATION VIEW AND DETAIL
    static ORG_VIEW = 'overview/v1/organization/log/stats/';
    static FILTER_VIEW = 'overview/v1/filters/list/';
    static ORG_FILTER = 'overview/v1/filters/';
    static ORG_DETAIL = 'overview/v1/organization/graph/detail/';
    static ORGANISATION_VIEW_DETAIL_CHART_DATA = 'overview/v1/organization/graph/detail/';
    static ORGANISATION_VIEW_DETAIL_PNBT_CHART_DATA = 'overview/v1/organization/graph/detail/';
    static ORGANISATION_VIEW_DETAIL_GUIDE_MODIFICATION_CHART = 'overview/v1/organization/guide_mod/detail/';


    // EXPORT
    static EXPORT_LIST_DATA = 'export/v1/export/history/';
    static EXPORT_SCHEDULER_DATA = '/export/v1/export/schedule/list/';
    static EXPORT_REQUEST = 'export/v1/export/request/';
    static EXPORT_TEMPLATE_DATA = '/export/v1/template/list/';
    static SAVE_TEMPLATE_DATA = 'export/v1/export/template/';
    static SAVE_SCHEDULE_DATA = '/export/v1/user/schedule/';
    static EXPORT_SCHEDULE_UPDATE = '/export/v1/export/schedule/';

    //EXPORT - GUIDE & TOOLTIP DATA FOR APPLICATION
    static GET_GUIDES_BY_APP = 'export/v1/guide/list/';
    static GET_TOOLTIPS_BY_APP = 'export/v1/tooltip/list/';


    // CONTROL CENTER
    static PREFERENCE_DATA = 'preference/v1/user/';
    static SAVE_PREFERENCE_DATA = 'preference/v1/user/save/';

    //GOAL 
    static GOAL_LIST = 'goals/v1_1/list/';
    static GET_GOAL = 'goals/v1_1/';
    static CREATE_GOAL = 'goals/v1_1/';
    static DELETE_GOAL = '';
    static GOAL_GUIDE_LIST = 'goals/v1_1/guide/list/';
    static GOAL_TOOLTIP_LIST = 'goals/v1_1/tooltip/list/';
    static GOAL_DETAILS_LINE_CHART_DATA = 'goals/v1_1/insight/graph/';
    static GOAL_DETAILS_PROGRESS_BAR_DATA = 'goals/v1_1/insight/progress/';
    static CHECK_GOAL_TITLE = 'goals/v1_1/duplicate/status/';
    static ADD_WATCHER = '/goals/v1_1/watcher/add/';
    static REMOVE_WATCHER = '/goals/v1_1/watcher/remove/';

    //MAINTENANCE
    static GET_EXPORT_JOBS = 'maintenance/v1/pending/export/jobs/'
    static GET_PENDING_EVENT_COUNT = 'maintenance/v1/pending/events/'
    static PRIORTIZE_JOB = 'maintenance/v1/priortise/export/job/'
    static DELETE_API_CACHE = 'maintenance/v1/api/cache/'
    static PRIORTIZE_JOB_ORG = 'maintenance/v1/priortise/'

    //BUSINESS INTELLIGENCE TOOLS INTEGRATION
    static POWERBI_CONNECTOR = '/public/v1/powerbi/connector/'
    static POWERBI_ODATA_TOKEN = '/export/v1/odata/token/'
    static ODATA_TOKEN_INITIATION = '/export/v1/odata/initiation/request/'


}

export class ChartColors {
    static lightgreen = '#76be65';
    static lightseagreen = '#38b6a0';
    static turquoise = '#56e7ce';
    static darkturquoise = '#00a6d9';
    static yellowsandybrown = '#facc41';
    static orangesandybrown = '#f79b42';
    static slateblue = '#5b64dd';
    static mediumpurple = '#9476c9';
    static mediumorchid = '#b060e2';
    static salmon = '#f1736a';
    static lightpink = '#ffaea9';
    static skyblue = '#7ebce6';
    static brightred = '#f93850';
    static lightgray = '#cfd0dd';
    static grey = '#454560';
    static progressBarGreen = '#02c39a';
    static progressBarPurple = '#7159c1';


    static color1 = '#118DA3';
    static color2 = '#AA63D1';
    static color3 = '#318c00';
    static color4 = '#636ED1';

    static colorArr = ['#118DA3', '#AA63D1', '#318c00', '#636ED1', '#facc41', '#f79b42', '#76be65', '#38b6a0', '#56e7ce', '#00a6d9'];
}

export class AppDomains {
    static domains: any;
}

export class RetryApi {
    static TIMEOUT = 5000;
}

export class DateSelection {
    static defaultDays = 30;
    static defaultSelection = 10
}