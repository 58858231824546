// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect initial load

import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';



// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";
import { SurveyStatsState } from "store/Reducers/Survey/surveyStats";
import surveyStatsReducer from "store/Reducers/Survey/surveyStats";
import * as surveyStatsActions from "store/Actions/index";
import { getCallExportExcel } from "utils/ApiCallActions";


import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ChartColors } from "utils/GlobalConstants";


// JSON Imports
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";


// SCSS Imports
import "./Stats.scss";


import Loader from "components/Loader/Loader";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import CommonTable from "components/Table/CommonTable";
import Maintenance from "components/Maintenance/Maintenance";
import BarChart from "components/Chart/nvd3Charts/BarChart";
import SectionFilters from "components/Layouts/SectionFilters";
import { useErrorBoundary } from 'react-error-boundary';
import { InsightLabels } from "labels";
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import UserInfoSection from "components/Layouts/UserInfoSection";



interface CurrentState {
    commonComponentData: CommonComponentState,
    surveyStats: SurveyStatsState,
    languageData: LanguageState
}

const Stats: React.FC = (props: any) => {
    const isInitialMount = useRef(true);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    const navigate = useNavigate();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    const dispatch = useDispatch();

    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "75",
    }
    // const pie_margin = {
    //     top: 10,
    //     bottom: 20,
    //     left: 0,
    //     right: 0
    // }

    let linechart_height = 400;

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;


    const SurveyStatsMount = useRef(false);
    const SurveySummaryMount = useRef(false);

    const [parameter, setParameter] = useState({
        parameter: "",
        isDeleted: "",
        surveyName: ""
    });
    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0,],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [surveyStatsBarChart, setSurveyStatsBarChart] = useState({
        loadBarChartData: [],
        loadBarChartOptions: [],
        surveyStatsBarChartDataFetched: false,
        barChart: {},
        barChartFetched: false,
    });

    const [surveyStatsSummaryTable, setSurveyStatsSummaryTable] = useState({
        surveyStatsSummaryTableDataFetched: false,
        searchQuery: '',
        sortKey: 'sentiment_responses',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 2,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        hideDeleted: 0
    });

    const [request_uuid, setUuid] = useState({
        value: ''
    })
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);

        }
    }, [fetched_details.languageData.languageData])


    useEffect(() => {
        document.title = insightsLbls.surevyStats ? insightsLbls.surevyStats : "" ;
        if (insightsLbls) {
            window.scrollTo(0, 0);
            CommonUtils.LeftPanelHighlight(3, 0, 0, true, false);
        }

    }, [insightsLbls])




    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getSurveyStatsBarChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            'exclude_weekends': false,
            'request_uuid': reqUUID

        };

        surveyStatsActions.getSurveyStatsData(
            params,
            "SURVEY_STATS_BAR_CHART",
            ActionTypes.SET_SURVEY_STATS_BAR_CHART_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    };

    const getSurveyStatsSummaryTableData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "page_size": surveyStatsSummaryTable.limit,
            "sort_by": surveyStatsSummaryTable.sortKey,
            "order": surveyStatsSummaryTable.sortValue,
            "page_index": surveyStatsSummaryTable.page,
            "search_text": surveyStatsSummaryTable.searchQuery,
            "filter_label": surveyStatsSummaryTable.filterLabels,
            'exclude_weekends': false,
            'request_uuid': reqUUID

            // "filter_label": ''
        };

        surveyStatsActions.getSurveyStatsData(
            params,
            "SURVEY_STATS_SUMMARY_TABLE",
            ActionTypes.SET_SURVEY_STATS_SUMMARY_TABLE_DATA,
            apiErrorFlag ?? '',
            surveyStatsSummaryTable.firstLoad
        ).then((res: any) => dispatch(res));
    };

    const exportExcel = (data: any) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "page_size": surveyStatsSummaryTable.limit,
            "sort_by": surveyStatsSummaryTable.sortKey,
            "order": surveyStatsSummaryTable.sortValue,
            "page_index": surveyStatsSummaryTable.page,
            "search_text": surveyStatsSummaryTable.searchQuery,
            "filter_label": surveyStatsSummaryTable.filterLabels,
            'exclude_weekends': false,
        };
        getCallExportExcel(params, 'SURVEY_STATS_SUMMARY_TABLE', data);
    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setSurveyStatsBarChart((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsBarChartDataFetched: false,
                        barChartFetched: false,
                        barChart: {}
                    };
                });
                getSurveyStatsBarChartData(request_uuid.value);

            } else if (optn === 1) {
                setSurveyStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsSummaryTableDataFetched: false,
                        searchQuery: '',
                        sortKey: 'sentiment_responses',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 2,
                            'sortValue': 'desc'
                        },
                        // renderTaggedTable: false,
                        sortValue: 'desc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                        firstLoad: true,
                        hideDeleted: 0

                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    setParameter((prevState: any) => {
                        return {
                            ...prevState,
                            parameter: data.sentimentCode,
                            isDeleted: data.isDeleted,
                            surveyName: data.sentimentTitle
                        };
                    });
                    break;

                case "sortColumn":

                    setSurveyStatsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });

                    break;

                case "changePage":

                    setSurveyStatsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":

                    setSurveyStatsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });

                    break;
                case "applyLabels":
                    setSurveyStatsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            filterLabels: data,
                            firstLoad: false
                        };
                    });
                    break;

                case "changeEntries":
                    setSurveyStatsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "hideDeleted":
                    setSurveyStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            firstLoad: false,
                            hideDeleted: data
                        }
                    });
                    break;

                case "exportExcel":
                    exportExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [surveyStatsSummaryTable]);

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // UseEffect for date, app, filters change
    useEffect(() => {
        try {
            if (isInitialMount.current) {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                }
                navigate({
                    pathname: `/survey/survey/`,
                    search: qs.stringify(queryParams)
                })

                if (fetched_details?.commonComponentData.datesData.source === 'component' &&
                    fetched_details?.commonComponentData?.appsData.appCode == localStorage.getItem('switch_app_code')) {
                    if (fetched_details?.commonComponentData.datesData?.url?.includes('survey/survey/')) {
                        isInitialMount.current = false;

                        const reqUUID = uuidv4();
                        setUuid((prevState: any) => {
                            return {
                                ...prevState,
                                value: reqUUID
                            }
                        })


                        enableExportPdf(0, false);

                        setSurveyStatsBarChart((prevState: any) => {
                            return {
                                ...prevState,
                                surveyStatsBarChartDataFetched: false,
                                barChartFetched: false,
                                barChart: {}
                            };
                        });

                        getSurveyStatsBarChartData(reqUUID);

                        enableExportPdf(1, false);
                        setSurveyStatsSummaryTable((prevState: any) => {
                            return {
                                ...prevState,
                                surveyStatsSummaryTableDataFetched: false,
                                searchQuery: '',
                                sortKey: 'sentiment_responses',
                                filterLabels: [],
                                page: 1,
                                showExport: true,
                                defaultSort: {
                                    'sortColumn_index': 2,
                                    'sortValue': 'desc'
                                },
                                sortValue: 'desc',
                                limit: 5,
                                tablePagination: {
                                    offset: 0,
                                    data: [],
                                    perPage: 5,
                                    currentPage: 0
                                },
                                firstLoad: true,
                                hideDeleted: 0
                            };
                        });
                    }
                }

                setTimeout(() => {
                    isInitialMount.current = true;
                }, 100)
            }

            setTimeout(() => {
                isInitialMount.current = true;
            }, 100)
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.commonComponentData.datesData])

    //Chart Data
    useEffect(() => {
        try {
            if (
                fetched_details.surveyStats.surveyStatsBarChartData.data !== undefined &&
                fetched_details.surveyStats.surveyStatsBarChartData.result === "success" &&
                fetched_details?.surveyStats.surveyStatsBarChartData?.requestUUID === request_uuid.value
            ) {
                SurveyStatsMount.current = true;
                let surveyBarChartData: any = [];
                surveyBarChartData = fetched_details.surveyStats.surveyStatsBarChartData.data;

                setSurveyStatsBarChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadBarChartData: [...surveyBarChartData],
                        surveyStatsBarChartDataFetched: true,
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true)

            } else if (fetched_details.surveyStats.surveyStatsBarChartData.result === 'retry') {
                getSurveyStatsBarChartData('retry');
            } else if (
                fetched_details.surveyStats.surveyStatsBarChartData.result === "error"
            ) {
                setSurveyStatsBarChart((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsBarChartDataFetched: true,
                    };
                });
                apiCatchError(0, 1);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.surveyStats.surveyStatsBarChartData]);

    //Summary Data
    useEffect(() => {
        try {
            if (
                fetched_details.surveyStats.surveyStatsSummaryTableData.data !== undefined &&
                fetched_details.surveyStats.surveyStatsSummaryTableData.result === "success" &&
                fetched_details?.surveyStats.surveyStatsSummaryTableData?.requestUUID === request_uuid.value
            ) {
                SurveySummaryMount.current = true;
                setSurveyStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.surveyStats.surveyStatsSummaryTableData.result === 'retry') {
                getSurveyStatsSummaryTableData('retry');
            } else if (
                fetched_details.surveyStats.surveyStatsSummaryTableData.result === "error"
            ) {
                setSurveyStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(1, 1);
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.surveyStats.surveyStatsSummaryTableData]);

    // Export PDF Btn
    useEffect(() => {
        if (loaderBtn.loader0 && loaderBtn.loader1) {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    disabled: false,
                };
            });
        }
        else {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    disabled: true,
                };
            });
        }
    }, [loaderBtn.loader0, loaderBtn.loader1])

    // Detail Navigation
    useEffect(() => {
        try {
            if (parameter.parameter !== '') {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                }

                navigate({
                    pathname: "/survey/survey-details/" + parameter.parameter + "/",
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [parameter])

    // Call summary table api on table related actions changes
    useEffect(() => {
        try {

            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData.datesData?.url?.includes('survey/survey/') && request_uuid.value) {
                enableExportPdf(5, false);
                getSurveyStatsSummaryTableData(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        surveyStatsSummaryTable.sortKey,
        surveyStatsSummaryTable.sortValue,
        surveyStatsSummaryTable.defaultSort,
        surveyStatsSummaryTable.page,
        surveyStatsSummaryTable.searchQuery,
        surveyStatsSummaryTable.filterLabels,
        surveyStatsSummaryTable.limit,
        surveyStatsSummaryTable.hideDeleted
    ]);

    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])

    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getBarChartData = useCallback(
        (optn: string) => {
            try {
                if (optn === "data") {
                    return surveyStatsBarChart.loadBarChartData;
                } else {
                    return surveyStatsBarChart.loadBarChartOptions;
                }
            } catch (error) {
                showBoundary(error)
            }

        },
        [surveyStatsBarChart.loadBarChartData, surveyStatsBarChart.loadBarChartOptions]
    );

    const BarChartSurvey = useCallback(() => {
        try {
            setSurveyStatsBarChart((prevState: any) => {
                return {
                    ...prevState,
                    barChart: <BarChart
                        chartEntities={['pos', 'neu', 'neg']}
                        optionEntities={
                            [
                                {
                                    key: insightsLbls.positive,
                                    color: ChartColors.lightgreen,
                                    associatedDataPoint: "pos",
                                    area: true
                                },
                                {
                                    key: insightsLbls.neutral,
                                    color: ChartColors.yellowsandybrown,
                                    associatedDataPoint: "neu",
                                    area: true
                                },
                                {
                                    key: insightsLbls.negative,
                                    color: ChartColors.brightred,
                                    associatedDataPoint: "neg",
                                    area: true
                                }
                            ]}
                        chartData={fetched_details?.surveyStats?.surveyStatsBarChartData?.data}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls.yAxisLabelCount}
                        refId="statsDetail_barchart"
                        parseIntFlag={true}>
                    </BarChart>,
                    barChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [getBarChartData, lineChartMargin, linechart_height]);

    return (
        <section className="demo surveyStats width100" ref={componentRef}>
            {/* Breadcrumb */}
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{insightsLbls.survey}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={insightsLbls.stats} svgImage={"surveys"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"SurveyStats"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}


            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"FeatureStats"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                dateObject={fetched_details?.commonComponentData?.datesData}
                messageText={insightsLbls.survey}>
            </UserInfoSection>


            <div id="surveyStatsSection">
                {
                    // <SectionRefresh sectionTitle={insightsLbls.survey} refreshClass={""}>
                    //     <i
                    //         className="fa fa-refresh pull-right pointer row displayFlex"
                    //         aria-hidden="true" title={insightsLbls.refresh}
                    //         onClick={() => refreshSections(0)}>
                    //     </i>
                    // </SectionRefresh>
                }

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {<SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.activity,
                                    "tooltipText": insightsLbls.barGraphPosNegNeu,
                                    "childern": []
                                }
                            } placement="right"></SectionTooltip>
                            }

                            <button className="pointer refreshBtn" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>

                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                            {surveyStatsBarChart.surveyStatsBarChartDataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    surveyStatsBarChart.loadBarChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        surveyStatsBarChart.barChartFetched ? <>{surveyStatsBarChart.barChart}</> :
                                            (<BarChartSurvey></BarChartSurvey>)
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>

                    </CardContainer>
                </div>
                {

                    // <SectionRefresh sectionTitle={insightsLbls.summary} refreshClass={"marginTop-30"}>
                    //     <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(1)}></i>
                    // </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.surveySummary,
                                        "tooltipText": insightsLbls.tableActitiesofSurvey,
                                        "childern": [
                                            {
                                                "key": insightsLbls.surveyTitle,
                                                "value": insightsLbls.titleSurveyCreated
                                            },
                                            {
                                                "key": insightsLbls.label + ":",
                                                "value": insightsLbls.labelsofSurvey
                                            },
                                            {
                                                "key": insightsLbls.responses + ":",
                                                "value": insightsLbls.numberResponsesEachSurvey
                                            },
                                            {
                                                "key": insightsLbls.surveyDistribution + ":",
                                                "value": insightsLbls.distributionAnalyzedSurevyNegNeuPos
                                            },
                                            {
                                                "key": insightsLbls.lastUpdatedOn + ":",
                                                "value": insightsLbls.dateTimeofSurveyModification
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            }
                            <button className="pointer refreshBtn" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>
                        </ContainerTooltip>
                        {
                            surveyStatsSummaryTable.surveyStatsSummaryTableDataFetched ? (
                                errorCount.errorCount[1] === 0 ? (
                                    <div className="tabelEditing commonSummaryTable surveyStatsTable">
                                        <CommonTable tableId="1"
                                            data={fetched_details.surveyStats.surveyStatsSummaryTableData.data}
                                            currentPage={surveyStatsSummaryTable.page}
                                            barColors="positiveColors"
                                            linkPresent={true}
                                            showExport={true}
                                            showSearch={true}
                                            showEntries={true}
                                            defaultSort={surveyStatsSummaryTable.defaultSort}
                                            tablePagination={surveyStatsSummaryTable.tablePagination}
                                            tableEvent={handleTableEvents}
                                            firstTimeloadFlag={fetched_details.surveyStats.surveyStatsSummaryTableData.firstTimeload}
                                            modalTable={false}
                                        />
                                    </div>
                                )
                                    : errorCount.errorCount[1] === 1 ? (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                                        : null)
                                : (
                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }
                    </CardContainer>
                </div>

            </div>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>

    )
}

export default withRouter(withReducer("surveyStats", surveyStatsReducer)(Stats));
