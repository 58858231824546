// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect (props.data)

import React, { useEffect, ChangeEvent, useState, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ReactPaginate from "react-paginate";
import Pagination from 'components/Pagination/Pagination';
import { useSelector } from "react-redux";

import { OverlayTrigger, Tooltip as TooltipData } from "react-bootstrap";


import { getCall } from "../../utils/ApiCallActions";
import { AppsState, DatesState, LanguageState } from "../../store/Reducers";


import $ from 'jquery';
import { ApiRelativePaths, RetryApi } from '../../utils/GlobalConstants';
import { ChartColors } from "../../utils/GlobalConstants";
import cloneDeep from 'lodash/cloneDeep';
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import { useErrorBoundary } from 'react-error-boundary';

import Loader from "../Loader/Loader";
import "./CommonTable.scss";
import { InsightLabels } from 'labels';
import Icon from 'components/Icon/Icon'


let sortTitle: any = [];
let indexVal: number;


interface CurrentState {
	apps: AppsState;
	dates: DatesState;
	languageData: LanguageState
}

const CommonTable = (props: any) => {
	let fetched_details = useSelector((state: CurrentState) => state);
	const { showBoundary } = useErrorBoundary();
	const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);

	let labelChipsArr: any = [];
	let labelsArr: any = [];
	const [labelsModalData, setValue] = useState({
		labelModalOpen: false,
		labelChips: labelChipsArr,
		labels: labelsArr,
		chipsArray: [],
	});

	const [exportExcelModal, setExportExcelVal] = useState({
		openExportExcelModal: false,
	});

	const [subHeadingLabel, setSubHeadingLabel] = useState(new Map());

	let forcePage = props.currentPage - 1;
	const paginationVar = props.tablePagination;
	let defaultSort = props.defaultSort;

	const filterBy = [
		{ key: "5", value: "5" },
		{ key: "10", value: "10" },
		{ key: "20", value: "20" },
		{ key: "30", value: "30" },
	];

	const location = useLocation();
	if (props.linkPresent === false) {
		$("tbody tr td:first-child").css("color", "#000000");
		$("tbody tr td:first-child").css("cursor", "default");
	}

	const [tableLoader, setTableLoader] = useState(true);

	const [sortValue, setSortVal] = useState({
		sortVal: props.defaultSort.sortValue,
		index: props.defaultSort.sortColumn_index,
	});

	if (defaultSort !== undefined) {
		indexVal = props.defaultSort.sortColumn_index;
		sortTitle[indexVal] = sortValue.sortVal;
	}

	useEffect(() => {
		if (fetched_details.languageData.languageData) {
			setInsightsLbls(fetched_details.languageData.languageData);
		}

	}, [fetched_details.languageData.languageData])

	useEffect(() => {
		if (insightsLbls) {
			let subHeadingMap = new Map([
				["Name of Guide", insightsLbls.guideNameSubheadingLabel],
				["No. of guides played", insightsLbls.guidesPlayedSubheadingLabel],
				["No. of guides played in GuideMe Mode", insightsLbls.guidesPlayedInGuideMeModeSubheadingLabel],
				["No. of guides played in Do It For Me Mode", insightsLbls.guidesPlayedInDoItForMeModeSubheadingLabel],
				["No. of ShowMe played", insightsLbls.showMePlayedSubheadingLabel],
				["No. of TestMe played", insightsLbls.testMePlayedSubheadingLabel],
				["No. of Survey Responses", insightsLbls.surveyResponsesSubheadingLabel],
				["No. steps in guide experiencing errors", insightsLbls.stepsWithErrorsSubheadingLabel],
				["No. of times error was encountered", insightsLbls.timesErrorEncounteredSubheadingLabel],
				["Time stamp of the last recorded error", insightsLbls.lastErrorTimestampSubheadingLabel],
				["Name of Step", insightsLbls.stepNameSubheadingLabel],
				["Latest Timestamp", insightsLbls.stepLatestTimestampSubheadingLabel],
				["No. of times error was encountered", insightsLbls.stepErrorCountSubheadingLabel],
				["No. of time step was played", insightsLbls.stepPlayCountSubheadingLabel],
				["No. of times human intervention was performed", insightsLbls.humanInterventionCountSubheadingLabel],
				["No. of times step was skipped", insightsLbls.stepSkippedCountSubheadingLabel],
				["No. of time play errored on this step", insightsLbls.stepPlayErrorCountSubheadingLabel],
				["Name of Tutorial Guide", insightsLbls.tutorialGuideNameSubheadingLabel],
				["No. of tutorial guides played", insightsLbls.tutorialGuidesPlayedSubheadingLabel],
				["No. of tutorial guides completed", insightsLbls.tutorialGuidesCompletedSubheadingLabel],
				["No. of times play started from this step", insightsLbls.stepPlayStartedCountSubheadingLabel],
				["No. of times play ended on this step", insightsLbls.stepPlayEndedCountSubheadingLabel],
				["Name of Power Form", insightsLbls.powerFormNameSubheadingLabel],
				["No. of times Power Form Cancelled", insightsLbls.powerFormCancelledCountSubheadingLabel],
				["No. of times Power Form Submitted", insightsLbls.powerFormSubmittedCountSubheadingLabel],
				["Name of task list", insightsLbls.taskListNameSubheadingLabel],
				["No. of task list engaged", insightsLbls.taskListEngagedCountSubheadingLabel],
				["No. of task list completed", insightsLbls.taskListCompletedCountSubheadingLabel],
				["Task List Last updated on", insightsLbls.taskListLastUpdatedSubheadingLabel],
				["Name of user", insightsLbls.userNameSubheadingLabel],
				["Email address of user", insightsLbls.userEmailAddressSubheadingLabel],
				["User played atleast one task list guide", insightsLbls.userPlayedTaskListGuideSubheadingLabel],
				["User completed all guides in the task list", insightsLbls.userCompletedAllGuidesInTaskListSubheadingLabel],
				["GuideMe Play Count", insightsLbls.guideMePlayCountSubheadingLabel],
				["ShowMe Play Count", insightsLbls.showMePlayCountSubheadingLabel],
				["TestMe Play Count", insightsLbls.testMePlayCountSubheadingLabel],
				["DoItForMe Play Count", insightsLbls.doItForMePlayCountSubheadingLabel],
				["Tutorial Play Count", insightsLbls.tutorialPlayCountSubheadingLabel],
				["Unique Keyword Searched", insightsLbls.uniqueKeywordSearchedSubheadingLabel],
				["No. of Searches", insightsLbls.keywordSearchCountSubheadingLabel],
				["Average No. of results shown", insightsLbls.averageSearchResultsSubheadingLabel],
				["No. of time search resulted into action", insightsLbls.searchActionCountSubheadingLabel],
				["Complete URL of the page", insightsLbls.pageUrlSubheadingLabel],
				["No. of URL visits", insightsLbls.pageVisitCountSubheadingLabel],
				["No. of unique users visits", insightsLbls.uniqueUserVisitsSubheadingLabel],
				["No. of tags associated with this URL", insightsLbls.urlTagCountSubheadingLabel],
				["Name of page tag", insightsLbls.pageTagNameSubheadingLabel],
				["No. of page views", insightsLbls.pageViewCountSubheadingLabel],
				["No. of unique visitors", insightsLbls.uniquePageVisitorsSubheadingLabel],
				["Name of user who tagged the tag", insightsLbls.userWhoTaggedSubheadingLabel],
				["Last updated on", insightsLbls.pageLastUpdatedSubheadingLabel],
				["Name of Survey", insightsLbls.surveyNameSubheadingLabel],
				["No. of responses", insightsLbls.surveyResponseCountSubheadingLabel],
				["User Survey analysis", insightsLbls.userSurveyAnalysisSubheadingLabel],
				["Last update time", insightsLbls.surveyLastUpdatedSubheadingLabel],
				["Name of Conversation", insightsLbls.conversationNameSubheadingLabel],
				["No. of responses", insightsLbls.conversationResponseCountSubheadingLabel],
				["Last update time", insightsLbls.conversationLastUpdatedSubheadingLabel],
				["Name of Goal", insightsLbls.goalNameSubheadingLabel],
				["Goal Status", insightsLbls.goalStatusSubheadingLabel],
				["Goal Live date", insightsLbls.goalLiveDateSubheadingLabel],
				["Goal Completion date", insightsLbls.goalCompletionDateSubheadingLabel],
				["Goal Completion Percentage", insightsLbls.goalCompletionPercentageSubheadingLabel],
				["Actions available", insightsLbls.availableActionsSubheadingLabel]
			]);
			setSubHeadingLabel(subHeadingMap);
		}
	}, [insightsLbls]);

	useEffect(() => {
		setTableLoader(false);
	}, [props.data])


	const getWidth = (keyField: any, optn: number) => {

		try {
			const keys = Object.keys(keyField);
			const total = keyField[keys[0]] + keyField[keys[1]] + keyField[keys[2]];

			if (
				keyField[keys[0]] === 0 &&
				keyField[keys[1]] === 0 &&
				keyField[keys[2]] === 0
			) {
				return "33.3%";
			} else {
				return (keyField[optn] * 100) / total + "%";
			}
		} catch (error) {
			showBoundary(error)
		}

	};

	const getBarColor = (key: string, value: number) => {
		try {
			if (props.barColors === "positiveColors") {
				if (key === "positive") {
					if (value > 0) {
						return ChartColors.lightgreen;
					} else {
						return "#a5a5a5";
					}
				} else if (key === "negative") {
					if (value > 0) {
						return ChartColors.brightred;
					} else {
						return "#a5a5a5";
					}
				} else if (key === "neutral") {
					if (value > 0) {
						return ChartColors.yellowsandybrown;
					} else {
						return "#a5a5a5";
					}
				}
			} else if (props.barColors === "showmeColors") {
				if (key === "videoPlay") {
					if (value > 0) {
						return ChartColors.brightred;
					} else {
						return "#a5a5a5";
					}
				} else if (key === "gifPlay") {
					if (value > 0) {
						return ChartColors.mediumpurple;
					} else {
						return "#a5a5a5";
					}
				} else if (key === "slideShowPlay") {
					if (value > 0) {
						return ChartColors.darkturquoise;
					} else {
						return "#a5a5a5";
					}
				}
			}
		} catch (error) {
			showBoundary(error)
		}

	};

	const getBgColors = (lableArr: any, opt: number) => {
		try {
			if (opt === 1) {
				let bgcolor = lableArr[0]?.chipColorCode;
				return bgcolor;
			} else if (opt === 2) {
				let bgcolor = lableArr;
				return bgcolor;
			}
		} catch (error) {
			showBoundary(error)
		}

	};

	const getFontColors = (lableArr: any, opt: number) => {
		try {
			if (opt === 1) {
				let color = lableArr[0]?.chipFontColorCode;
				return color;
			} else {
				let color = lableArr;
				return color;
			}
		} catch (error) {
			showBoundary(error)
		}


	};

	const getTooltip = (keys: any, item: any, i: number) => {
		try {
			if (keys !== null && keys !== undefined && keys !== "") {
				let data: any;
				for (let i = 0; i < keys.length; i++) {
					if (keys[i] === 'pageUrl') {
						data = <div>
							<p style={{ textTransform: 'capitalize' }}>{data}</p>
							<p>({item[keys[i]]})</p>
						</div>
					} else {
						data = data ?? '' + item[keys[i]];
					}
				}
				return data;
			} else {
				return "";
			}
		} catch (error) {
			showBoundary(error)
		}

	};

	const tagThis = (data: any) => {
		try {
			props.tableEvent("tagPage", data.pageTitle);

		} catch (error) {
			showBoundary(error)
		}
	};

	const openLabelsModal = () => {
		try {
			getLabelsList();

			setValue((prevState: any) => {
				return {
					...prevState,
					labelModalOpen: true,
					chipsArray: cloneDeep(labelsModalData.labels),
				}
			})
		} catch (error) {
			showBoundary(error)
		}

	};

	const getLabelsList = (apiErrorFlag?: string) => {
		try {
			let params = {};
			let path = ApiRelativePaths.GET_LABEL_LIST;
			let paramObj = {};

			getCall(params, "GET_LABEL_LIST", apiErrorFlag ?? '').then((data: any) => {
				if (data.result === "success") {

					setValue((prevState: any) => {
						return {
							...prevState,
							labelModalOpen: true,
							labelChips: data.data,
						}
					})

					for (let i = 0; i < labelsModalData.labelChips.length; i++) {
						for (let j = 0; j < labelsModalData.labels.length; j++) {
							if (
								labelsModalData.labels[j].toLowerCase() ==
								labelsModalData.labelChips[i].chipName.toLowerCase()
							) {
								setTimeout(() => {
									$(".chip_" + i).addClass("chipOutline");
								}, 100);
							}
						}
					}
				} else if (data.result === 'retry') {
					setTimeout(() => {
						getLabelsList('retry')
					}, RetryApi.TIMEOUT)
				}
			});
		} catch (error) {
			showBoundary(error)
		}

	};

	const selectChip = (chipName: any, i: number) => {
		try {
			let arr: any = [];
			arr = labelsModalData.chipsArray;

			if ($(".chip_" + i).hasClass("chipOutline")) {
				$(".chip_" + i).removeClass("chipOutline");
				for (let i = 0; i < arr.length; i++) {
					if (arr[i] === chipName) {
						arr.splice(i, 1);
					}
				}
			} else {
				if (arr.length < 9) {
					arr.push(chipName);
					$(".chip_" + i).addClass("chipOutline");
				}
			}

			setValue((prevState: any) => {
				return {
					...prevState,
					labelModalOpen: true,
					chipsArray: cloneDeep(arr),
					labels: []
				}
			})

			if (labelsModalData.chipsArray.length === 0) {
				// $('.ages').prop('disabled', true);
			} else {
				$(".submitLabels").prop("disabled", false);
			}
		} catch (error) {
			showBoundary(error)
		}

	};

	const clearLabels = () => {
		try {
			$(".labelList").removeClass("active-i");
			for (let j = 0; j < labelsModalData.labelChips.length; j++) {
				$(".chip_" + j).removeClass("chipOutline");
			}

			setValue((prevState: any) => {
				return {
					...prevState,
					chipsArray: [],
				}
			})
		} catch (error) {
			showBoundary(error)
		}


	};

	const submitLabels = () => {
		try {
			$(".labelList").addClass("active-i");

			setValue((prevState: any) => {
				return {
					...prevState,
					labelModalOpen: false,
					labels: cloneDeep(labelsModalData.chipsArray),
				}
			})

			props.tableEvent("applyLabels", labelsModalData.chipsArray);
		} catch (error) {
			showBoundary(error)
		}

	};

	const routeToDetail = (index: number, key: any, value: any, arr: any, isLinkable: boolean) => {
		try {
			if (isLinkable == undefined || isLinkable === true) {
				let tabledata = props.data.tableData;
				if (index === 0) {
					for (let i = 0; i < tabledata.length; i++) {

						if (
							tabledata[i].tagCode !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].tagCode === arr["tagCode"] &&
							tabledata[i].pageViews > 0
						) {
							props.tableEvent("routeToDetail", { tagCode: tabledata[i].tagCode });
							// this.childEvent.emit({ tagCode: tabledata[i].tagCode });
						} else if (
							tabledata[i].sentimentCode !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].sentimentCode === arr["sentimentCode"] &&
							tabledata[i].sentimentResponses > 0
						) {



							props.tableEvent("routeToDetail", {
								sentimentCode: tabledata[i].sentimentCode,
								isDeleted: tabledata[i].isDeleted,
								sentimentTitle: tabledata[i].sentimentTitle,
								appCode: tabledata[i].appCode,
								appTitle: tabledata[i].appTitle,
								appId: tabledata[i].appID,
							});


							// this.childEvent.emit({ sentimentCode: tabledata[i].sentimentCode, isDeleted: tabledata[i].isDeleted, appCode: tabledata[i].appCode, appTitle: tabledata[i].appTitle, appId: tabledata[i].appID });
						} else if (
							tabledata[i].conversationCode !== undefined &&
							tabledata[i][key] === value
						) {
							props.tableEvent("routeToDetail", {
								conversationCode: tabledata[i].conversationCode,
								isDeleted: tabledata[i].isDeleted,
								conversationTitle: tabledata[i].conversationTitle,
								conversationResponse: tabledata[i].conversationResponses,
								appCode: tabledata[i].appCode,
								appTitle: tabledata[i].appTitle,
								appId: tabledata[i].appID,
							});
						} else if (
							tabledata[i].workflowID !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].workflowID === arr["workflowID"] &&
							tabledata[i].appCode !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].appCode === arr["appCode"] &&
							tabledata[i].appTitle !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].appTitle === arr["appTitle"]
						) {
							props.tableEvent("routeToDetail", {
								workflowCode: tabledata[i].workflowID,
								appCode: tabledata[i].appCode,
								appId: tabledata[i].appID,
								appTitle: tabledata[i].appName,
								appIconImageURL: tabledata[i].appIconImageURL,
								workflowTitle: tabledata[i].workflowTitle,
								workflowType: (tabledata[i].workflowType === 'Guide Workflow' ? 'guide_workflow' : 'dynamic_workflow')
							});
							// this.childEvent.emit({ workflowCode: tabledata[i].workflowID, appCode: tabledata[i].appCode, appId: tabledata[i].appID, appTitle: tabledata[i].appTitle, appIconImageURL: tabledata[i].appIconImageURL });
						} else if (
							tabledata[i].workflowID !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].workflowID === arr["workflowID"] &&
							tabledata[i].appCode !== undefined &&
							tabledata[i].appCode === arr["appCode"] &&
							tabledata[i].appID !== undefined &&
							tabledata[i].appID === arr["appID"]
						) {
							props.tableEvent("routeToDetail", {
								workflowCode: tabledata[i].workflowID,
								appCode: tabledata[i].appCode,
								appId: tabledata[i].appID,
								appTitle: tabledata[i].appTitle,
								workflowTitle: tabledata[i].workflowTitle,
								workflowType: (tabledata[i].workflowType === 'Guide Workflow' ? 'guide_workflow' : 'dynamic_workflow')
							});
						} else if (
							tabledata[i].workflowID !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].workflowID === arr["workflowID"] &&
							tabledata[i].appCode === undefined
						) {
							props.tableEvent("routeToDetail", {
								workflowCode: tabledata[i].workflowID,
								appCode: tabledata[i].appCode,
								appId: tabledata[i].appID,
								appTitle: tabledata[i].appTitle,
								workflowTitle: tabledata[i].workflowTitle,
								workflowType: (tabledata[i].workflowType === 'Guide Workflow' ? 'guide_workflow' : 'dynamic_workflow')
							});
							// this.childEvent.emit({ workflowCode: tabledata[i].workflowID, appCode: tabledata[i].appCode, appId: tabledata[i].appID, appTitle: tabledata[i].appTitle });
						} else if (
							tabledata[i].guideID !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].guideID === arr["guideID"]
						) {
							props.tableEvent("routeToDetail", {
								guideID: tabledata[i].guideID,
								guideTitle: tabledata[i].guideTitle,
								// appCode: tabledata[i].appCode,
								// appId: tabledata[i].appID,
								// appTitle: tabledata[i].appTitle,
								// appIconImageURL: tabledata[i].appIconImageURL,
							});
							// this.childEvent.emit({ guideID: tabledata[i].guideID, appCode: tabledata[i].appCode, appId: tabledata[i].appID, appTitle: tabledata[i].appTitle, appIconImageURL: tabledata[i].appIconImageURL });
						} else if (
							tabledata[i].tutorialID !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].tutorialID === arr["tutorialID"]
						) {
							props.tableEvent("routeToDetail", {
								tutorialID: tabledata[i].tutorialID,
								tutorialTitle: tabledata[i].tutorialTitle,
								// appCode: tabledata[i].appCode,
								// appId: tabledata[i].appID,
								// appTitle: tabledata[i].appTitle,
								// appIconImageURL: tabledata[i].appIconImageURL,
							});
							// this.childEvent.emit({ guideID: tabledata[i].guideID, appCode: tabledata[i].appCode, appId: tabledata[i].appID, appTitle: tabledata[i].appTitle, appIconImageURL: tabledata[i].appIconImageURL });
						}
						else if (
							tabledata[i].uniqueUserID !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].uniqueUserID === arr["uniqueUserID"]
						) {
							const userEngagementId = btoa(tabledata[i].uniqueUserID);
							props.tableEvent("routeToDetail", {
								uniqueUserID: userEngagementId,
								userDisplayName: tabledata[i].userDisplayName
								// isDeleted: tabledata[i].isDeleted,
								// appTitle: tabledata[i].appTitle,
								// appId: tabledata[i].appID,
							});
						}
						else if (
							tabledata[i].guide_id !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].guide_id === arr["guide_id"]
						) {
							props.tableEvent("routeToDetail", {
								guide_id: tabledata[i].guide_id,
								guide_title: tabledata[i].guide_title
								// isDeleted: tabledata[i].isDeleted,
								// appTitle: tabledata[i].appTitle,
								// appId: tabledata[i].appID,
							});
						} else if (
							tabledata[i].jobID !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].jobID === arr["jobID"]
						) {


							props.tableEvent("routeToDetail", {
								suiteID: tabledata[i].jobID,
								suiteTitle: tabledata[i].jobTitle,
								viewType: "jobs_list",
								jobRunId: tabledata[i].jobRunID,
							});
						} else if (
							tabledata[i].suiteID !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].suiteID === arr["suiteID"]
						) {
							props.tableEvent("routeToDetail", {
								suiteID: tabledata[i].suiteID,
								suiteTitle: tabledata[i].suiteTitle,
								viewType: tabledata[i].jobRunID ? 'job' : "suite"
							});
						}
						else if (
							tabledata[i].playCode !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].playCode === arr["playCode"]
						) {
							props.tableEvent("routeToDetail", {
								playCode: tabledata[i].playCode,
								title: tabledata[i].title,
							});
						}

						else if (
							tabledata[i].featureCode !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].featureCode === arr["featureCode"] &&
							tabledata[i].featureInteractions !== 0
						) {
							props.tableEvent("routeToDetail", {
								featureCode: tabledata[i].featureCode,
								isDeleted: tabledata[i].isDeleted,
								featureGuideTitle: tabledata[i].featureGuideTitle,
							});

						}

						else if (
							tabledata[i].userID !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].userID === arr["userID"] &&
							tabledata[i].featureInteractions !== 0 &&
							tabledata[i].userID !== '0' && !location.pathname.includes("task-list-detail")
						) {
							props.tableEvent("routeToDetail", {
								userID: tabledata[i].userID,
								isDeleted: tabledata[i].isDeleted,
								userFullName: tabledata[i].userFullName,
							});
						}
						else if (
							tabledata[i].userID !== undefined &&
							tabledata[i][key] === value &&
							tabledata[i].userID === arr["userID"] &&
							tabledata[i].pageVisits !== 0 &&
							tabledata[i].userID !== '0' && !location.pathname.includes("task-list-detail")
						) {
							props.tableEvent("routeToDetail", {
								userID: tabledata[i].userID,
								isDeleted: tabledata[i].isDeleted,
								userFullName: tabledata[i].userFullName,
							});
						} else if (tabledata[i].taskListID !== undefined &&
							tabledata[i][key] === value) {
							props.tableEvent("routeToDetail", {
								taskListID: tabledata[i].taskListID,
							});
						} else if (tabledata[i].userID !== undefined && location.pathname.includes("task-list-detail")) {
							props.tableEvent("routeToDetail", {
								userID: arr["userID"],
							});
						} else if (
							tabledata[i].goalCode !== undefined &&
							(tabledata[i].goalStatus !== 'Saved' || tabledata[i].goalStatus !== 'Published') &&
							tabledata[i][key] === value &&
							tabledata[i].goalCode === arr["goalCode"]
						) {
							props.tableEvent("routeToDetail", {
								goalCode: tabledata[i].goalCode,
								goalTitle: tabledata[i].goalTitle
							});
						}
					}
				}
			} else {
				return false;
			}
		} catch (error) {
			showBoundary(error)
		}


	};

	const sortColumn = (value: string, index: number) => {
		try {
			forcePage = 0;
			indexVal = index;
			sortTitle[index] = sortValue.sortVal;

			if (sortTitle[index] === undefined) {
				setSortVal((prevState: any) => {
					return {
						...prevState,
						sortVal: "desc",
						index: index,
					}
				});

				const sortData = {
					sortKey: value,
					sortValue: "desc",
					index: index,
				};
				props.tableEvent("sortColumn", sortData);
			} else {
				if (sortTitle[index] === "desc") {
					setSortVal((prevState: any) => {
						return {
							...prevState,
							sortVal: "asc",
							index: index,
						}
					});

					const sortData = {
						sortKey: value,
						sortValue: "asc",
						index: index,
					};
					props.tableEvent("sortColumn", sortData);

				} else {
					setSortVal((prevState: any) => {
						return {
							...prevState,
							sortVal: "desc",
							index: index,
						}
					});

					const sortData = {
						sortKey: value,
						sortValue: "desc",
						index: index,
					};
					props.tableEvent("sortColumn", sortData);
				}
			}
			setTableLoader(true);
		} catch (error) {
			showBoundary(error)
		}

	};

	const changePaginationClick = useCallback((e: any) => {
		try {
			props.tableEvent("changePage", e);
			forcePage = e;
			setTableLoader(true);
		} catch (error) {
			showBoundary(error)
		}
	}, [forcePage]);

	const onChangeEntries = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
		try {
			forcePage = 0;
			props.tableEvent("changeEntries", e);
			setTableLoader(true);
		} catch (error) {
			showBoundary(error)
		}
	}, [paginationVar.perPage]);

	const onChangeDynamicEntries = (e: ChangeEvent<HTMLSelectElement>, title: string) => {
		try {
			forcePage = 0;
			props.tableEvent("changeDynamicEntries", { data: e.target.value, title: title });
			setTableLoader(true);
		} catch (error) {
			showBoundary(error)
		}

	};

	const onHideDeleted = (e: any) => {
		try {
			forcePage = 0;
			props.tableEvent("hideDeleted", e.target.checked ? 1 : 0);
			setTableLoader(true);
		} catch (error) {
			showBoundary(error)
		}

	};

	const onSearchText = (e: ChangeEvent<HTMLInputElement>) => {
		try {
			forcePage = 0;
			props.tableEvent("searchTxt", e.target.value);
			setTableLoader(true);
		} catch (error) {
			showBoundary(error)
		}

	};

	const exportExcel = () => {
		try {
			setExportExcelVal({ ...exportExcelModal, openExportExcelModal: true });
		} catch (error) {
			showBoundary(error)
		}

	};

	const getCsv = (viewOl: boolean) => {
		try {
			props.tableEvent("exportExcel", viewOl);
			setExportExcelVal({ ...exportExcelModal, openExportExcelModal: false });
		} catch (error) {
			showBoundary(error)
		}

	};

	const openLinkedModal = (guideId: number, sourceId: number) => {
		try {
			interface ids {
				guideId: number;
				sourceId: number;
			}

			const data: ids = {
				guideId: guideId,
				sourceId: sourceId,
			}

			props.tableEvent("linkedModal", data);
		} catch (error) {
			showBoundary(error)
		}

	}

	const getDivLabelBg = (label: string) => {
		try {
			switch (label) {
				case 'pass':
					return '#76c761';
				case 'fail':
					return '#ef6363';
				case 'incomplete':
					return '#ffba51';
			}
		} catch (error) {
			showBoundary(error)
		}

	}

	const getColumnFontColor = (text: string, data: any) => {
		try {
			switch (text) {
				case 'complete':
				case 'pass':
					return ChartColors.lightgreen;

				case 'fail':
					return ChartColors.brightred;

				case 'incomplete':
					return ChartColors.orangesandybrown;

				default:
					return ChartColors.grey
			}
		} catch (error) {
			showBoundary(error)
		}

	}

	const getOptionValue = (value: string) => {
		try {
			switch (value) {
				case 'All':
					return insightsLbls['all'];

				case 'Scheduled':
					return insightsLbls['scheduled'];

				case 'Manual':
					return insightsLbls['manual'];

				case 'Pass':
					return insightsLbls['pass'];

				case 'Fail':
					return insightsLbls['fail'];

				case 'Complete':
					return insightsLbls['completed'];

				case 'Interrupted':
					return insightsLbls['interrupted'];

				case 'Guide':
					return value;

				case 'Dynamic':
					return value;

				default:
					return insightsLbls['all'];
			}
		} catch (error) {
			showBoundary(error)
		}

	}

	const getItemValue = (value: string) => {
		try {
			switch (value) {
				case 'positive':
					return insightsLbls['positive'];

				case 'negative':
					return insightsLbls['negative'];

				case 'neutral':
					return insightsLbls['neutral'];

				case 'Pass':
					return insightsLbls['pass'];

				case 'Fail':
					return insightsLbls['fail'];

				case 'Complete':
					return insightsLbls['completed'];

				case 'Interrupted':
					return insightsLbls['interrupted'];

				case 'Guide':
					return value;

				case 'Dynamic':
					return value;

				default:
					return insightsLbls['all'];
			}
		} catch (error) {
			showBoundary(error)
		}

	}

	const getClasses = (data: any, i: any) => {
		try {
			let cnt = 0;
			if ((data['tagCode'] !== undefined) && data['pageViews'] > 0) {
				cnt = cnt + 1
			} else if ((data['featureCode'] !== undefined) && data['featureInteractions'] > 0) {
				cnt = cnt + 1
			} else if ((data['userID'] !== undefined) && (data['featureInteractions'] > 0 && data['userID'] !== '0')) {
				cnt = cnt + 1
			} else if ((data['sentimentCode'] !== undefined) && (data['sentimentResponses'] > 0 && (data['appCode'] !== "" || data['appID'] !== ""))) {
				cnt = cnt + 1
			} else if ((data['conversationCode'] !== undefined) && (data['conversationResponses'] > 0)) {
				cnt = cnt + 1
			} else if ((data['pageVisits'] !== undefined) && (data['pageVisits'] > 0) && (data['userID']) !== '0') {
				cnt = cnt + 1
			} else if ((data['taskListID'] !== undefined) && (data['taskListPlays'] > 0)) {
				cnt = cnt + 1
			} else if (((data['userID'] !== undefined) && location.pathname.includes("task-list-detail"))) {
				cnt = cnt + 1
			} else if (((data['goalCode'] !== undefined) && location.pathname.includes("goal"))) {
				cnt = cnt + 1
			}

			if (i == 0) {
				if (data['tagCode'] == undefined &&
					data['featureCode'] == undefined &&
					data['userID'] == undefined &&
					data['sentimentCode'] == undefined &&
					data['conversationCode'] == undefined &&
					data['pageVisits'] == undefined &&
					data['taskListID'] == undefined &&
					data['goalCode'] == undefined) {
					cnt = cnt + 1
				}
			}


			if (data['isLinkable'] === false) {
				cnt = 0
			}

			if (location.pathname.includes('goal')) {
				if (data.goalStatus == 'Saved' || data.goalStatus == 'Published') {
					cnt = 0
				}
			}


			return cnt > 0 ? ' showCursor pointer' : ' hideCursor'
		} catch (error) {
			showBoundary(error)
		}

	}

	const getRoutePath = (data: any, i: any, d: any) => {
		try {
			let cnt = 0;
			if ((data['tagCode'] !== undefined) && data['pageViews'] > 0) {
				cnt = cnt + 1
			} else if ((data['featureCode'] !== undefined) && data['featureInteractions'] > 0) {
				cnt = cnt + 1
			} else if ((data['userID'] !== undefined) && (data['featureInteractions'] > 0 && data['userID'] !== '0')) {
				cnt = cnt + 1
			} else if ((data['sentimentCode'] !== undefined) && (data['sentimentResponses'] > 0 && (data['appCode'] !== "" || data['appID'] !== ""))) {
				cnt = cnt + 1
			} else if ((data['conversationCode'] !== undefined) && (data['conversationResponses'] > 0)) {
				cnt = cnt + 1
			} else if ((data['pageVisits'] !== undefined) && (data['pageVisits'] > 0)) {
				cnt = cnt + 1
			} else if ((data['taskListID'] !== undefined) && (data['taskListPlays'] > 0)) {
				cnt = cnt + 1
			} else if ((data['userID'] !== undefined && location.pathname.includes("task-list-detail"))) {
				cnt = cnt + 1;
			} else if (((data['goalCode'] !== undefined) && location.pathname.includes("goal"))) {
				cnt = cnt + 1
			}



			if (i == 0) {
				if (data['tagCode'] == undefined &&
					data['featureCode'] == undefined &&
					data['userID'] == undefined &&
					data['sentimentCode'] == undefined &&
					data['conversationCode'] == undefined &&
					data['pageVisits'] == undefined &&
					data['taskListID'] == undefined &&
					data['goalCode'] == undefined) {
					cnt = cnt + 1
				}
			}

			if (data['isLinkable'] === false) {
				cnt = 0
			}

			if (location.pathname.includes('goal')) {
				if (data.goalStatus == 'Saved' || data.goalStatus == 'Published') {
					cnt = 0
				}
			}

			return cnt > 0 ? routeToDetail(
				i,
				d.associatedDataField,
				data[d.associatedDataField],
				data,
				data['isLinkable']
			) : null
		} catch (error) {
			showBoundary(error)
		}

	}

	// let extensionId = "bhneolnhloclmfaoleepkeijiajfhkhn";
	// var playerExtImgUrl = "chrome-extension://"+extensionId+"/common/img/widget_icon.png";

	const openOrInstallExtension = (data: any) => {
		try {
			// var img;
			// img = new Image();
			// img.src = playerExtImgUrl;
			// img.onload = function () {
			let reqObj = {
				action: 'myguide_action:find_element_request_from_insight',
				mgdata: {
					step: data['stepID']
				}
			};
			let message = JSON.stringify(reqObj);
			window.postMessage(message, "*");
			// };
			// img.onerror = function () {
			// };
		} catch (error) {
			showBoundary(error)
		}
	}

	const openDetailPage = (data, type) => {
		props.tableEvent("errorDetails", data, type);
	}

	const goalEvents = (type, data) => {
		props.tableEvent(type, data);
	}

	const getButtonText = (text: string) => {
		try {
			if (text === 'Tag Page') {
				return insightsLbls['tagPage']
			} else {
				return text
			}
		} catch (error) {
			showBoundary(error)
		}

	}

	const tooltip_data = (tooltipText: string) => (
		<TooltipData id={"tooltip_" + tooltipText}>
			<div><span className="">{tooltipText}</span></div>
		</TooltipData>
	)

	const getGoalAction_template = (data: any) => {
		return data['goalStatus'] !== 'Saved' || props.deleteGoalFlag ?
			<>
				<button className="editDeleteBtn" disabled>
					<i className="fa fa-edit editCard-icon margin8" aria-hidden="true"></i>
				</button>
				<button className="editDeleteBtn" disabled>
					<i className="fa fa-trash editCard-icon margin8" aria-hidden="true"></i>
				</button>
			</>
			:
			<>

				<button className="editDeleteBtn" onClick={() => goalEvents('edit', data,)} >
					<i className="fa fa-edit editCard-icon margin8" aria-hidden="true" ></i>
				</button>

				<button className="editDeleteBtn" onClick={() => goalEvents('delete', data)} >
					<i className="fa fa-trash editCard-icon margin8" aria-hidden="true"></i>
				</button>

			</>
	}

	const getGoalTime_template = (data: any) => {
		return new Date(data).toLocaleString('en-US', {
			month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true
		});
	}

	const getLocalizedSubHeadingLabel = (key: any) => {
		const val = subHeadingLabel.has(key) ? subHeadingLabel.get(key) : key;
		return <span>({val})</span>
	}

	const headerTitlesMap = new Map<string, string>([
		['Survey Name', insightsLbls.surveyName],
		['App Title', insightsLbls.appTitle],
		['Labels', insightsLbls.labels],
		['Responses', insightsLbls.responses],
		['Survey Distribution', insightsLbls.surveyDistribution],
		['Last updated on', insightsLbls.lastUpdatedOn],
		['Guide Title', insightsLbls.guideTitle],
		['Steps in guide with errors', insightsLbls.stepInGuideWithErrors],
		['Total Guide Play Errors', insightsLbls.totalGuidePlayErrors],
		['Latest error recorded on', insightsLbls.latestErrorRecorded],
		['Tutorial Guide Title', insightsLbls.guideTitle],
		['Tutorial Guide Played', insightsLbls.guidePlayed],
		['Tutorial Guide Completed', insightsLbls.guideCompleted],
		['Power Form', insightsLbls.powerForm],
		['Cancelled', insightsLbls.cancelled],
		['Submitted', insightsLbls.submitted],
		['Guidance', insightsLbls.guidanceLabel],
		['Validation', insightsLbls.validation],
		['Guidance & Validation', insightsLbls.guidanceAndValidation],
		['Digital Duct Tape', insightsLbls.digitalDuctTapeLabel],
		['Step Title', insightsLbls.stepTitle],
		['Step Played Count', insightsLbls.stepPlayedCount],
		['Started From This Step', insightsLbls.humanInterventionCount],
		['Ends On This Step', insightsLbls.skippedStepCount],
		['Failed On This Step', insightsLbls.failedOnStep],
		['Human Intervention Count', insightsLbls.humanInterventionCount],
		['Skipped Step Count', insightsLbls.skippedStepCount],
		['Task List Name ', insightsLbls.taskListNameLabel],
		['Task List Engagements', insightsLbls.taskListEngagementsLabel],
		['Task List Completed', insightsLbls.taskListCompletedLabel],
		['Task List Last Updated', insightsLbls.taskListLastUpdatedLabel],
		['Guide Name ', insightsLbls.guideName],
		['Guide Played by Users', insightsLbls.guidePlays],
		['User Name', insightsLbls.userName],
		['User Email', insightsLbls.userEmail],
		['Task List Interacted', insightsLbls.taskListInteracted],
		['GuideMe Plays', insightsLbls.guidePlayed],
		['Tutorial Plays', insightsLbls.tutorialGuidePlayed],
		['DoItForMe Plays', insightsLbls.doItForMePlayes],
		['ShowMe Plays', insightsLbls.showMePlays],
		['TestMe Plays', insightsLbls.testMePlays],
		['Total Plays', insightsLbls.totalPlaysLabel],
		['Keyword', insightsLbls.keyword],
		['Search Count', insightsLbls.numberOfSearches],
		['Avg. Result Returned', insightsLbls.avgResultRetured],
		['Clicks', insightsLbls.clicks],
		['Page Name', insightsLbls.pageName],
		['Page Views', insightsLbls.pageView],
		['Unique Visits', insightsLbls.uniqueVisits],
		['Created by', insightsLbls.createdBy],
		['Last Updated', insightsLbls.lastUpdated],
		['Complete URL', insightsLbls.completeURL],
		['URL Visits', insightsLbls.URLVisits],
		['Unique User Visits', insightsLbls.uniqueUserVisits],
		['Associated Page Tags', insightsLbls.associatedPageTags],
		['Feature Set Name', insightsLbls.featureSetName],
		['Interactions', insightsLbls.interactions],
		['Conversation Name', insightsLbls.conversationName],
		['Title', insightsLbls.title],
		['Play Time', insightsLbls.playTime],
		['Run Type', insightsLbls.runType],
		['Result', insightsLbls.result],
		['Status', insightsLbls.status],
		['Goal Title', insightsLbls.goalTitle],
		['Goal Status', insightsLbls.goalStatus],
		['Goal live on', insightsLbls.goalLiveOn],
		['Target Completion', insightsLbls.targetCompletion],
		['Current Status', insightsLbls.currentStatus],
		['Goal Actions', insightsLbls.goalActions],
		['Latest StartTimestamp', insightsLbls.latestStartTimestamp],
		['Step Error Counts', insightsLbls.stepErrorCounts]

	]);

	const getHeaderTitle = (title: string) => {
		return headerTitlesMap.get(title) || title;
	}

	const getIcon = (index) => {
		if(sortTitle[index] === "asc" && indexVal === index) {
			return <Icon className="menu-icon-img" img={`img/sort-asc-icon.svg`} />
		} else if(sortTitle[index] === "desc" && indexVal === index) {
			return <Icon className="menu-icon-img" img={`img/sort-desc-icon.svg`} />
		} else {
			return <Icon className="menu-icon-img" img={`img/sort-icon.svg`} />
		}
	}

	return (
		<>
			{props.data.hasOwnProperty('tableData') && (
				<div>
					{props.data.tableHeaders ?
						(

							<React.Fragment>
								<div className="displayContents">
									<div className="col-sm-12 col-12 displayFlex">
										<div className=" col-8 col-lg-8 padding0 marginTop-10">
											{props.showSearch ? (
												<p className="search-field-wrapper">
													<input
														type="text"
														autoComplete="off"
														className="searchIcon form-control round-input"
														onChange={(event) => onSearchText(event)}
														placeholder={insightsLbls && insightsLbls['search']}
														disabled={props.data.totalCount === 0 && props.firstTimeloadFlag}
													/>
												</p>
											) : (
												<p></p>
											)}
										</div>
										<div className="col-4 col-lg-4 padding0 marginTop-10">
											{props.showExport ? (
												<p className="export-button marginTB-8 pull-right">
													<button
														className="form-control btn btn-primary"
														data-toggle="modal"
														onClick={() => exportExcel()}
														disabled={props.data.totalCount === 0}

													>
														{/* <img
                                                                src={
                                                                    require("../../assets/images/export-excel.png")
                                                                }
                                                                width="22"
                                                                height="auto"
                                                                alt=""
                                                                className="excel-icon"
                                                            /> */}
														{insightsLbls['exportToExcel']}
													</button>
												</p>
											) : (
												<p></p>
											)}
										</div>
									</div>
									<div className="col-sm-12 displayFlex  padding0">
										<div className={"col-12 padding0 floatRight font13 commonTable alignCenter displayFlex" + `${(props.modalTable && !location.pathname.includes('task-list-detail')) ? " marginLeft-20" : ""}`}>
											{/* {props.showEntries ? (
												<div className="selectCustomColor displayGrid lineHeight-1">
													<span className="startAlign height30">{insightsLbls['showEntries']}</span>
													<select
														className="entries-dropdown"
														onChange={(event) => onChangeEntries(event)}
														value={props.tablePagination.perPage}
														disabled={props.data.totalCount === 0}
													>
														{filterBy.map((option, index) => {
															return <option value={option.key} key={index}>{option.value}</option>;
														})}
													</select>
												</div>
											) : (
												<div></div>
											)} */}



											{props.dynamicSort ? (
												<>
													{props.dynamicSort.map((option: any, index: number) => {
														return (
															<div className="selectCustomColor displayGrid marginLeft-10 width120 lineHeight-1">
																<span className="startAlign height30">{option['title']}</span>
																<select
																	className="entries-dropdown"
																	onChange={(event) => onChangeDynamicEntries(event, option['title'])}
																	value={option['defaultValue']}

																>
																	{option['value']?.map((option1: any, index1: number) => {
																		return <option value={option1.key} key={index1}>{getOptionValue(option1.value)}</option>;
																	})}
																</select>
															</div>
														);
													})}
												</>
											) : (
												<div></div>
											)}

											<>
												{props.hideDeleted && (
													<div className="marginLeft-5 marginTop25 custom-control custom-checkbox mr-sm-2 check-container">
														<label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5">
															<span className="marginLeft-30">{props.checkboxText}</span>
															{/* [checked]="hide_deleted==1" */}
															{/* [disabled]="!((firstTimeLoad === true && powerForm.length!=0) || firstTimeLoad === false)" */}
															<input type="checkbox"
																className="custom-control-input chk checkbox"
																id="customControlAutosizing1"
																onClick={(event) => onHideDeleted(event)}
																name="chk-box"
																value="delete"
																disabled={props.data.totalCount === 0 && props.firstTimeloadFlag ? true : false} />
															<span className="checkmark checkmark-tooltip displayInlineBlock top0"></span>
														</label>
													</div>
												)}

											</>
										</div>

									</div>
								</div>

								<table
									className={"table color_table commonTable tableId" + props.tableId}
								>
									<thead className="bg-secondary sorting-header">
										<tr>
											{props.data.tableHeaders.map((header: any, index: number) => {
												let subTitle;
												if (header.subTitle !== "") {
													subTitle = (
														<p className="marginBottom-0 font10 font600" key={index}>
															{getLocalizedSubHeadingLabel(header.subTitle)}
														</p>
													);
												}

												let labelFilterIcon;
												if (
													header.fieldDataType === "chips" &&
													!location.pathname.includes("dashboard")
												) {
													let countChips = labelsModalData.labels.length;
													let countTag;
													// onClick="openLabelModal(content)"
													if (countChips > 0) {
														countTag = <span className="chipCount" key={index}>{countChips}</span>;
													}
													labelFilterIcon = (
														<span
															className={
																"pull-right hand-pointer " + (countChips > 0 ? "active-i" : "")
															}
															onClick={() => openLabelsModal()}
														>
															<i className="fa fa-filter labelList"></i>
															{countTag}
														</span>
													);
												}

												let sortIcons;
												if (header.isSortAllowed === true) {
													sortIcons = (
														<span
															className="hand-pointer displayInlineBlock pull-right marginTop-0"
															onClick={() => sortColumn(header.sortKey, index)}
															key={index}
														>
															{getIcon(index)}
															{/* <i
																className={
																	"fa fa-sort-asc height0 displayBlock descending " +
																	(sortTitle[index] === "asc" && indexVal === index
																		? "active-i"
																		: "")
																}
															></i>
															<i
																className={
																	"fa fa-sort-desc height0 displayBlock ascending " +
																	(sortTitle[index] === "desc" && indexVal === index
																		? "active-i"
																		: "")
																}
															></i> */}
														</span>
													);
												}

												return (
													<th
														className={
															(index === 0 ? "th-left-border" : "") +
															(index === props.data.tableHeaders.length - 1
																? "th-right-border"
																: "")
														}
														key={index}
													>
														<span className="thTitle-span">
															<span>
																{getHeaderTitle(header.displayTitle)}&nbsp;

																<span>{subTitle}</span>
																{labelFilterIcon}
															</span>
															<span>
																{sortIcons}
															</span>
														</span>


													</th>
												);
											})}
										</tr>
									</thead>
									{!tableLoader ? (
										props.data.tableData.length !== 0 ? (
											<tbody>
												{props.data.tableData.map((data: any, index: number) => {
													return (
														<tr>
															{props.data.tableHeaders.map((d: any, i: number) => {
																let text;
																let tooltipText = getTooltip(d.altText, data, i);

																if (
																	(d.fieldDataType === "string"
																		|| d.fieldDataType === "number"
																		|| d.fieldDataType === "label") &&
																	d.fieldDataType !== "chips" &&
																	d.fieldDataType !== "button" &&
																	d.fieldDataType !== "ratio-bar"
																) {
																	if (tooltipText) {
																		text = (
																			<div className="displayFlex alignCenter" key={index}>
																				{data['linkedGuideID'] && (<i className="fa fa-link marginRight-5 top5 positionRelative"></i>)}
																				<div

																					data-tip
																					data-for={
																						"txtTooltip_" +
																						props.tableId +
																						"_" +
																						i +
																						"_" +
																						index
																					}
																					data-class="reactTooltipStyle"
																					className={
																						"ellipsistext textCapitalize" +
																						(d.altText !== null ? " tableContent displayInlineBlock " : "")
																						+ (data['linkedGuideID'] ? ' showCursor' : (props.linkPresent ? getClasses(data, i) : ' hideCursor'))

																						// (props.linkPresent && (data['featureCode'] !== undefined)) ? ((data['featureInteractions'] > 0 ? ' showCursor pointer' : ' hideCursor')) :
																						// (props.linkPresent && (data['userID'] !== undefined)) ? ((data['featureInteractions'] > 0 && data['userID'] !== '0' ? ' showCursor pointer' : ' hideCursor')) :
																						// 	(props.linkPresent && (data['sentimentCode'] !== undefined)) ? (((data['sentimentResponses'] > 0 && (data['appCode'] !== "" || data['appID'] !== "")) ? ' showCursor pointer' : ' hideCursor')) :
																						// 		(props.linkPresent && (data['conversationCode'] !== undefined)) ? ((data['conversationResponses'] > 0 ? ' showCursor pointer' : ' hideCursor')) :
																						// 			(props.linkPresent && (data['tagCode'] !== undefined)) ? ((data['pageViews'] > 0 ? ' showCursor pointer' : ' hideCursor')) :
																						// 				(props.linkPresent && i === 0 && (data['appCode'] !== "" || data['appID'] !== "")) ? (data['isLinkable'] === undefined ? ' showCursor pointer' : (data['isLinkable'] ? ' showCursor pointer' : ' hideCursor')) : ' hideCursor')
																					}
																					onClick={() => {
																						getRoutePath(data, i, d);
																						{ data['linkedGuideID'] && openLinkedModal(data['linkedGuideID'], props.data['guideID']) }

																					}
																					}
																				>
																					{data[d.associatedDataField]}
																					<ReactTooltip
																						id={
																							"txtTooltip_" +
																							props.tableId +
																							"_" +
																							i +
																							"_" +
																							index
																						}
																						place="bottom"
																					>
																						{tooltipText}
																					</ReactTooltip>
																				</div>
																			</div>
																		);
																	} else {
																		text = (
																			<div
																				className={
																					"tableContent textCapitalize " +
																					((d.altText !== null ? "displayInlineBlock" : "") + ((props.linkPresent && i === 0) ? ' showCursor 1' : ' hideCursor')) +
																					((props.tableId == 'tutorialGuideTable' ? "  ellipsistext250Width" : " ellipsistext"))
																				}


																				style={{ color: getColumnFontColor(data[d.associatedDataField], data) ?? '#000000' }}
																				onClick={() =>
																					routeToDetail(
																						i,
																						d.associatedDataField,
																						data[d.associatedDataField],
																						data,
																						data['isLinkable']
																					)
																				}

																			>
																				{(props.statusFailAction && (d.associatedDataField === "failedOnThisStep" || d.associatedDataField === 'stepErrorCounts') &&
																					data[d.associatedDataField] > 0)
																					? (
																						<>{data[d.associatedDataField]}
																							<div className='result-status-container'>
																								<div>
																									{d.associatedDataField === "failedOnThisStep" && (
																										<>

																											<OverlayTrigger placement={"bottom"} overlay={tooltip_data('Replace')}>
																												<button
																													className="cursorPointer resultStatusBtn"
																													onClick={() => openOrInstallExtension(data)}
																												>
																													<i className={"fa fa-exchange"}></i>
																												</button>
																											</OverlayTrigger>

																											<OverlayTrigger placement={"bottom"} overlay={tooltip_data('Details')}>
																												<button className="cursorPointer resultStatusBtn"
																													onClick={() => openDetailPage(data, props.statusFailAction)}>
																													<i className={"fa fa-window-maximize"}></i>
																												</button>
																											</OverlayTrigger>
																											<SectionTooltip tooltipText={
																												{
																													"tooltipText": insightsLbls.creatorExtensionRequired,
																												}
																											} placement="bottom"></SectionTooltip>
																										</>
																									)}

																									{d.associatedDataField === "stepErrorCounts" && (
																										<>
																											<OverlayTrigger placement={"bottom"} overlay={tooltip_data('Details')}>
																												<button className="cursorPointer resultStatusBtn"
																													onClick={() => openDetailPage(data, props.statusFailAction)}>
																													<i className={"fa fa-window-maximize"}></i>
																												</button>
																											</OverlayTrigger>
																											<SectionTooltip tooltipText={
																												{
																													"tooltipText": insightsLbls.guideStepErrorDetails
																													,
																												}
																											} placement="bottom"></SectionTooltip>
																										</>
																									)}

																								</div>


																							</div>
																						</>
																					)
																					: (["goalEndTimestamp", "goalStartTimestamp", "goalActions"].includes(d.associatedDataField) ?
																						["goalEndTimestamp", "goalStartTimestamp"].includes(d.associatedDataField) ? getGoalTime_template(data[d.associatedDataField]) : getGoalAction_template(data)
																						:
																						data[d.associatedDataField])
																				}
																			</div>
																		);
																	}
																}

																let deletedTag;
																if (i === 0 && data.isDeleted === true) {
																	deletedTag = (
																		<span className="stepDeleted">({insightsLbls['deleted']})</span>
																	);
																}

																let ratioBar;
																if (d.fieldDataType === "ratio-bar") {
																	ratioBar = (
																		<div
																			className="ratioBar"
																			data-tip
																			data-for={
																				"ratioBarTooltip_" +
																				props.tableId +
																				"_" +
																				i +
																				"_" +
																				index
																			}
																			data-class="reactTooltipStyle"
																		>

																			{location.pathname.includes('goal') ? (
																				<div className='width100'>
																					<div className="width100">
																						{+data[d.associatedDataField] * 1.0 > 100 && (
																							<span className="extraGoalPercentage">
																								+{parseFloat((+data[d.associatedDataField] * 1.0 - 100).toFixed(2))}%
																							</span>
																						)}
																					</div>
																					<div className='width100'>
																						<div className="progress-container">
																							<div className="progress-bar" style={{ width: ((+data[d.associatedDataField] * 1.00) > 100 ? 100 : (+data[d.associatedDataField] * 1.00)) + '%' }}></div>
																						</div>
																						<span>{(+data[d.associatedDataField] * 1.00) >= 100 ? (
																							<span>100%</span>
																						) : (
																							<span>{(+data[d.associatedDataField] * 1.00)}%</span>
																						)}</span>
																					</div>

																				</div>
																			) : (
																				<>
																					{Object.keys(data[d.associatedDataField]).map(
																						(item: any, index: number) => {
																							let barDivisionsStyle = {
																								width: getWidth(
																									data[d.associatedDataField],
																									item
																								),
																								backgroundColor: getBarColor(
																									item,
																									data[d.associatedDataField][item]
																								),
																							};
																							return (
																								<div
																									key={index}
																									className="ratioBarDivisions"
																									style={barDivisionsStyle}
																								></div>
																							);
																						}
																					)}
																					<ReactTooltip
																						id={
																							"ratioBarTooltip_" +
																							props.tableId +
																							"_" +
																							i +
																							"_" +
																							index
																						}
																						place="bottom"
																					>
																						<span>
																							{Object.keys(data[d.associatedDataField]).map(
																								(item: any, index: number) => {
																									return (
																										<span
																											key={index}
																											className="ratio-color spacing widthAuto"
																											style={{
																												backgroundColor: getBarColor(
																													item,
																													data[d.associatedDataField][item]
																												),
																											}}
																										>
																											{getItemValue(item)}:{" "}
																											{data[d.associatedDataField][item]}
																										</span>
																									);
																								}
																							)}
																						</span>
																					</ReactTooltip>
																				</>
																			)}


																		</div>
																	);
																}

																let btn;
																if (d.fieldDataType === "button") {
																	btn = (
																		<span>
																			<button
																				className="tabSwitch-btn1 taggedBtn cursorPointer"
																				onClick={() => tagThis(data)}
																			>
																				{getButtonText(data[d.associatedDataField])}
																			</button>
																		</span>
																	);
																}

																let label;
																let additionalLabels;
																let divLable;
																if (d.fieldDataType === "chips") {
																	if (data[d.associatedDataField] === "") {
																		label = <span className="tableContent"> - </span>;
																	} else {
																		if (data[d.associatedDataField] !== undefined) {
																			let chipStyle = {
																				backgroundColor: getBgColors(
																					data[d.associatedDataField],
																					1
																				),
																				color: getFontColors(
																					data[d.associatedDataField],
																					1
																				),
																			};

																			if (data[d.associatedDataField].length > 1) {
																				additionalLabels = (
																					<div
																						className={`statusChip statusChipTable marginLeft-5 displayInlineBlock ${
																						location.pathname.includes('survey') || location.pathname.includes('chatbot') || location.pathname.includes('dashboard') ? '' : 'tooltipText'
																					  }`}
																						data-class="reactTooltipStyle"
																						data-tip
																						data-for={
																							"labelChipsTooltip_" +
																							props.tableId +
																							"_" +
																							i +
																							"_" +
																							index
																						}
																					>
																						+{data[d.associatedDataField].length - 1}
																						<ReactTooltip
																							id={
																								"labelChipsTooltip_" +
																								props.tableId +
																								"_" +
																								i +
																								"_" +
																								index
																							}
																							place="bottom"
																						>
																							<span>
																								{data[d.associatedDataField].map(
																									(chip: any, j: number) => {
																										if (j !== 0) {
																											let chipListStyle = {
																												backgroundColor: getBgColors(
																													chip.chipColorCode,
																													2
																												),
																												color: getFontColors(
																													chip.chipFontColorCode,
																													2
																												),
																											};
																											return (
																												<span
																													key={j}
																													style={chipListStyle}
																													className="statusChip statusChipTable spacing widthAuto"
																												>
																													{chip.chipName}
																												</span>
																											);
																										}
																									}
																								)}
																							</span>
																						</ReactTooltip>
																					</div>
																				);
																			}


																			label = (
																				<span className="statusChip statusChipTable" style={Object.assign({}, chipStyle, data[d.associatedDataField][0]?.chipName?.length > 0 ? { top: '6px' } : { backgroundColor: 'white' })}>
																					{data[d.associatedDataField][0]?.chipName}
																				</span>
																			);
																		}
																	}
																}

																if (d.fieldDataType === "label") {
																	divLable = (
																		<span className={" verticalMiddle statusChip"} style={{ backgroundColor: getDivLabelBg(data[d.associatedDataField]) }}>
																			{data[d.associatedDataField]}
																		</span>
																	)
																}

																return (
																	<td
																		className={
																			"tableTooltip " +
																			(d.fieldDataType === "chips" ? " " : " ") +
																			(d.fieldDataType === "chips" ? "marginTop--1" : " ")
																		}
																	>
																		{/* for string and numbers */}
																		{divLable ?? text}

																		{/* for deleted tag */}
																		{deletedTag}

																		{/* for bar color */}
																		{ratioBar}

																		{/* for buttons */}
																		{btn}

																		{/* for labels */}
																		{label}

																		{additionalLabels}


																	</td>
																);
															})}
														</tr>
													);
												})}
											</tbody>
										) : (
											<tbody>
												<tr className="noDataText-fontCommonTable noDataText-font abc">
													<td
														className="textCenter noDataText-font"
														colSpan={props.data.tableHeaders.length}
													>
														{insightsLbls.noRecordFound}
													</td>
												</tr>
											</tbody>
										)
									) : (
										<tbody>
											<tr className="noDataText-fontCommonTable noDataText-font def">
												<td
													className="textCenter noDataText-font"
													colSpan={props.data.tableHeaders.length}
												>
													<Loader></Loader>
												</td>
											</tr>
										</tbody>)
									}

								</table>
								{
									props.data.totalCount > 0 ?
										(
											<div>
												{/* <div className="countContainer">
													<span className="paginations-showingOf-text marginLeft-5">{insightsLbls['showing']} </span>
													<span className="paginations-count-text">{((forcePage + 1) * paginationVar.perPage) - (paginationVar.perPage - 1)}</span>
													<span className="paginations-showingOf-text"> - </span>
													{
														((forcePage + 1) * paginationVar.perPage < props.data.totalCount) ?
															(<span className="paginations-count-text">{(forcePage + 1) * paginationVar.perPage}</span>)
															: (<span className="paginations-count-text">{props.data.totalCount}</span>)
													}
													<span className="paginations-showingOf-text marginLeft-5">{insightsLbls['of']} </span>
													<span className="paginations-count-text">{props.data.totalCount}</span>
												</div> */}
												{!location.pathname.includes('goal') && (
													<div className="paginationContainer">
														{/* <ReactPaginate
														previousLabel={<span aria-hidden="true">«</span>}
														nextLabel={<span className={"nexBtn" + props.tableId} aria-hidden="true">»</span>}
														breakLabel={"..."}
														breakClassName={"break-me"}
														pageCount={props.data.totalCount / paginationVar.perPage}
														marginPagesDisplayed={2}
														pageRangeDisplayed={5}
														onPageChange={(event) => changePaginationClick(event)}
														containerClassName={"pagination"}
														activeClassName={"active"}
														forcePage={forcePage}
													/> */}
														<Pagination
															showEntries={props.showEntries}
															pageNumber={forcePage}
															totalCount={props.data.totalCount}
															pageLimit={paginationVar.perPage}
															handleChange={(event) => changePaginationClick(event)}
															changeLimit={(event) => onChangeEntries(event)}
															rowsLabel={insightsLbls.rowsPerPage}
														/>
													</div>
												)}

											</div>
										) : (null)
								}



								<Modal
									onHide={() => setValue((prevState: any) => {
										return {
											...prevState,
											labelModalOpen: false
										}
									})}
									show={labelsModalData.labelModalOpen}
									bsSize="medium"
								>
									<Modal.Header>
										<Modal.Title>{insightsLbls['showLabels']}</Modal.Title>
										<button
											type="button"
											className="close"
											onClick={() =>
												setValue((prevState: any) => {
													return {
														...prevState,
														labelModalOpen: false
													}
												})
											}
										>
											<span aria-hidden="true">×</span>
											<span className="sr-only">{insightsLbls['close']}</span>
										</button>
									</Modal.Header>
									<Modal.Body>
										<div className="widthAuto labelsDiv">
											{labelsModalData.labelChips.map(function (chip: any, index: any) {
												let chipStyle = {
													backgroundColor: chip.chipColorCode,
													color: chip.chipFontColorCode,
												};
												return (
													<span
														key={index}
														style={chipStyle}
														className={"statusChip labelChips font12 floatLeft chip_" + index}
														onClick={() => selectChip(chip.chipName, index)}
													>
														{chip.chipName}
													</span>
												);
											})}
										</div>
									</Modal.Body>
									<Modal.Footer>
										<div className="col-lg-12">
											{labelsModalData.chipsArray.length === 9 && (<span className="font12 warningMsg-text">
												{insightsLbls['max9Allowed']}
											</span>)}

											<button
												className="btn btn-secondary floatRight"
												onClick={() => clearLabels()}
											>
												{insightsLbls['clearAll']}
											</button>
											<button
												className="btn btn-primary floatRight marginRight-30"
												onClick={() => submitLabels()}
											>
												{insightsLbls['apply']}
											</button>
										</div>
									</Modal.Footer>
								</Modal>

								<Modal
									onHide={() =>
										setExportExcelVal({
											...exportExcelModal,
											openExportExcelModal: false,
										})
									}
									show={exportExcelModal.openExportExcelModal}
									bsSize='medium'
								>
									<Modal.Header>
										<Modal.Title>{insightsLbls['exportExcel']}</Modal.Title>
										<button
											type="button"
											className="close"
											onClick={() =>
												setExportExcelVal({
													...exportExcelModal,
													openExportExcelModal: false,
												})
											}
										>
											<span aria-hidden="true">×</span>
											<span className="sr-only">{insightsLbls['close']}</span>
										</button>
									</Modal.Header>
									<Modal.Body className="displayFlex height100-percent">
										<div className="col-lg-12 paddingTB-20 displayFlex btns-div">
											<div className="downloadCsv col-lg-6 padding0">
												{/* (click)="getCsv(false)" */}
												<span
													className="btn btn-primary font13 width100"
													onClick={() => getCsv(false)}
												>
													{" "}
													<i className="fa fa-download" aria-hidden="true"></i> {insightsLbls['download']}
												</span>
											</div>
											<div className="marginTop-25">
												<div className="vl"></div>
												<div className="or-div">{insightsLbls['or']}</div>
											</div>
											<p className="text font14 marginLeft-10 font500 excelText">
												{insightsLbls['excelViewerQn']}
											</p>

											<div className="downloadCsv col-lg-6 padding0">
												{/* (click)="getCsv(true)" */}
												<span
													className="btn btn-primary font13"
													onClick={() => getCsv(true)}>
													<i className="fa fa-cloud" aria-hidden="true"></i> {insightsLbls['viewOnline']}
												</span>
											</div>
										</div>
									</Modal.Body>
								</Modal>
							</React.Fragment>
						)
						: (<div>{insightsLbls.serviceNotAvailable}</div>)
					}
				</div>)}

		</>
	)
};

export default React.memo(CommonTable);
