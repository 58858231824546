import $ from "jquery";
import { default as en_US } from 'utils/stringConstants/en_US';
import { default as fr_CA } from 'utils/stringConstants/fr_CA';
import { default as fr_FR } from 'utils/stringConstants/fr_FR';
import { default as ja_JP } from 'utils/stringConstants/ja_JP';
import { default as ko_KR } from 'utils/stringConstants/ko_KR';
import { default as pseudo } from 'build/insights_pseudo';


import insightsLabels from "utils/stringConstants/trans_utils";
let insightsLbls: any = insightsLabels;



export const CommonUtils = {
    LeftPanelHighlight: function (index: number, innerIndex: number | string, subInnerIndex: number | string, isDetail: boolean, isNavigate: boolean) {
        innerIndex = (innerIndex === null || innerIndex === '') ? 0 : innerIndex;
        subInnerIndex = (subInnerIndex === null || subInnerIndex === '') ? 0 : subInnerIndex;

        setTimeout(() => {
            const li = document.querySelectorAll('li');
            for (let i = 0; i < li.length; i++) {
                li[i].classList.remove('active-menu');
                li[i].classList.remove('active-sub-menu');
                li[i].classList.remove('active-sub-menu2');
            }

            const ul = document.querySelectorAll('.panel-collapse');
            for (let i = 0; i < ul.length; i++) {
                ul[i].classList.add('collapse');
            }

            // remove collapse on click
            $("#menu-" + index).removeClass("collapse");
            $("#menu-" + index + " #sub-submenu-" + innerIndex).removeClass("collapse");

            // highlight selected option
            $("#optn_" + index).addClass("active-menu");

            // if not dashboard then highlight icon
            // if (index !== 0){
            $("#optn_" + index + " #subMenu_" + innerIndex).addClass("active-sub-menu");
            // }
            $("#optn_" + index + " #subMenu_" + innerIndex + " #subMenu2_" + subInnerIndex).addClass("active-sub-menu2");
        }, 500);
    },

    HostSettings: function () {
    },

    URLGenerator: function (queryObject: any, relativePath: string, parametersObj: any) {
        // Initializing all data members.
        let protocol: string = 'https';
        let host = '';
        let port = null;
        let path = '/';
        let query = null;
        let baseUrl: any = process.env.REACT_APP_ANALYTICS_HOST;

        try {
            host = (baseUrl.match(/:\/\/(.[^/:]+)/i) === null) ? '' : (baseUrl.match(/:\/\/(.[^/:]+)/i)[1] || '');
            protocol = baseUrl.match(/^[^:]+(?=:\/\/)/i);
            port = (baseUrl.match(/:([0-9]+)/) === null) ? null : (baseUrl.match(/:([0-9]+)/)[1] || null);
            path = '/' + relativePath.split('/').filter(function (el) { return (el.length > 0) }).join('/') + '/';

            for (let itr1 = 0; itr1 < Object.keys(parametersObj).length; itr1++) {
                path = path.replace((':' + Object.keys(parametersObj)[itr1]), parametersObj[Object.keys(parametersObj)[itr1]]);
            }

            for (let itr2 = 0; itr2 < Object.keys(queryObject).length; itr2++) {
                query = (query === null) ? '?' :
                    (
                        Array.isArray(queryObject[Object.keys(queryObject)[itr2]]) ?
                            (queryObject[Object.keys(queryObject)[itr2]].length === 0 ?
                                (query)
                                : (query + '&')
                            )
                            : (query + '&')
                    );
                // query = query + (Object.keys(queryObject)[itr2] + '=' + (queryObject[Object.keys(queryObject)[itr2]]));

                query = query + (Array.isArray(queryObject[Object.keys(queryObject)[itr2]]) ?
                    queryObject[Object.keys(queryObject)[itr2]]
                        .map(function (elem: any) {
                            return (Object.keys(queryObject)[itr2] + '=' + elem)
                        }).join('&')
                    :
                    (Object.keys(queryObject)[itr2] + '=' + queryObject[Object.keys(queryObject)[itr2]]));

            }
        } catch (err) {
            throw 'Invalid or missing URL properties1.'
        }


        let str_url = '';

        try {
            str_url = ((host !== '') ? (protocol + '://' + host) : '') + ((port === null || port === '') ? '' : (':' + port)) + path + ((query !== null) ? query : '');
            // str_url = ((host !== '') ? (protocol + '://' + host) : '' ) +  path + ((query !== null) ? query : '');

        } catch (err) {
            throw 'Invalid or missing URL properties.'
        }

        return str_url;
    },

    clearLocalStorage: function (storeArray: string | any[]) {
        let clear_array = [];
        clear_array[0] = 'ORG_IMG_URL';
        clear_array[1] = 'CDN_SIGNATURE';
        clear_array[2] = 'ORG_NAME';
        clear_array[3] = 'max_date';
        clear_array[4] = 'USER_FNAME';
        clear_array[5] = 'USER_LNAME';
        clear_array[6] = 'USER_ACTIVITY';
        clear_array[7] = 'USER_PROFILE';
        clear_array[8] = 'orgkey';
        clear_array[9] = 'VIEW_TYPE';
        clear_array[10] = 'ACCESS_TOKEN';
        clear_array[11] = 'APP_KEY';
        clear_array[12] = 'dashboard_min_date';
        clear_array[13] = 'TDATE';
        clear_array[14] = 'A-FDATE';
        clear_array[15] = 'MODEL2';
        clear_array[16] = 'DEFAULT_UI';
        clear_array[17] = 'MYBOT_VISIBILITY';
        clear_array[18] = 'USER_SIGN';
        clear_array[19] = 'GUIDE_SELECTED_OPTN';
        clear_array[20] = 'SECONDS';
        clear_array[21] = 'IMAGE_ID';
        clear_array[22] = 'guideState';
        clear_array[23] = 'switch_app_code';
        clear_array[24] = 'firstApp_name';
        clear_array[25] = 'SELECTED_OPTN';
        clear_array[26] = 'SELECTED_APPS';
        clear_array[27] = 'min_date';
        clear_array[28] = 'A-TDATE';
        clear_array[29] = 'APP_FLAG';
        clear_array[30] = 'APP_COUNT';
        clear_array[31] = 'QA_AUTOMATOR';
        clear_array[32] = 'dashboard_max_date';
        clear_array[33] = 'pageRefreshEvent';
        clear_array[34] = 'FDATE';
        clear_array[35] = 'eventSourceFilterState';
        clear_array[36] = 'USER_ID';
        clear_array[37] = 'EMAIL_ID';
        clear_array[38] = 'REFRESH_TOKEN';
        clear_array[39] = 'switch_app_id';
        clear_array[40] = 'INDEX_UP';
        clear_array[41] = 'INNER_INDEX_UP';
        clear_array[42] = 'APPINSIGHTS_VISIBILITY';
        clear_array[43] = 'GUIDEINSIGHTS_VISIBILITY';
        clear_array[44] = 'QAAUTOMATOR_VISIBILITY';
        clear_array[45] = 'PI-TRACKING';
        clear_array[46] = 'SUPER_ADMIN';
        clear_array[47] = 'min_date_dashboard';
        clear_array[48] = 'switch_app_code_dashboard';
        clear_array[49] = 'ORG_FITLER_ID';
        clear_array[50] = 'max_date_dashboard';
        clear_array[51] = 'firstApp_name_dashboard';
        clear_array[52] = 'ORG_PRIORITY_PROCESSING';
        clear_array[53] = 'ORG_IDS';
        clear_array[54] = 'app_archived';
        clear_array[55] = 'ORG_SWITCH_ID';
        clear_array[56] = 'WEEKEND_TEXT';
        clear_array[57] = 'ORG_ID';
        clear_array[58] = 'IS_AUTHENTICATED';
        clear_array[59] = 'app_img';
        clear_array[60] = 'REAL_TIME_CHECK';
        clear_array[61] = 'exportRequestSourceState';
        clear_array[62] = 'segmentsFilterState';
        clear_array[63] = 'ENV_FILTER_STATE';
        clear_array[64] = 'WEB_APP_LANGUAGE';
        clear_array[65] = 'DASHBOARD_APP_LIST';
        clear_array[66] = 'ORG_HELP_URL';
        clear_array[67] = 'BUSINESS_TOOLS_VISIBILITY';

        if (storeArray.length === 0) {
            for (let i = 0; i < clear_array.length; i++) {
                localStorage.removeItem(clear_array[i]);
            }
        }

        for (let j = 0; j < storeArray.length; j++) {
            localStorage.removeItem(storeArray[j]);
        }

    },

    clearCookie: function () {
        // var res = document.cookie;
        // var multiple = res.split(";");
        // for (var i = 0; i < multiple.length; i++) {
        //     var key = multiple[i].split("=");
        //     document.cookie = key[0] + " =; expires = " + new Date().toUTCString();
        // }
    },

    readCookie: function (name: string) {
        // var nameEQ = name + "=";
        // var ca = document.cookie.split(';');
        // for (var i = 0; i < ca.length; i++) {
        //     var c = ca[i];
        //     while (c.charAt(0) === ' ') {
        //         c = c.substring(1, c.length);
        //     }
        //     if (c.indexOf(nameEQ) === 0) {
        //         return (c.substring(nameEQ.length, c.length));
        //     }
        // }
        // return null;
    },


    transform: function (value: any, short?: boolean) {
        if (isNaN(value)) {
            return " 0 ";
        }
        else {
            let str = '';
            let hr, min, sec;
            hr = Math.floor(value / 3600);
            min = Math.floor(value % 3600 / 60);
            sec = Math.floor(value % 3600 % 60);

            if (hr > 0) {
                str += hr + (hr === 1 ? " hr " : " hrs ");
                if (min > 0) {
                    str += min + (min === 1 ? " min " : " mins ");
                }
                str = checkshort(str);
                return str;
            }
            else if (hr === 0 && min > 0) {
                str += min + (min === 1 ? " min " : " mins ");
                if (sec > 0) {
                    str += sec + (sec === 1 ? " sec " : " secs ");
                }
                str = checkshort(str);
                return str;
            }
            else {
                str += sec + (sec <= 1 ? " sec " : " secs ");
                str = checkshort(str);
                return str;
            }


        }

        function checkshort(str: string) {
            if (short) {
                str = str.replace(" mins ", "m ");
                str = str.replace(" secs ", "s");
                str = str.replace(" hrs ", "h ");
                str = str.replace(" min ", "m ");
                str = str.replace(" sec ", "s");
                str = str.replace(" hr ", "h ");
            }
            return str;
        }

    },

    dateSeparator: function (data: any) {
        if (data?.length !== 0 && data[0]?.startTimestamp.includes('|')) {

            const modifiedData: any = [
            ];
            const data1: any = [
            ];
            //   const modifiedData = [], data1 = [];
            let flag = 0;
            for (let i = 0; i < data.length; i++) {
                data1[i] = [];
                data1[i].counts = data[i].counts;
                data1[i].date = data[i].startTimestamp.split('|')[0];
                data1[i].startTimestamp = data[i].startTimestamp.split('|')[1];
            }

            for (let i = 0; i < data1.length; i++) {
                for (let j = 0; j < modifiedData.length; j++) {
                    if (modifiedData[j].startTimestamp === data1[i].startTimestamp) {
                        flag = 1;
                        break;
                    } else {
                        flag = 0;
                    }
                }
                if (flag === 1) {
                    modifiedData[i] = data1[i];
                    modifiedData[i].startTimestamp = data1[i].startTimestamp + ' ';
                } else {
                    modifiedData[i] = data1[i];
                }
            }
            data = modifiedData;
        }
        return data;
    },

    dateFormat: function (date: string, type: string) {
        let date_string: any = [];
        date_string = date.split('|');
        if (type === 'time') {
            return date_string[1];

        } else {
            return date_string[0];

        }
    },

    dateTimeFormat: function (date: string, type?: string) {
        let date_arr: any = [];
        let time_string: any = [];
        date_arr = date.split(' ')[0].split('-')
        let month: string;
        switch (date_arr[1]) {
            case '01': {
                month = insightsLbls.jan;
                break;
            }
            case '02': {
                month = insightsLbls.feb;
                break;
            }
            case '03': {
                month = insightsLbls.mar;
                break;
            }
            case '04': {
                month = insightsLbls.apr;
                break;
            }
            case '05': {
                month = insightsLbls.may;
                break;
            }
            case '06': {
                month = insightsLbls.jun;
                break;
            }
            case '07': {
                month = insightsLbls.jul;
                break;
            }
            case '08': {
                month = insightsLbls.aug;
                break;
            }
            case '09': {
                month = insightsLbls.sep;
                break;
            }
            case '10': {
                month = insightsLbls.oct;
                break;
            }
            case '11': {
                month = insightsLbls.nov;
                break;
            }
            case '12': {
                month = insightsLbls.dec;
                break;
            }

        }
        if (type === 'date&time') {
            time_string = date.split(' ')[1].split(':')
            if (+time_string[0] < 12) return `${date_arr[2]}th ${month}, ${date_arr[0]} At ${time_string[0]}:${time_string[1]} AM`;
            else if (+time_string[0] > 12) return `${date_arr[2]}th ${month}, ${date_arr[0]} At ${+time_string[0] - 12}:${time_string[1]} PM`;
            else return `${date_arr[2]}th ${month}, ${date_arr[0]} At ${+time_string[0]}:${time_string[1]} PM`
        } else {
            return `${date_arr[2]}th ${month}, ${date_arr[0]}`
        }
    },

    addRemove_class: function (type: string, toclass: string, class_name: string) {
        if (type === 'add') {
            for (let i = 0; i < document.querySelectorAll("." + toclass).length; i++) {
                const el1 = (document.getElementsByClassName(toclass)[i]) as HTMLInputElement;
                if (el1 !== undefined) {
                    el1.classList.add(class_name);
                }
            }
        } else {
            for (let i = 0; i < document.querySelectorAll("." + toclass).length; i++) {
                const el1 = (document.getElementsByClassName(toclass)[i]) as HTMLInputElement;
                if (el1 !== undefined) {
                    el1.classList.remove(class_name);
                }
            }
        }
    },

    // storeCookie: function(data: any) {
    //     let splitArr = [];
    //     let APP_CODE, APP_ID;
    //     splitArr = data;
    //     localStorage.setItem('switch_app_code', String(this.readCookie('switch_app_code')));
    //     localStorage.setItem('switch_app_id', String(this.readCookie('switch_app_id')));
    //     localStorage.setItem('firstApp_name', String(this.readCookie('firstApp_name')));

    //     APP_CODE = localStorage.setItem('switch_app_code', String(this.readCookie('switch_app_code')));
    //     APP_ID = localStorage.setItem('switch_app_id', String(this.readCookie('switch_app_id')));

    //     localStorage.setItem('INDEX_UP', String(this.readCookie('INDEX_UP')));
    //     localStorage.setItem('INNER_INDEX_UP', String(this.readCookie('INNER_INDEX_UP')));

    //     if (this.readCookie('dateSelection') === '15') {
    //         localStorage.setItem('startDate', String(this.readCookie('startDate')));
    //         localStorage.setItem('endDate', String(this.readCookie('endDate')));
    //     }
    //     localStorage.setItem('MODEL2', String(this.readCookie('MODEL2')));
    //     localStorage.setItem('SELECTED_OPTN', String(this.readCookie('dateSelection')));
    //     localStorage.setItem('EVENT_SOURCE', String(this.readCookie('EVENT_SOURCE')));
    //     localStorage.setItem('guideState', String(this.readCookie('guideState')));
    // },

    storeAuthData: function (data: any) {
        localStorage.setItem('APP_KEY', String(process.env.REACT_APP_APP_KEY));
        localStorage.setItem('ACCESS_TOKEN', data.accesstoken);
        localStorage.setItem('REFRESH_TOKEN', data.refreshtoken);
        localStorage.setItem('USER_FNAME', data.first_name);
        localStorage.setItem('USER_LNAME', data.last_name);
        localStorage.setItem('USER_PROFILE', data.image_url);
        localStorage.setItem('USER_SIGN', data.cdn_signature);
        localStorage.setItem('USER_ROLE', data.role);
        localStorage.setItem('ORG_PRIORITY_PROCESSING', data.org_insight_processing_priority);
        localStorage.setItem('USER_ID', data.user_id);
        localStorage.setItem('ORG_NAME', data?.organization?.name);
        localStorage.setItem('ORG_IMG_URL', data?.organization?.image_url);
        localStorage.setItem('ORG_ID', data.organization?.organization_id);
        if (["mg_admin", "owner", "app_admin"].includes(data.role)) {
            let orgSetting = JSON.parse(data.organization.settings);
            localStorage.setItem('ORG_HELP_URL', orgSetting != undefined && orgSetting?.helpUrl != undefined ? orgSetting?.helpUrl : '');
        }
        // localStorage.setItem('datedashflag', 'true');
        localStorage.setItem('guideState', localStorage.getItem('guideState') ?? 'All');
        localStorage.setItem("exportRequestSourceState", 'Guides');
        localStorage.setItem("segmentsFilterState", JSON.stringify({ segmentsFilter: 0, segmentTitle: "Segment Filter" }));
        localStorage.setItem('eventSourceFilterState', JSON.stringify({ eventSourceGuides: "player", eventSourceTutorialGuides: "player", eventSourceTooltips: "player", eventSourceSearches: "player", eventSourceUserEngagement: "player", eventSourceDashboard: "player", eventSourceGuideAutomation: "player", eventSourceGoals: "player", eventSourceExportEmailGuide: "player", eventSourceGoalsGuides: "player", eventSourceGoalsTooltips: "player" }));   // eventSourceExportEmailTooltip:"player",eventSourceExportEmailSearches:"player"
        localStorage.setItem('PI-TRACKING', JSON.parse(data.organization.admin_settings).isPITrackingEnabled);
        // localStorage.setItem('REALTIME_API_FLAG', data.app_insights_enabled);

        localStorage.setItem('IS_AUTHENTICATED', 'true');
        localStorage.setItem('REAL_TIME_CHECK', 'false');
        localStorage.setItem('REALTIME_INFO_VISIBILITY', 'true');


        const adminSettings = JSON.parse(data.organization.admin_settings);
        if (typeof adminSettings.insights !== 'object') {
            adminSettings.insights = JSON.parse(adminSettings.insights)
        }


        if ((adminSettings !== null || adminSettings !== undefined) && 'isPITrackingEnabled' in adminSettings) {
            if (adminSettings.isPITrackingEnabled === 1 || adminSettings.isPITrackingEnabled === '1') {
                localStorage.setItem('USER_ACTIVITY', 'true');
            } else {
                localStorage.setItem('USER_ACTIVITY', 'false');
            }
        } else {
            localStorage.setItem('USER_ACTIVITY', 'false');
        }

        if (adminSettings.myBotEnabled === 1 || adminSettings.myBotEnabled === '1' || adminSettings.myBotEnabled === true) {
            localStorage.setItem('MYBOT_VISIBILITY', 'true');
        } else {
            localStorage.setItem('MYBOT_VISIBILITY', 'false');
        }

        if ('guide' in adminSettings.insights && adminSettings.insights['guide'] == 1) {
            localStorage.setItem('GUIDEINSIGHTS_VISIBILITY', 'true');
        } else {
            localStorage.setItem('GUIDEINSIGHTS_VISIBILITY', 'false');
        }

        if ('business_intelligence_tools_integration' in adminSettings.insights &&
            JSON.parse(adminSettings.insights.business_intelligence_tools_integration) == 1) {
            localStorage.setItem('BUSINESS_TOOLS_VISIBILITY', 'true');
        } else {
            localStorage.setItem('BUSINESS_TOOLS_VISIBILITY', 'false');
        }

        if (Object.keys(adminSettings.insights.app).length) {
            if ('app' in adminSettings.insights && adminSettings.insights.app[Object.keys(adminSettings.insights.app)[0]]['enable_page_tracking'] == 1) {
                localStorage.setItem('APPINSIGHTS_VISIBILITY', 'true');
            } else {
                localStorage.setItem('APPINSIGHTS_VISIBILITY', 'false');
            }
        } else {
            localStorage.setItem('APPINSIGHTS_VISIBILITY', 'false');
        }


        if (adminSettings.workflowAutomationEnabled === 1 || adminSettings.workflowAutomationEnabled === '1' || adminSettings.workflowAutomationEnabled === true) {
            localStorage.setItem('QAAUTOMATOR_VISIBILITY', 'true');
        } else {
            localStorage.setItem('QAAUTOMATOR_VISIBILITY', 'false');
        }

        if (data['role'] === 'superadmin') {
            localStorage.setItem('SUPER_ADMIN', 'true');
        } else {
            localStorage.setItem('SUPER_ADMIN', 'false');
        }


        if ('real_time_stats' in adminSettings.insights && adminSettings.insights['real_time_stats'] == 1) {
            localStorage.setItem('ENABLE_REAL_TIME', 'true');
        } else {
            localStorage.setItem('ENABLE_REAL_TIME', 'false');
        }

        const sectionVisibility_data = {
            superAdmin: localStorage.getItem('SUPER_ADMIN') === 'true',
            isPITrackingEnabled: localStorage.getItem('USER_ACTIVITY') === 'true',
            myBot_flag: localStorage.getItem('MYBOT_VISIBILITY') === 'true',
            guideInsights_flag: localStorage.getItem('GUIDEINSIGHTS_VISIBILITY') === 'true',
            appInsights_flag: localStorage.getItem('APPINSIGHTS_VISIBILITY') === 'true',
            qaAutomator_flag: localStorage.getItem('QAAUTOMATOR_VISIBILITY') === 'true',
            businessTools_flag: localStorage.getItem('BUSINESS_TOOLS_VISIBILITY') === 'true'
        }
        return sectionVisibility_data;

    },

    routeOnLogin: function () {
        if (localStorage.getItem('USER_ROLE') !== 'creator') {
            if (localStorage.getItem('GUIDEINSIGHTS_VISIBILITY') === 'false' && localStorage.getItem('APPINSIGHTS_VISIBILITY') === 'false') {
                this.checkAutomationInsightsVisibility();
            } else {
                this.getDashboardData();
            }
        } else {
            localStorage.setItem('GUIDEINSIGHTS_VISIBILITY', 'false');
            localStorage.setItem('APPINSIGHTS_VISIBILITY', 'false');
            // this.checkAutomationInsightsVisibility();
        }
    },

    checkAutomationInsightsVisibility: function () {
        // let urlPath = process.env.REACT_APP_INSIGHTS_DOMAIN;
        // if (localStorage.getItem('MYBOT_VISIBILITY') === 'true') {
        //     window.open(urlPath + "#/automation-insights/guide-automation?app_code=" + app_code + "&app_id=" + app_id, "_self")
        // } else if (localStorage.getItem('QAAUTOMATOR_VISIBILITY') === 'true') {
        //     window.open(urlPath + "#/automation-insights/qa-automator?app_code=" + app_code + "&app_id=" + app_id, "_self")
        // }
    },

    getDashboardData: function () { },

    getLanguageChangeData: function () {
        const selectedLang = localStorage.getItem('WEB_APP_LANGUAGE');
        let insightLabels: any = en_US;
        if (selectedLang === null || selectedLang === '' || selectedLang === undefined) {
            switch (process.env.REACT_APP_LANGUAGE) {
                case 'en_US':
                    insightLabels = en_US;
                    break;

                case 'fr_CA':
                    insightLabels = fr_CA;
                    break;

                case 'fr_FR':
                    insightLabels = fr_FR;
                    break;

                case 'ja_JP':
                    insightLabels = ja_JP;
                    break;

                case 'ko_KR':
                    insightLabels = ko_KR;
                    break;

                case 'pseudo':
                    insightLabels = pseudo;
                    break;

                default:
                    insightLabels = en_US;
                    break;
            }
        } else {
            switch (selectedLang) {
                case 'en_US':
                    insightLabels = en_US;
                    break;

                case 'fr_CA':
                    insightLabels = fr_CA;
                    break;

                case 'fr_FR':
                    insightLabels = fr_FR;
                    break;

                case 'ja_JP':
                    insightLabels = ja_JP;
                    break;

                case 'ko_KR':
                    insightLabels = ko_KR;
                    break;

                case 'pseudo':
                    insightLabels = pseudo;
                    break;

                default:
                    insightLabels = en_US;
                    break;
            }
        }

        let GmXt: any;
        if (GmXt === undefined) GmXt = {};

        GmXt.engLbls = {};

        GmXt.engLbls = {

            guideSurvey: "Guide Survey",
            exitGuideSurvey: "Exit Guide Survey",
            guideStepSurvey: "Guide Step Survey",

            docTitleGuides: "Guide Insights - Guides",
            docTitleTutorialGuides: "Guide Insights - Tutorial Guides",
            docTitleTooltips: "Guide Insights - Tooltips",
            docTitleGuideAutomation: "Automation Insights - Guide Automation",
            genericSurvey: "Generic Survey",
            guideWorkflow: " Guide Workflow",
            dynamicWorkflow: " Dynamic Workflow",
            workflows: "Workflows",
            manageGoals: "Manage Goal",
            manageSurvey: "Manage Survey",
            guideMe: "GuideMe",
            showMe: "ShowMe",
            testMe: "TestMe",
            dashboard: "Dashboard",
            workflow: "Workflow",
            pages: "Pages",
            doItForMe: "DoItForMe",
            url: "URL",
            guideMeDashboard: "Guide Me",
            showMeDashboard: "Show Me",
            testMeDashboard: "Test Me",
            workFlow: "Workflow",
            guidance: "Guidance",
            giphy: "GIF",
            downloadPptx: "PPT",
            pdf: "PDF",
            page: "Page",
            creator: "Creator",
            superAdmin: "SuperAdmin",
            mgAdmin: "MG Admin",
            appAdmin: "App Admin",
            partnerAdmin: "Partner Admin",
            chatBot: "ChatBot",
            slideshow: "Slideshow",
            brandName: insightLabels.brandName,
            version: insightLabels.buildversion + " 2024.6.0",
            supportLinkText: insightLabels.supportLinkText,
            supportLink: insightLabels.mailto + ":support@csod.com",
            displaySupportLink: "support@csod.com",
            noReplyEmail: "reset-noreply@myguide.com",
            mailTo: "support@csod.com",
            jan: "Jan",
            feb: "Feb",
            mar: "Mar",
            apr: "Apr",
            may: "May",
            jun: "Jun",
            jul: "Jul",
            aug: "Aug",
            sep: "Sep",
            oct: "Oct",
            nov: "Nov",
            dec: "Dec",
            gif: "GIF",
            PNBandTooltips: "Push-notifications, Beacons & Tooltips",
            buttonText: "Login",
            feature: 'Feature',

            // not translated below all
            dashboard24HrMsg: "Data on the dashboard is updated typically once in every 24 hours.",
            guideNameSubheadingLabel: "Name of Guide",
            guidesPlayedSubheadingLabel: "No. of guides played",
            guidesPlayedInGuideMeModeSubheadingLabel: "No. of guides played in GuideMe Mode",
            guidesPlayedInDoItForMeModeSubheadingLabel: "No. of guides played in Do It For Me Mode",
            showMePlayedSubheadingLabel: "No. of ShowMe played",
            testMePlayedSubheadingLabel: "No. of TestMe played",
            surveyResponsesSubheadingLabel: "No. of Survey Responses",
            stepsWithErrorsSubheadingLabel: "No. steps in guide experiencing errors",
            timesErrorEncounteredSubheadingLabel: "No. of times error was encountered",
            lastErrorTimestampSubheadingLabel: "Time stamp of the last recorded error",
            stepNameSubheadingLabel: "Name of Step",
            stepLatestTimestampSubheadingLabel: "Latest Timestamp",
            stepErrorCountSubheadingLabel: "No. of times error was encountered",
            stepPlayCountSubheadingLabel: "No. of time step was played",
            humanInterventionCountSubheadingLabel: "No. of times human intervention was performed",
            stepSkippedCountSubheadingLabel: "No. of times step was skipped",
            stepPlayErrorCountSubheadingLabel: "No. of time play errored on this step",
            tutorialGuideNameSubheadingLabel: "Name of Tutorial Guide",
            tutorialGuidesPlayedSubheadingLabel: "No. of tutorial guides played",
            tutorialGuidesCompletedSubheadingLabel: "No. of tutorial guides completed",
            stepPlayStartedCountSubheadingLabel: "No. of times play started from this step",
            stepPlayEndedCountSubheadingLabel: "No. of times play ended on this step",
            powerFormNameSubheadingLabel: "Name of Power Form",
            powerFormCancelledCountSubheadingLabel: "No. of times Power Form Cancelled",
            powerFormSubmittedCountSubheadingLabel: "No. of times Power Form Submitted",
            taskListNameSubheadingLabel: "Name of task list",
            taskListEngagedCountSubheadingLabel: "No. of task list engaged",
            taskListCompletedCountSubheadingLabel: "No. of task list completed",
            taskListLastUpdatedSubheadingLabel: "Task List Last updated on",
            userNameSubheadingLabel: "Name of user",
            userEmailAddressSubheadingLabel: "Email address of user",
            userPlayedTaskListGuideSubheadingLabel: "User played atleast one task list guide",
            userCompletedAllGuidesInTaskListSubheadingLabel: "User completed all guides in the task list",
            guideMePlayCountSubheadingLabel: "GuideMe Play Count",
            showMePlayCountSubheadingLabel: "ShowMe Play Count",
            testMePlayCountSubheadingLabel: "TestMe Play Count",
            doItForMePlayCountSubheadingLabel: "DoItForMe Play Count",
            tutorialPlayCountSubheadingLabel: "Tutorial Play Count",
            uniqueKeywordSearchedSubheadingLabel: "Unique Keyword Searched",
            keywordSearchCountSubheadingLabel: "No. of Searches",
            averageSearchResultsSubheadingLabel: "Average No. of results shown",
            searchActionCountSubheadingLabel: "No. of time search resulted into action",
            pageUrlSubheadingLabel: "Complete URL of the page",
            pageVisitCountSubheadingLabel: "No. of URL visits",
            uniqueUserVisitsSubheadingLabel: "No. of unique users visits",
            urlTagCountSubheadingLabel: "No. of tags associated with this URL",
            pageTagNameSubheadingLabel: "Name of page tag",
            pageViewCountSubheadingLabel: "No. of page views",
            uniquePageVisitorsSubheadingLabel: "No. of unique visitors",
            userWhoTaggedSubheadingLabel: "Name of user who tagged the tag",
            pageLastUpdatedSubheadingLabel: "Last updated on",
            surveyNameSubheadingLabel: "Name of Survey",
            surveyResponseCountSubheadingLabel: "No. of responses",
            userSurveyAnalysisSubheadingLabel: "User Survey analysis",
            surveyLastUpdatedSubheadingLabel: "Last update time",
            conversationNameSubheadingLabel: "Name of Conversation",
            conversationResponseCountSubheadingLabel: "No. of responses",
            conversationLastUpdatedSubheadingLabel: "Last update time",
            goalNameSubheadingLabel: "Name of Goal",
            goalStatusSubheadingLabel: "Goal Status",
            goalLiveDateSubheadingLabel: "Goal Live date",
            goalCompletionDateSubheadingLabel: "Goal Completion date",
            goalCompletionPercentageSubheadingLabel: "Goal Completion Percentage",
            availableActionsSubheadingLabel: "Actions available",
            name: 'Name',
            goalStatus: 'Goal Status',
            goalActions: 'Goal Actions',
            goalTitleText: 'The title of the goal being tracked',
            goalStatusText: 'The current status of the goal',
            goalLiveOnText: 'The date when the goal gets live',
            targetCompletionText: 'The expected or planned completion date for the goal',
            currentStatusText: 'The progress percentage or current completion level of the goal.',
            goalActionsText: 'Available actions or options for managing the goal',
        };

        GmXt.getPlayerLabels = function (lang: any) {
            var lab = insightLabels;
            if (lang) {
                var l = lang.substr(0, 2);
                if (GmXt.playerLbls[l]) {
                    lab = GmXt.playerLbls[l];
                }
            }

            lab = GmXt.mergeLabels(lab, GmXt.engLbls);
            return lab;
        };

        GmXt.mergeLabels = function (labels: any, lbs: any) {
            for (var attr in lbs) {
                labels[attr] = lbs[attr];
            }
            return labels;
        };

        let insightLables = {};
        GmXt.getAllLabels = function (lang: any) {
            GmXt.label = GmXt.getPlayerLabels(lang);
            const data = GmXt.mergeLabels(GmXt.engLbls, GmXt.getPlayerLabels(lang));
            return data;
        };

        insightLables = GmXt.getAllLabels();
        return insightLables;
    },

    handle503Redirect: function (redirectPath: string) {
        // window.open(process.env.REACT_APP_ADMIN_URL! + "/maintenance_mode?redirectfrommaintenance=" + btoa(redirectPath), "_self");
    }
};
