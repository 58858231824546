if (GmXt === undefined) var GmXt = {}; 
GmXt.playerLbls = {}; 
GmXt.playerLbls.fr_FR = {
    "yAxisLabelCount": "Nombre",
    "playedCount": "Nombre de consultations effectuées",
    "completedCount": "Nombre de consultations terminées",
    "expandViews": "Agrandir les vues",
    "collapseViews": "Réduire les vues",
    "expand": "Agrandir",
    "collapse": "Réduire",
    "totalSteps": "Nombre total d’étapes",
    "slideshowStats": "Statistiques du diaporama",
    "videoStats": "Statistiques de la vidéo",
    "view": "Afficher",
    "deleted": "Supprimé",
    "published": "Publié",
    "unpublished": "Non publié",
    "unknown": "Inconnu",
    "allUsers": "Tous les utilisateurs",
    "download": "Télécharger",
    "viewOnline": "Afficher en ligne",
    "or": "Ou",
    "cioDashboardSubTitle": "Activité dans toutes les applications",
    "cioDashboardModalTitle": "Exporter les données",
    "play": "Consulter",
    "totalPlayed": "Nombre total de consultations",
    "uniquePlayed": "Consultation unique",
    "completed": "Terminé",
    "pass": "Réussite",
    "fail": "Échec",
    "panel": "Panneau",
    "withAudio": "Avec audio",
    "withouAudio": "Sans audio",
    "linkedTable": "Tableau associé par un lien",
    "showGuideWithActivities": "Afficher les Guides avec les activités",
    "submitted": "Envoyé",
    "cancelled": "Annulé",
    "hideDeleted": "Masquer ce qui est supprimé",
    "totalPlayCount": "Nombre total de consultations ShowMe",
    "uniquePlayCount": "Nombre de consultations ShowMe uniques",
    "testMeTotalPlayCount": "Nombre total de consultations TestMe",
    "testMeUniquePlayCount": "Nombre de consultations TestMe uniques",
    "passCount": "Nombre total de réussites Test Me",
    "failCount": "Nombre total d’échecs Test Me",
    "searchCount": "Nombre de fois où la recherche a été effectuée.",
    "actionCount": "Nombre de fois qu’une action a été effectuée lorsque les résultats de la recherche ont été affichés.",
    "scheduledPlay": "Consultation programmée",
    "manualPlay": "Consultation manuelle",
    "playedBy": "Consulté par",
    "runType": "Type d’exécution",
    "result": "Résultat",
    "status": "État",
    "srNo": "N° de série",
    "guideTitle": "Titre du Guide",
    "description": "Description",
    "moreInfo": "En savoir plus",
    "jobPrioritisedSuccessMsg": "Emploi priorisé avec succès ! !",
    "apiCacheDeletedMsg": "Cache de l’API supprimé avec succès.",
    "guideCreated": "Guides créés",
    "guideModified": "Guides modifiés",
    "exportJobs": "Exporter les emplois",
    "searchOrganization": "Rechercher une organisation",
    "showing": "Affichage",
    "exportJobsList": "Exporter la liste des emplois",
    "organization": "Organisation",
    "requestTime": "Heure de la demande",
    "exportJobStatus": "Exporter l’état de l’emploi",
    "pending": "En attente",
    "priortizeJob": "Prioriser l’emploi",
    "priortized": "Priorisé.",
    "inProcess": "En cours",
    "complete": "Terminé",
    "pendingEventsCount": "Nombre d’événements en attente",
    "deleteCache": "Supprimer le cache",
    "priortizeEventsForOrg": "Prioriser les événements pour cette organisation.",
    "tokenCancelledMsg": "Opération annulée en raison d’une nouvelle demande.",
    "selectAll": "Sélectionner tout",
    "application": "Application",
    "appList": "Liste des applications",
    "close": "Fermer",
    "searchApp": "Rechercher une application",
    "totalApps": "Nombre total d’applications",
    "active": "actif",
    "deletedApp": "Application supprimée",
    "operatingSystem": "Système d’exploitation",
    "screenResolution": "Résolution d’écran",
    "webBrowser": "Navigateur Web",
    "name": "Nom",
    "version": "Version",
    "count": "Nombre",
    "visitors": "Visiteurs",
    "includeWeekend": "Inclure les week-ends",
    "last7Days": "7 derniers jours",
    "last30Days": "30 derniers jours",
    "last60Days": "60 derniers jours",
    "last90Days": "90 derniers jours",
    "last180Days": "180 derniers jours",
    "last365Days": "365 derniers jours",
    "lastCalendarYear": "Dernière année civile",
    "customDate": "Date personnalisée",
    "weekendsIncluded": "Week-ends inclus",
    "weekendsExcluded": "Week-ends exclus",
    "withRealTimeData": "Avec des données en temps réel",
    "realTimeDataNotAvailable": "Aucune donnée en temps réel n’est disponible",
    "includeRealTimeData": "Inclure les données en temps réel",
    "excludeWeekends": "Exclure les week-ends",
    "from": "Début",
    "to": "Fin",
    "invalidStartDate": "Date de début non valide",
    "invalidEndDate": "Date de fin non valide",
    "selectDateRangeWithin12mmonths": "Sélectionnez une plage de dates dans les 12 mois",
    "successfullyAdded": "Ajout réussi !",
    "dashboardName": "Nom du Dashboard",
    "duplicateDashboardName": "Nom de tableau de bord en double",
    "canAddUpto3Dashboards": "Vous pouvez ajouter jusqu’à 3 tableaux de bord.",
    "dashboardName20Chars": "Le nom du Dashboard ne doit pas comporter plus de 20 caractères",
    "pleaseEnterValidName": "Veuillez saisir un nom valide",
    "successfullyDeleted": "Supprimé avec succès !",
    "successfullyUpdated": "Mis à jour avec succès !",
    "chartsAlreadyAdded": "Graphique déjà ajouté dans",
    "createNewDashboardMsg": "Vous n’avez pas de tableaux de bord personnalisés, veuillez créer un nouveau Dashboard.",
    "createNewDashboard": "Créer un nouveau Dashboard",
    "allDashboardDataDeleted": "Toutes les données du Dashboard seront supprimées.",
    "wantToDeleteDashboard": "Voulez-vous supprimer ce tableau de bord ?",
    "save": "Enregistrer",
    "requestSent": "Demande envoyée !",
    "requestCompleteTime": "Heure de finalisation de la demande",
    "jobStatus": "État de l’emploi",
    "type": "Type",
    "surveyType": "Type de Survey",
    "surveyName": "Nom du Survey",
    "stepName": "Nom de l’étape",
    "selectFilter": "Sélectionner le filtre",
    "publicURL": "URL publique",
    "filter": "Filtre",
    "filter1Placeholder": "Filtre 1 (nom du Guide, nom de l’étape, nom de l’Tooltip)",
    "filter2Placeholder": "Filtre 2 (nom de l’étape)",
    "tooltipList": "Liste des Tooltips",
    "clearAll": "Effacer tout",
    "tooltipPlay": "Consultation de l’Tooltip",
    "played": "Consultée",
    "lastPlayedOn": "Consultée pour la dernière fois le",
    "activityDetails": "Détails d’activité",
    "exportExcel": "Exporter au format Excel",
    "downloading": "Téléchargement en cours...",
    "exportPDF": "Exporter au format PDF",
    "editFunnel": "Modifier l’entonnoir",
    "clickHereToCreateFunnel": "Cliquer ici pour créer un entonnoir",
    "step": "Étape",
    "funnelStepsInOrder": "Étapes de l’entonnoir dans l’ordre",
    "funnelStepsMsg": "Veuillez sélectionner 3 étapes au minimum et 7 étapes au maximum pour afficher l’entonnoir.",
    "funnelStepsStatsMsg": "* Les statistiques des guides liés peuvent être consultées sur leurs pages de détails respectives.",
    "max7Steps": "7 étapes au maximum peuvent être sélectionnées.",
    "clearList": "Effacer la liste",
    "submit": "Envoyer",
    "playedVsCompleted": "Consulté contre Terminé",
    "deleteChartMsg": "Voulez-vous supprimer ce graphique ?",
    "youAreHere": "Vous êtes ici",
    "successfullyRenamed": "Renommé avec succès !",
    "dashboardName20CharsErrorMsg": "*Le nom du Dashboard ne peut excéder 20 caractères",
    "dashboardNameEmptyErrorMsg": "*Le nom du Dashboard ne peut être vide",
    "maintenanceInProgress": "Maintenance en cours.",
    "pleaseContactUs": "Veuillez nous contacter via",
    "backToList": "Retour à la liste",
    "title": "Titre",
    "filterTitle": "Titre du filtre",
    "max100chars": "100 caractères maximum autorisés",
    "filterDescription": "Description du filtre",
    "selectOrganizations": "Sélectionner des organisations",
    "deleteFilter": "Supprimer le filtre",
    "deleteFilterMsg": "Voulez-vous supprimer ce filtre ?",
    "switchOrganization": "Changer d’organisation",
    "ofPages": "DE PAGES",
    "ofPagesViews": "DE VUES PAR PAGE",
    "untaggedURLS": "URL SANS TAG",
    "taggedURLS": "URL AVEC TAG",
    "singleSelect": "Sélection unique",
    "multiSelect": "Multi-sélection",
    "text": "Texte",
    "range": "Plage",
    "rating": "Notation",
    "yesNo": "Oui-Non",
    "URLHostname": "Nom d’hôte de l’URL",
    "URLPath": "Chemin de l’URL",
    "URLParameters": "Paramètres de l’URL",
    "URLHash": "Hash de l’URL",
    "equals": "Égal",
    "notEquals": "Non égal",
    "contains": "Contient",
    "notContains": "Ne contient pas",
    "startsWith": "Commence par",
    "similarTo": "Similaire à",
    "endsWith": "Se termine par",
    "commonMsg": "Erreur : chemin invalide, seuls des caractères alphanumériques et des caractères spéciaux (~, =, <, >, /, * uniquement) sont autorisés, les espaces ne sont pas autorisés dans la chaîne du chemin",
    "slashMsg": "Erreur : le chemin commencera toujours par une barre oblique",
    "angularBracketMsg": "Erreur : utilisez des crochets obliques ouverts et fermés (<>) contenant une chaîne",
    "tilEqAngularMsg": "Erreur : il est impossible d’avoir <> et ~= ensemble après une barre oblique",
    "tilEqTogetMsg": "Erreur : il est impossible d’avoir ~ ou = seul dans le chemin, ~ sera toujours suivi de =",
    "starAloneMsg": "Erreur : il est impossible d’avoir quoi que ce soit dans les barres obliques s’il y a une étoile (*) déjà présente, une seule étoile (*) sera toujours placée entre deux barres obliques",
    "twoStarMsg": "Erreur : il est impossible d’avoir quoi que ce soit après ** (double étoile) dans la chaîne de chemin",
    "noDoubleSlashMsg": "Erreur : les doubles barres obliques ne sont pas autorisées dans la chaîne de chemin",
    "onlyHyphenUnderMsg": "Erreur : seuls _ et - sont autorisés entre crochets obliques (<>)",
    "noHyphenUnderMsg": "Erreur : _ et - ne sont autorisés qu’entre crochets obliques (<>)",
    "filedRequired": "Erreur : ce champ est obligatoire",
    "hashMsg": "Erreur : hash d’URL non valide",
    "paramaterAlphAndSpecialChar": "Erreur : paramètre non valide, les & suivis de caractères alphanumériques et de caractères spéciaux (-, _) sont autorisés",
    "parameterStartMsg": "Erreur : paramètre non valide, il commencera toujours par un ? et ne peut pas comporter de ? suivi de = dans le paramètre",
    "parameterAllowedMsg": "Erreur : paramètre non valide, seuls les caractères alphanumériques et les caractères spéciaux (-, _, =, &) sont autorisés",
    "urlTypeSelected": "Le type est déjà sélectionné. Veuillez sélectionner n’importe quel autre type.",
    "errorHostnameAlreadyExists": "Erreur : le nom d’hôte existe déjà",
    "errorValueAlreadyExsts": "Erreur : la valeur existe déjà",
    "URLHostnameMsg": "Le nom d’hôte de l’URL est hérité du paramètre de l’application. Et est la règle par défaut pour tous les tags et les flux de travail d’une application",
    "and": "ET",
    "add": "Ajouter",
    "addGroup": "Ajouter un groupe",
    "searchStep": "Rechercher une étape",
    "searchUser": "Rechercher un utilisateur",
    "selectGuide": "Sélectionner un guide",
    "segmentFilter": "Filtre de segments",
    "selectSegments": "Sélectionner des segments",
    "summary": "Récapitulatif",
    "edit": "Modifier",
    "delete": "Supprimer",
    "bestTime": "Temps le meilleur",
    "avgTime": "Temps intermédiaire",
    "worstTime": "Temps le moins bon",
    "tagPage": "Attribuer un tag à la Page",
    "label": "Label",
    "cancel": "Annuler",
    "deletePageTag": "Supprimer le tag de la Page",
    "deleteConfirmation": "Voulez-vous vraiment supprimer le tag de cette Page ?",
    "confirm": "Confirmer",
    "create": "Créer",
    "tagged": "Avec tag",
    "untagged": "Sans tag",
    "domainMessage": "L’application n’a actuellement pas de domaine ajouté. Veuillez ajouter un domaine pour créer ou marquer la Page.",
    "workflowType": "Type de Workflow",
    "dynamicWorkflowPlayCount": "Nombre de mise(s) en œuvre de Workflow dynamique(s)",
    "guideWorkflowPlayCount": "Nombre de mise(s) en œuvre de Workflow de Guide",
    "uniqueWorkflowPlayCount": "Nombre de mise(s) en œuvre de Workflow unique(s)",
    "workflowDetails": "Détails du Workflow",
    "workflowPlayCounts": "Nombre de mises en œuvre de workflows",
    "positive": "Positif",
    "neutral": "Neutre",
    "negative": "Négatif",
    "surveyDetails": "Détails du Survey",
    "copied": "Copié !",
    "allowView": "Autoriser la vue anonyme",
    "linkExpiry": "Le lien expire",
    "change": "Modifier",
    "ok": "OK",
    "showList": "Afficher la liste",
    "surveyTitle": "Titre du Survey",
    "markGlobal": "Marquer comme global",
    "availableAllAppsInAcc": "Disponible dans toutes les applications du compte",
    "mandatoryForExtSurvey": "Obligatoire pour quitter le Survey",
    "enterDiffQuestType": "Veuillez saisir une question différente de même type",
    "linkDiffPage": "Impossible de lier la même page à la question. Veuillez lier une autre page.",
    "linkQuest": "LIER LES QUESTIONS",
    "addOption": "Ajouter une option",
    "analyzer": "Analyseur",
    "must3CharLong": "Le titre doit comporter au moins 3 caractères.",
    "threeStarRating": "Notation 3 étoiles",
    "addPage": "Ajouter la Page",
    "mandatory1Analyzer": "Cela ne peut pas être désélectionné. Une question de l’analyseur est obligatoire.",
    "changeAnalyzerQuest": "Voulez-vous vraiment modifier la question de l’analyseur ?",
    "set1QuestAsAnalyzer": "(Remarque : Une seule question peut être définie en tant que question de l’analyseur.)",
    "conditionOfMoreThan3": "Celle-ci ne peut pas être sélectionnée comme question de branchement si le nombre d’options est supérieur à 3.",
    "noteAboutoptionsReduction": "(Remarque : recommencez la sélection après avoir réduit le nombre total d’options à 3)",
    "QuestForChangingBranchQuest": "Voulez-vous vraiment modifier la question de branchement ?",
    "noteBranchQuestLimit": "(Remarque : une seule question par page peut être définie comme question de branchement.)",
    "removeBranching": "Cette action supprimera le branchement de la question sélectionnée.",
    "addBranching": "Cette action ajoutera le branchement à la question sélectionnée.",
    "blankLinkMes": "Un lien vide signifie qu’aucun lien n’est associé",
    "goalDeleteConfirmation": "Voulez-vous vraiment supprimer cet objectif ?",
    "viewDetails": "Afficher les détails",
    "targetCompletion": "Finalisation de la cible",
    "click": "Cliquez",
    "iconForNewGoal": "sur l’icône pour créer un nouvel Goal",
    "clickForEditMessage": "Cliquez sur l’objectif dans la liste pour modifier cet objectif particulier",
    "goalTitle": "Titre de l’Goal",
    "goalExistsMsg": "L’Goal existe déjà. Sélectionnez un autre titre.",
    "goalDescription": "Description de l’Goal",
    "startTimeMsg": "La date/heure de début doit être postérieure de 30 minutes par rapport à la date/heure actuelle",
    "endTimeMsg": "La date/heure de fin doit être postérieure de 24 heures à la date/heure de début",
    "goalStartDate": "Date de début de l’Goal",
    "goalEndDate": "Date de fin de l’Goal",
    "canAddupto": "Peut ajouter jusqu’à",
    "guideList": "Liste des Guides",
    "playCount": "Nombre de consultations",
    "eventSource": "Origine de l’événement",
    "playMode": "Mode de consultation",
    "msgDoitFormeNotselect": "(lorsque seuls des guides de didacticiels sont choisis, le mode de consultation \"doitforme\" ne doit pas être sélectionné)",
    "msgCannotEditGoal": "Impossible de modifier l’objectif une fois ce dernier publié. Voulez-vous vraiment publier cet objectif ?",
    "goalEmailNotification": "Les notifications par email relatives à l’Goal sont actuellement désactivées dans vos préférences. Vous risquez de passer à côté de notifications importantes concernant les jalons des objectifs.",
    "updatePreference": "Pour mettre à jour vos préférences à tout moment, vous pouvez vous rendre sur",
    "goalNotificationPreference": "Préférences de notification relatives à l’Goal",
    "goalDetails": "Détails sur l’Goal",
    "startDate": "Date de début",
    "endDate": "Date de fin",
    "eventSources": "Origines de l’événement",
    "playModes": "Modes de consultation",
    "targetPlayCount": "Nombre de consultations cibles",
    "oneEmailPublishedGuides": "Un email par jour, contenant des informations détaillées sur tous les échecs d’étape survenus au cours de la journée pour les guides publiés.",
    "oneEmailonSundays": "Un email par semaine résumant l’utilisation de MyGuide dans toute l’organisation. Les emails seront envoyés le dimanche (GMT).",
    "receiveEmailOnMilestone": "Cette option vous permet de recevoir des notifications par email chaque fois qu’un jalon est atteint (50 %, 80 % et 100 %). Considérant le fait que vous anticipez déjà l’objectif,",
    "receiveEmailOnAchievement": "cette option vous permet de recevoir des emails chaque fois qu’un objectif est atteint. Considérant le fait que vous anticipez déjà l’objectif,",
    "emailNotificationPreference": "Préférences de notification par email",
    "detail": "Détails",
    "moreDetails": "Plus de détails",
    "lessDetails": "Moins de détails",
    "requestSentOn": "Demande envoyée le",
    "requestCompletedOn": "Date de finalisation de la demande",
    "requestCompletedvia": "Demande finalisée par",
    "requestSourcedAt": "Demande provenant de",
    "requestGuideType": "Type de guide de la demande",
    "appsSelectedForRequest": "Applications sélectionnées pour la demande",
    "dataNotAvailable": "Données non disponibles.",
    "exportViaEmail": "Exporter par email",
    "selectedDateRange": "Votre plage de dates sélectionnée",
    "selectedApps": "Vos applications sélectionnées",
    "selectedDataSources": "Vos sources de données sélectionnées",
    "noteAboutExclusion": "Remarque : l’Tooltip exclut Mobile Creator et Web Creator",
    "selecetdEventSource": "Votre source d’événement sélectionnée",
    "placeRequestLimitMsg": "Vous ne pouvez déposer une demande que si vous n’avez aucune demande en attente au cours des dernières 24 heures.",
    "textCommentsFormat": "Il affiche toutes les réponses fournies par les utilisateurs au format texte (commentaires).",
    "pieChartRepresentating": "Un graphique à secteurs représentant le pourcentage et le nombre d’options ayant reçu une réponse de la part de l’ensemble des utilisateurs.",
    "tabularRepresentationOfResponse": "Une représentation tabulaire du nombre de réponses et du pourcentage de chaque option.",
    "vennDiagramCombinationOption": "Un diagramme de Venn présentant une combinaison des options ayant reçu une réponse de la part des utilisateurs.",
    "tableOfCombinationOption": "Une représentation sous forme de tableau de toutes les combinaisons d’options avec leur nombre de réponses et leur pourcentage.",
    "barforRating": "Le graphique à barres représente la notation fournie par les utilisateurs.",
    "tableForCounts": "Le tableau représente les notations en chiffres et en pourcentages.",
    "barForRatings": "Le graphique à barres représente la notation en étoiles fournie par les utilisateurs.",
    "tableForRatings": "Le tableau représente les notations en étoiles en chiffres et en pourcentages.",
    "barChartForYesNo": "Le graphique à barres représente les réponses \"Oui\" et \"Non\".",
    "tableofCountsPercentage": "Le tableau représente les chiffres et les pourcentages.",
    "timeLine": "Chronologie",
    "detailsNotAvailable": "Les détails ne sont pas disponibles pour cette instance.",
    "textType": "Type de texte",
    "selectSingleType": "Type de sélection unique",
    "multiSelectType": "Type de sélection multiple",
    "rangeType": "Type de plage",
    "starRatingType": "Type de notation en étoiles",
    "yesnoType": "Type Oui/Non",
    "responses": "Réponses",
    "excelViewerQn": "Vous n’avez pas d’afficheur Excel ?",
    "viewPlayDetails": "Afficher les détails de consultation",
    "feedback": "Feed-Back",
    "noResponseYet": "Pas encore de réponse",
    "responseStats": "Statistiques de réponse",
    "answerChoices": "CHOIX DE RÉPONSES PROPOSÉ",
    "responseCount": "NOMBRE DE RÉPONSES",
    "total": "TOTAL",
    "max5AppsSelected": "5 applications au maximum peuvent être sélectionnées.",
    "player": "consultant",
    "webCreator": "Web Creator",
    "webPlayer": "Web Player",
    "desktopCreator": "Desktop Creator",
    "mobileCreator": "Mobile Creator",
    "mobilePlayer": "Mobile Player",
    "dataTooltiptext7Days": "Les données peuvent prendre jusqu’à 7 jours pour apparaître sur le portail à partir de la survenue de l’événement réel.",
    "password1SpecialChar": "Le champ Mot de passe doit contenir au moins un caractère spécial.",
    "password1Numeric": "Le champ Mot de passe doit contenir une valeur numérique.",
    "passwordLowerCase": "Le champ Mot de passe doit contenir au moins un caractère en minuscules.",
    "passwordUpperCase": "Le champ Mot de passe doit contenir au moins un caractère en majuscules.",
    "passwordConfirmationField": "Le champ Confirmation du mot de passe doit être identique au champ Mot de passe.",
    "password8Char": "Le mot de passe de confirmation doit comporter 8 caractères !",
    "newPassword8Char": "Le nouveau mot de passe doit comporter 8 caractères !",
    "passwordChanged": "Le mot de passe a été correctement modifié !",
    "curPassword": "Mot de passe actuel",
    "newPassword": "Nouveau mot de passe",
    "confirmPassword": "Confirmer le mot de passe",
    "admin": "Admin",
    "changePassword": "Modifier le mot de passe",
    "logout": "Déconnexion",
    "somethingWentWrong": "Une erreur s’est produite",
    "showLabels": "Afficher les labels",
    "max9Allowed": "9 sélections maximum autorisées.",
    "showEntries": "Afficher les entrées",
    "activity": "Activité",
    "guideMeActivity": "Activité de GuideMe",
    "showMeActivity": "Activité de ShowMe",
    "testMeActivity": "Activité de TestMe",
    "doItForMeActivity": "Activité de DoItForMe",
    "guideTutorialActivity": "Activité des didacticiels du Guide",
    "activitiesOnGuidesonSelectApps": "Graphique linéaire représentant les activités relatives aux guides pour les applications sélectionnées",
    "guideCounts": "Nombre de Guides :",
    "guidesSelectedinTimeFrame": "Nombre total de guides dans le mode sélectionné durant l’intervalle de temps donné",
    "guidesInGuideModeinTimeFrame": "Le nombre total de guides dans le mode GuideMe durant l’intervalle de temps donné",
    "guidesInShowMeModeinTimeFrame": "Le nombre total de guides dans le mode ShowMe durant l’intervalle de temps donné.",
    "guidesInTestMeModeinTimeFrame": "Le nombre total de guides dans le mode TestMe durant l’intervalle de temps donné.",
    "guidesInSDoItForMeModeinTimeFrame": "Le nombre total de guides dans le mode DoItForMe durant l’intervalle de temps donné.",
    "tutorialGuidesinTimeFrame": "Le nombre total de Guides de Tutorial consultés durant l’intervalle de temps donné",
    "guideSummary": "Récapitulatif des Guides",
    "top10Guides": "Les dix principaux Guides",
    "tableTop10GuidesAcrossApps": "Tableau représentant les dix principaux Guides pour l’ensemble des applications.",
    "titleOfGuide": "Titre du Guide.",
    "appTitle": "Titre de l’application",
    "appToWhichGuideBelong": "App à laquelle le Guide appartient.",
    "labels": "Labels",
    "labelsToGuide": "Les labels appliqués au Guide.",
    "noOfResponsesForGuide": "Nombre total de réponses enregistrées pour le Guide.",
    "guideDistribution": "Distribution par Guide",
    "distributionAnalyzedNegNeuPos": "Distribution des réponses par Guide analysées comme étant négatives, neutres et positives.",
    "lastUpdatedOn": "Dernière mise à jour le",
    "dateTimeofGuideModification": "Date et heure auxquelles le Guide a été modifié.",
    "lineGraphonSelectedApps": "Graphique linéaire représentant les activités relatives aux workflows pour les applications sélectionnées",
    "workflowSummary": "Récapitulatif des Workflows",
    "top10Workflows": "Les dix principaux flux de travail",
    "tableTop10WorkflowsAcrossApps": "Tableau représentant les dix principaux flux de travail pour l’ensemble des applications.",
    "workflowTitle": "Titre du Workflow",
    "titleWorkflow": "Titre du flux de travail.",
    "typeWorkflow": "Type de flux de travail.",
    "walkthroughCounts": "Nombre de procédures guidées",
    "totalCountinWorkflow": "Nombre total dans un Workflow.",
    "avgTimeonWorkflow": "Temps moyen consacré à un flux de travail.",
    "bestimeAchievedWorkflow": "Meilleur temps réalisé lors d’un flux de travail.",
    "lineGraphSurveysApps": "Graphique linéaire représentant les activités relatives aux sondages pour les applications sélectionnées",
    "top10Survey": "Les dix principaux sondages",
    "tableTop10SurveysApps": "Tableau représentant les dix principaux sondages pour l’ensemble des applications.",
    "nameSurvey": "Nom du Survey.",
    "appToWhichSurveyBelong": "Application à laquelle le sondage appartient.",
    "labelsToSurvey": "Les labels appliqués au Survey.",
    "noOfResponsesForSurvey": "Nombre total de réponses enregistrées pour le Survey.",
    "surveyDistribution": "Distribution du Survey",
    "distributionAnalyzedSurevyNegNeuPos": "Distribution des réponses par sondage analysées comme étant négatives, neutres et positives.",
    "dateTimeofSurveyModification": "Date et heure auxquelles le Survey a été modifié.",
    "tableFilteredBasedOnApps": "Ce tableau est filtré en fonction des applications sélectionnées uniquement.",
    "locationInsights": "Emplacement des Insights",
    "whereUsersAccessFrom": "D’où les utilisateurs accèdent-ils à votre application ?",
    "pageStats": "Statistiques de la Page",
    "pageAcivity": "Activité de la Page",
    "blockDiagramTop50Pages": "Un schéma fonctionnel représentant les 50 pages les plus activées par des tags (en fonction du nombre d’accès à chaque page).",
    "pageSummaryOverview": "Synthèse d’ensemble de la Page",
    "pageDetail": "Détails d’une Page",
    "collectionChartsTaggedPages": "Un ensemble de graphiques représentant les activités sur les pages activées par des tags.",
    "pageName": "Nom de la Page",
    "noOfPages": "Nom de la page.",
    "labelsFilterTaggedPages": "Les étiquettes sont utilisées pour filtrer les pages activées par des tags.",
    "pageView": "Vues d’une Page",
    "numberTimesPageViewed": "Le nombre de consultations pour une page.",
    "uniqueVisits": "Visites uniques",
    "numberUniqueVisitorPage": "Le nombre de visiteurs uniques qui ont consulté la page.",
    "topPages": "Principales Pages",
    "topUntaggedVistis": "Les principales pages sans tag visitées par les utilisateurs.",
    "pageDistribution": "Contribution d’une Page",
    "chartRepresentingPage": "Un graphique représentant la contribution de l’activité d’une page.",
    "taggedPages": "Page avec tags",
    "percentageContribution": "Le pourcentage de pages contribuant à 80 % des vues.",
    "urlContibution": "Contribution d’une URL",
    "chartURLActivity": "Un graphique représentant la contribution de l’activité d’une URL.",
    "untaggedURL": "URL sans tag",
    "comparisonTaggedUntagged": "Comparaison en pourcentage des URL avec et sans tags.",
    "pageUserActivity": "Activité utilisateur sur une Page",
    "graphUserActiviyOnPage": "Graphiques représentant l’activité des utilisateurs sur une Page.",
    "totalUserVisit": "Nombre total de visites des utilisateurs",
    "totalUsersOnPageinTime": "Le nombre total d’utilisateurs qui ont consulté la page dans l’intervalle de temps imparti.",
    "minSessionDuration": "Durée minimum d’une session",
    "maxSessionDuration": "Durée maximum d’une session",
    "avgSessionDuration": "Durée moyenne d’une session",
    "minTimeonPageinTime": "Temps minimum passé sur la page dans l’intervalle de temps imparti.",
    "maxTimeonPageinTime": "Temps maximum passé sur la page dans l’intervalle de temps imparti.",
    "avgTimeonPageinTime": "Temps moyen passé sur la page dans l’intervalle de temps imparti.",
    "whenUsersVisit": "Quand vos utilisateurs consultent-ils ?",
    "heatMapforMaxTraffic": "Carte thermique représentant l’heure de la journée qui affiche le maximum de trafic sur la page",
    "pageLoadTime": "Temps de chargement de la Page",
    "averageTime": "Temps moyen",
    "workflowVisualSummary": "Résumé visuel d’un Workflow",
    "workflowUsageStats": "Statistiques d’utilisation d’un Workflow",
    "lineGraphWorkflowPlayed": "Un graphique linéaire représente le nombre de flux de travail mis en œuvre.",
    "workflowPlayCount": "Nombre de Workflow mis en œuvre",
    "workflowPlayedInTime": "Nombre de flux de travail mis en œuvre dans un intervalle de temps donné.",
    "uniqueWorkflowInTime": "Nombre de flux de travail uniques mis en œuvre dans un intervalle de temps donné.",
    "tableWorkflowAvgBestTime": "Tableau représentant la liste des workflows ainsi que leur temps moyen et leur meilleur temps.",
    "titleOfWorkflow": "Titre du flux de travail.",
    "associatedLabelWorkflow": "Nom du label auquel le flux de travail est associé.",
    "totalUsersWalkthrough": "Le nombre total d’utilisateurs qui ont exécuté la procédure guidée.",
    "avgTimeWorkflow": "Temps moyen passé sur le workflow.",
    "bestTimeWorkflow": "Meilleur temps passé sur le workflow.",
    "topWorkflows": "Principaux workflows",
    "top5WorkFLowByUsage": "Les cinq principaux workflows par utilisation",
    "barChartTop5Workflows": "Un graphique à barres représentant les cinq principaux workflows classés par ordre décroissant du nombre de mises en œuvre.",
    "workflowUsageSummary": "Récapitulatif de l’utilisation d’un Workflow",
    "usageSummaryGraph": "Graphique récapitulatif d’utilisation",
    "lineChartWorkflowinTime": "Un graphique linéaire représente le nombre de flux de travail mis en œuvre par les utilisateurs durant l’intervalle de temps donné.",
    "uniqueUserPlayCount": "Nombre de mises en œuvre par des utilisateurs uniques",
    "stepTitle": "Titre de l’étape",
    "stepPlayedCount": "Nombre d’étapes mises en œuvre",
    "numberTimesWorkflowPlayedbyAll": "Nombre de fois où le workflow a été mis en œuvre par tous les utilisateurs.",
    "numberTimesWorkflowPlayedbyUnique": "Nombre de fois où le workflow a été mis en œuvre par des utilisateurs uniques.",
    "usageHeatMap": "Carte thermique du niveau d’utilisation",
    "heatmapOnWorkflow": "Une carte thermique qui représente le temps passé sur le workflow.",
    "workflowUsageAnalysis": "Analyse de l’utilisation du Workflow",
    "scatterGraph": "Graphique en nuage de points",
    "scatterPlotforBestAvgWorst": "Un graphique en nuage de points représente le temps le meilleur, le temps intermédiaire et le moins bon temps durant l’intervalle de temps donné.",
    "bestTimeonWorkflow": "Le temps le meilleur passé sur le workflow.",
    "averageTimeonWorkflow": "Le temps intermédiaire passé sur le workflow.",
    "worstTimeonWorkflow": "Le moins bon temps passé sur le workflow.",
    "stepsTimeUsageTable": "Tableau de la durée d’utilisation des étapes",
    "tableUsageStepsinWorkflow": "Un tableau montre la durée d’utilisation des étapes dans le flux de travail sélectionné.",
    "titleStepWorkflow": "Le titre de l’étape dans le workflow.",
    "numberStepsPlayed": "Le nombre de fois où l’étape a été mise en œuvre.",
    "avgTimeSpentonStep": "Le temps moyen passé sur l’étape.",
    "bestTimeSpentonStep": "Le meilleur temps passé sur l’étape.",
    "workflowUsageEngagement": "Engagement d’utilisation du Workflow",
    "stepsFlowChart": "Organigramme des étapes",
    "pathChartforWorkflow": "Un graphique de parcours représentant le flux d’étapes dans le flux de travail sélectionné.",
    "stepDropOff": "Déconnexion de l’étape",
    "funnelForworkflow": "Représentation en entonnoir des étapes sélectionnées dans le flux de travail actuel. Vous pouvez sélectionner un minimum de 3 étapes et un maximum de 7 étapes. L’ordre de sélection n’a pas d’importance, car l’entonnoir s’affichera en fonction de l’ordre des étapes du flux de travail.",
    "specificUserActivity": "Activité utilisateur spécifique",
    "lineProficiency": "Un graphique linéaire représente le graphique de compétence des utilisateurs individuels. Le suivi des informations utilisateur doit être activé pour afficher les données de compétence de l’utilisateur.",
    "overallWorkflowUsage": "Usage général du Workflow",
    "workflowFootfallAnalysis": "Analyse des mouvements au sein du Workflow",
    "footfallGraph": "Graphique des mouvements",
    "graphWalkThroughEntireWorkflow": "Un graphique du pourcentage de procédures guidées obtenues pour chaque étape par rapport au nombre total de procédures guidées de l’ensemble du flux de travail.",
    "workflowPathAnalysis": "Analyse du parcours au sein du Workflow",
    "pathChart": "Graphique de parcours",
    "graphStepsCombinationWorkflow": "Un graphique représentant toutes les combinaisons de séquences d’étapes exécutées par les utilisateurs pour un flux de travail particulier.",
    "pathChartLegend": "Légende du graphique de parcours",
    "legendsStepsColorCode": "Légende présentant les étapes d’après leurs codes couleurs.",
    "barGraphPosNegNeu": "Un graphique à barres représentant la distribution des réponses positives, négatives et neutres au sondage.",
    "tableActitiesofSurvey": "Un tableau représentant les activités des sondages.",
    "titleSurveyCreated": "Titre du Survey créé.",
    "labelsofSurvey": "Labels du sondage.",
    "numberResponsesEachSurvey": "Nombre de réponses pour chaque sondage.",
    "responseDistribution": "Distribution des réponses",
    "graphPercentageSurevey": "Graphique représentant le pourcentage de réponses au sondage.",
    "countPositiveResponses": "Nombre de réponses positives dans l’intervalle de temps donné.",
    "countNeutralResponses": "Nombre de réponses neutres dans l’intervalle de temps donné.",
    "countNegativeResponses": "Nombre de réponses négatives dans l’intervalle de temps donné.",
    "pieChartPosNegNeu": "Un graphique à secteurs montrant le nombre et le pourcentage de réponses positives, négatives et neutres pour ce sondage.",
    "surveyQuestions": "Questions du Survey",
    "verifyAccount": "Vérifier le compte",
    "otpSent": "Le mot de passe à usage unique a été envoyé à votre adresse email. Veuillez entrer le mot de passe à usage unique pour vérifier votre compte.",
    "otp": "Mot de passe à usage unique",
    "enterNumber": "Veuillez saisir uniquement des chiffres",
    "didntReceiveOTP": "Vous n’avez pas reçu de mot de passe à usage unique ?",
    "clicktoResendOTP": "Cliquez pour renvoyer le mot de passe à usage unique",
    "backToLogin": "Revenir à l’espace de connexion",
    "manageGoals": "Gérer l’Goal",
    "goalDeletedSuccessfully": "L’Goal a été supprimé avec succès.",
    "manageSurvey": "Gérer le Survey",
    "surevyStats": "Statistiques relatives au Survey",
    "surveyStatsDetailed": "Statistiques détaillées relatives au Survey",
    "graphActivitiesOnLiveGuide": "Graphique représentant les activités des guides en direct.",
    "totalNumberOfGuidesPlayed": "Le nombre total de guides consultés dans l’intervalle de temps donné.",
    "totalNumberOfUniqueGuidesPlayed": "Le nombre total de guides uniques consultés (par utilisateur unique) dans l’intervalle de temps donné.",
    "totalNumberOfCompleteGuidesPlayed": "C’est le nombre total de guides consultés de la toute première à la dernière étape.",
    "completionRatio": "Ratio d’achèvement",
    "slideshowPlayedInGivenTimeFrame": "Le nombre total de diaporamas consultés dans l’intervalle de temps donné.",
    "totalVideosPlayedInGivenTimeFrame": "Le nombre total de vidéos consultées dans l’intervalle de temps donné.",
    "totalGIFdPlayedInGivenTimeFrame": "Le nombre total de GIF consultés dans l’intervalle de temps donné.",
    "pieActivityPercentageDistribution": "Un graphique à secteurs représentant la répartition en pourcentage des guides téléchargés sous divers formats.",
    "barGraphTestMe": "Un graphique à barres représentant le nombre total d’utilisateurs qui ont effectué l’activité TestMe où chaque barre est divisée en 2 parties (nombre d’utilisateurs ayant réussi le test et nombre d’utilisateurs ayant échoué au test).",
    "guidePlayedVieDoItForMe": "Graphique représentant les guides consultés par automatisation du mode DoItForMe.",
    "myGuideSurvey": "Survey relatif à MyGuide",
    "totalNuberOfGuideAndExitResponses": "Un graphique représentant le nombre total de Guide(s) et les réponses de sortie fournies par les utilisateurs de ces guides.",
    "displayCount": "Afficher le nombre",
    "guideName": "Nom du Guide",
    "myGuideSurveysPlayed": "Sondages MyGuide effectués",
    "tableRepresentingGuideActivites": "Tableau représentant les activités des Guides.",
    "nameOfGuidesPlayed": "Nom des guides consultés.",
    "totalGuidesCompleteCount": "Le nombre total de guides, de guides uniques consultés (par utilisateur unique) et le nombre total de guides consultés durant l’intervalle de temps donné.",
    "totalSlideshowVideoGIFCount": "Le nombre total de diaporamas, de vidéos et de GIF consultés dans l’intervalle de temps donné.",
    "totalTestPassCount": "Le nombre total d’utilisateurs qui ont réussi et échoué aux tests dans l’intervalle de temps donné.",
    "totalResponsesSubmitted": "Nombre total de réponses envoyées par les utilisateurs pour le guide dans l’intervalle de temps donné.",
    "guideMePlay": "Consultation de GuideMe",
    "guidePlayedStats": "Statistiques de consultation de Guide",
    "avgGuidePlayed": "Niveau de consultation moyen du guide dans l’intervalle de temps donné par utilisateur unique.",
    "guidePlayedOverview": "Vue d’ensemble du Guide consulté",
    "radialChartLiveGuides": "Un graphique radial représentant le niveau d’activité sur les guides en direct.",
    "avgPlayed": "Consultation moyenne",
    "guidePlaySources": "Sources de consultation du Guide (notifications Push, Beacons, panneau & URL)",
    "pieChartGuideSources": "Un graphique à secteurs représentant le Guide consulté via des notifications Push, des Beacons, le panneau & des URL.",
    "audioGuidance": "Accompagnement audio",
    "audioConsumptionGuideMeShowMe": "Graphique représentant l’accompagnement audio sur les modes GuideMe/ShowMe pris en charge.",
    "guideMeDetails": "Détails sur GuideMe",
    "detailedSummary": "Récapitulatif détaillé",
    "tableOfLiveGuideActivities": "Tableau représentant les activités des étapes des guides en direct.",
    "startStep": "Début lors de cette étape",
    "endStep": "Fin lors de cette étape",
    "failedOnStep": "Échec lors de cette étape",
    "stepsFlow": "Flux d’étapes",
    "titleOfStep": "Titre de l’étape.",
    "numberOfTimesStepPlayed": "Nombre de fois où l’étape a été mise en œuvre.",
    "stepBeganFromCurrentStep": "Nombre de fois où la consultation du guide a commencé à partir de l’étape en cours.",
    "stepEndedOnCurrentStep": "Nombre de fois où la consultation du guide s’est terminée à l’étape en cours.",
    "stepErrors": "Nombre de fois où la consultation du guide s’est interrompue à cette étape en raison d’erreurs.",
    "sankeyPaths": "Diagramme de Sankey représentant les différents parcours suivis par les utilisateurs lors de la consultation du guide.",
    "funnelWarningMsg": "Représentation en entonnoir des étapes sélectionnées dans le guide en cours. Vous pouvez sélectionner un minimum de 3 étapes et un maximum de 7 étapes. L’ordre de sélection n’a pas d’importance, car l’entonnoir s’affichera en fonction de l’ordre des étapes dans le guide.",
    "graphActivitiesOnShowMe": "Graphique représentant les activités sur ShowMe.",
    "downloadDistribution": "Un graphique représentant la répartition des guides téléchargés dans divers formats.",
    "showMePlaySources": "Sources de consultation du mode ShowMe (notifications Push, Beacons, panneau & URL)",
    "pieChartShowMeModes": "Un graphique à secteurs représentant le mode ShowMe consulté via des notifications Push, des Beacons, le panneau & des URL.",
    "guidesPlayedViaDoItForMe": "Graphique représentant le nombre de fois où ce guide a été consulté par automatisation du mode DoItForMe.",
    "doItForMeShowMeSources": "Sources de consultation de DoItforMe (notifications Push, Beacons, panneau & URL)",
    "doItForMeTableActivities": "Tableau représentant les activités de consultation du guide en mode DoItForMe.",
    "humanInterventionCount": "Nombre d’interventions humaines",
    "humanInterventionCountTimes": "Nombre de fois où une étape a nécessité une intervention humaine.",
    "skippedStepCount": "Nombre d’étapes ignorées",
    "skippedStepCountTimes": "Nombre de fois où une étape a été ignorée",
    "failedOnStepTimes": "Nombre de fois où la consultation du guide a échoué à cette étape en raison d’erreurs.",
    "myGuideSurveys": "Sondages MyGuide",
    "guideExitStepSurveys": "Guide, quitter le Guide & sondages d’étapes",
    "tutorialGuideStats": "Statistiques des guides de Tutorial",
    "lineChartTutorialGuide": "Un graphique linéaire représentant les statistiques des guides de didacticiel.",
    "activitiesOnTutorialGraph": "Graphique représentant les activités des guides de didacticiel.",
    "tutorialGuideName": "Nom du Guide de Tutorial consulté.",
    "totalNoOfTutorialGuidesPlayed": "Nombre total de guides de didacticiel consultés",
    "guideCompleted": "Guide finalisé",
    "totalNumberOfGuidesCompleted": "Nombre total de guides de didacticiel finalisés.",
    "tutorialGuideDetailSummary": "Récapitulatif détaillé d’un Guide de Tutorial",
    "tableOfGuidePlayActivities": "Tableau représentant les activités de consultation du guide.",
    "tooltipGuidanceAndValidationGraph": "Un graphique linéaire représentant les statistiques d’assistance et de validation des info-bulles dans l’intervalle de temps donné.",
    "countOfTooltipHoverClicked": "Nombre de fois où l’utilisateur a interagi avec l’info-bulle sur une page soit par survol, clic ou lorsque la page a été chargée.",
    "countOfValidationPerformed": "Nombre de fois où la validation a été effectuée sur la page.",
    "countOfValidationAndInteractionPerformed": "Nombre de fois où les deux info-bulles ont été consultées et la validation a été effectuée.",
    "barChartPowerForm": "Un graphique en barres empilées représentant les statistiques d’état lorsque le formulaire Power a été utilisé dans l’intervalle de temps donné.",
    "countOfSubmittedPowerForm": "Nombre de tous les utilisateurs qui ont envoyé le formulaire Power.",
    "countOfCancelledPowerForm": "Nombre de tous les utilisateurs qui ont annulé le formulaire Power.",
    "tableOfPowerFormStats": "Un tableau représentant les statistiques d’état du formulaire Power dans l’intervalle de temps donné.",
    "titleOfPowerForm": "Titre d’un formulaire Power contenant l’ordre des étapes et le nom du guide relatif aux info-bulles.",
    "countOfCancelledSpecificPowerForm": "Nombre de tous les utilisateurs qui ont annulé le formulaire Power spécifique.",
    "countOfSubmittedSpecificPowerForm": "Nombre de tous les utilisateurs qui ont envoyé le formulaire Power spécifique.",
    "tableOfStatsOfTooltip": "Un tableau représentant les statistiques des info-bulles dans l’intervalle de temps donné.",
    "nameOfTooltipContainsMoreTooltips": "Nom du guide relatif aux info-bulles qui contient une ou plusieurs info-bulles.",
    "interactionHappenedWithGuidanceType": "Nombre de fois où une interaction utilisateur s’est produite avec une info-bulle de type Assistance dans un guide spécifique relatif aux info-bulles.",
    "interactionHappenedWithValidationType": "Nombre de fois où une interaction utilisateur s’est produite avec une info-bulle de type Validation dans un guide spécifique relatif aux info-bulles.",
    "interactionHappenedWithGuidanceValidationType": "Nombre de fois où une interaction utilisateur s’est produite avec une info-bulle de type Assistance & Validation dans un guide spécifique relatif aux info-bulles.",
    "digitalDuctTapeRenderCount": "Nombre de fois où un Duct Tape numérique s’est affiché et une action a été effectuée sur ce dernier.",
    "interactionHappenedWithPowerForm": "Nombre de fois où une interaction utilisateur s’est produite avec le Power Form.",
    "tooltipValidationActivity": "Activité de type Tooltip & Validation",
    "countOfTooltipClickedAndHovered": "Nombre de fois où l’info-bulle s’est affichée sur une page soit par survol, clic ou lorsque la page a été chargée.",
    "countTooltipShownAndValidationPerformed": "Nombre de fois où les deux info-bulles se sont affichées et où la validation a été effectuée.",
    "ductTapeActivity": "Activité du Duct Tape",
    "statsOfDigitalDuctTape": "Un graphique représentant les statistiques du ruban numérique dans l’intervalle de temps donné.",
    "countDigitalDuctTapeDisplayed": "Nombre de fois où le ruban numérique s’est affiché sur la page.",
    "powerFormActivity": "Activité de Power Form",
    "newUser": "Nouveaux utilisateurs",
    "totalUsers": "Nombre total d’utilisateurs",
    "engagedUsers": "Utilisateurs engagés",
    "newUserCount": "Le nombre total de nouveaux utilisateurs (n’ayant eu aucune session au cours des 30 derniers jours) visitant les pages contenant des guides.",
    "numberOfUsersVisitingGuidePages": "Le nombre total d’utilisateurs visitant les pages contenant des guides.",
    "usersPerformedSomeActivity": "Les utilisateurs qui ont effectué une activité avec MyGuide.",
    "lineGraphUserEngagement": "Graphique linéaire représentant les engagements des utilisateurs.",
    "guideMeUniquePlayCount": "Nombre de consultations uniques de Guide Me",
    "guideMeTotalPlayCount": "Nombre total de consultations de Guide Me",
    "guideMeCompletePlayCount": "Nombre de consultations finalisées de Guide Me",
    "numberOfUsersPlayedInGuideMode": "Nombre total d’utilisateurs ayant consulté des guides en mode GuideMe dans un intervalle de temps donné.",
    "numberOfUniqueUsersPlayedInGuideMode": "Nombre d’utilisateurs uniques ayant consulté des guides en mode GuideMe dans un intervalle de temps donné.",
    "numberOfTotalUsersPlayedInGuideMode": "Nombre total d’utilisateurs ayant finalisé la consultation de guides en mode GuideMe dans un intervalle de temps donné.",
    "doItForMeUniquePlayCount": "Nombre de consultations uniques de DoItForMe",
    "doItForMeTotalPlayCount": "Nombre total de consultations de DoItForMe",
    "doItForMeCompleteCount": "Nombre de consultations finalisées de DoItForMe",
    "showMeTotalPlayCount": "Nombre total d’utilisateurs ayant consulté des guides en mode ShowMe dans un intervalle de temps donné.",
    "showMeUniquePlayCount": "Nombre d’utilisateurs uniques ayant consulté des guides en mode ShowMe dans un intervalle de temps donné.",
    "testMeUserTestCount": "Nombre total d’utilisateurs ayant effectué des tests en mode TestMe dans un intervalle de temps donné.",
    "testMeUniqueUserTestCount": "Nombre d’utilisateurs uniques ayant effectué le test en mode TestMe dans un intervalle de temps donné.",
    "doItForMeUserPlayCount": "Nombre total d’utilisateurs ayant consulté des guides en mode DoItForMe dans un intervalle de temps donné.",
    "doItForMeUniqueUserPlayCount": "Nombre d’utilisateurs uniques ayant consulté des guides en mode DoItForMe dans un intervalle de temps donné.",
    "doItForMeCompleteUserPlayCount": "Nombre total d’utilisateurs ayant finalisé la consultation de guides en mode DoItForMe dans un intervalle de temps donné.",
    "tutorialGuidePlayCount": "Nombre total de consultations des Guides de Tutorial",
    "tutorialGuideUniquePlayCount": "Nombre de consultations uniques de Guides de Tutorial",
    "tutorialGuideCompletePlayCount": "Nombre de consultations finalisées de Guides de Tutorial",
    "tutorialGuideUserPlayCount": "Nombre total d’utilisateurs ayant consulté des Guides de Tutorial dans un intervalle de temps donné.",
    "tutorialGuideUniqueUserPlayCount": "Nombre d’utilisateurs uniques ayant consulté des Guides de Tutorial dans un intervalle de temps donné.",
    "tutorialGuideCompleteUserPlayCount": "Nombre total d’utilisateurs ayant finalisé des Guides de Tutorial dans un intervalle de temps donné.",
    "userEngagementSummaryOverview": "Synthèse d’ensemble de l’engagement d’un utilisateur",
    "userEngagementSummary": "Récapitulatif de l’engagement d’un utilisateur",
    "tableOfUserEngagements": "Tableau présentant la liste d’engagements d’un utilisateur.",
    "email": "email",
    "emailIdOfUser": "Identifiant de messagerie de l’utilisateur qui a effectué une activité.",
    "nameOfUser": "Nom de l’utilisateur qui a effectué une activité.",
    "guidePlayedByUser": "Le nombre total de guides consultés par l’utilisateur dans l’intervalle de temps donné.",
    "guidesPlayedByShowMeMod": "Le nombre total de guides consultés par l’utilisateur en mode ShowMe dans l’intervalle de temps donné.",
    "guidesPlayedByTestMeMod": "Le nombre total de guides consultés par l’utilisateur en mode TestMe dans l’intervalle de temps donné.",
    "userEngagementDetailSummary": "Synthèse d’ensemble détaillée de l’engagement d’un utilisateur",
    "userEngagementDetailSummaryTable": "Tableau présentant les activités de l’utilisateur relatives aux Guides.",
    "userEngagementDetailGuideName": "Nom des guides consultés.",
    "userEngagementDetailTotalGuidePlay": "Le nombre total de guides et le nombre total de guides consultés durant l’intervalle de temps donné pour l’utilisateur.",
    "userEngagementDetailShowMePlay": "Le nombre total de diaporamas, de vidéos et de GIF consultés dans l’intervalle de temps donné pour l’utilisateur.",
    "userEngagementDetailTestMePlay": "Le nombre total d’utilisateurs qui ont réussi et échoué aux tests dans l’intervalle de temps donné pour l’utilisateur.",
    "userGuideGraph": "Graphiques des Guides d’un utilisateur",
    "graphOfLiveGuideActivities": "Graphique représentant les activités de l’utilisateur sur les guides en direct.",
    "playsByMedia": "Consultations par type de média",
    "donutChartShowMeDistribution": "Graphique en anneau présentant la répartition en pourcentage des activités sur ShowMe.",
    "playsByTime": "Consultations par heure",
    "lineChartOfTestMeActivities": "Graphique linéaire présentant le décompte horaire total des fois où l’utilisateur a réussi ou échoué dans l’activité TestMe.",
    "graphOfDoItForMeMode": "Graphique présentant les activités de l’utilisateur sur les guides en direct consultés en mode DoItForMe.",
    "totalGuidesPlayedInDoItForMeMode": "Le nombre total de guides consultés en mode DoItForMe dans l’intervalle de temps donné.",
    "totalGuidesPlayedFromFirstStep": "Il s’agit du nombre total de guides consultés de la toute première à la dernière étape en mode DoItForMe.",
    "userEngagementTimeline": "Chronologie de l’engagement de l’utilisateur",
    "userEngagementPlayDetails": "Détails des consultations",
    "tutorialGuideActivity": "Activité des Guides de Tutorial",
    "userActivitiesTimelineCharts": "Le graphique chronologique représente la série d’activités effectuées par un utilisateur spécifique dans un intervalle de temps donné. Affiche également le mode dans lequel l’activité d’assistance a été effectuée.",
    "listOfSteps": "Il affiche la liste détaillée des étapes exécutées par un utilisateur pour un guide spécifique dans un intervalle de temps donné.",
    "userActivitiesOnTutorialGuide": "Graphique présentant les activités de l’utilisateur relatives aux Guides de Tutorial.",
    "totalNumberOfGuideInTutorialGuide": "Le nombre total de Guides de Tutorial consultés dans l’intervalle de temps donné.",
    "totalNumberOfGuideInTutorialGuideFromFirstStep": "C’est le nombre total de Guides de Tutorial consultés de la toute première à la dernière étape.",
    "searchOverview": "Vue d’ensemble des recherches",
    "searchKeywords": "Mots clés de recherche",
    "keyword": "Mot clé",
    "numberOfSearches": "Nombre de recherches",
    "avgResultRetured": "Nombre moyen de résultats renvoyés",
    "clicks": "Clics",
    "searchSummary": "Récapitulatif de recherche",
    "tableOfSearchSummary": "Tableau présentant les 50 principaux mots clés (en fonction du nombre d’accès à chaque mot clé) dans l’intervalle de temps donné. Vous êtes libre de rechercher le mot clé souhaité à l’aide de la fonction de recherche si vous pensez qu’il n’a pas été retenu dans ce tableau.",
    "searchKeystrokes": "Recherche de séquences de touches telles qu’elles ont été recherchées par divers utilisateurs.",
    "keystrokesHitByUser": "Nombre de fois où chaque séquence de touches a été saisie par différents utilisateurs",
    "avgKeystrokes": "Le nombre moyen de résultats (minimum) renvoyés, sur la période par rapport au nombre de recherches effectuées pour chaque séquence de touches.",
    "totalClickSearchResult": "Il s’agit du nombre de fois où un élément particulier des résultats de recherche a fait l’objet d’un clic",
    "wordClouseTooltipText": "Diagramme de nuage de mots représentant le volume de recherche par rapport aux 50 premiers mots clés (en fonction du nombre de résultats pour chaque mot clé) dans l’intervalle de temps donné.",
    "ductTapeTable": "Un tableau représentant les statistiques du ruban numérique dans l’intervalle de temps donné.",
    "guideAutomationPlay": "Consultation automatisée du Guide",
    "testCasesExecutedGraph": "Un graphique linéaire présentant le nombre de fois où des scénarios de test ont été exécutés dans l’intervalle de temps donné.",
    "numberOfTimesTestCasesAutoExecuted": "Nombre de fois où des scénarios de test ont été exécutés automatiquement selon l’horaire planifié dans l’intervalle de temps donné.",
    "numberOfTimesTestCasesManuallyExecuted": "Le nombre de fois où des scénarios de test ont été exécutés manuellement par l’utilisateur dans l’intervalle de temps donné.",
    "guideAutomationSummary": "Récapitulatif relatif à l’automatisation du Guide",
    "testCasesTable": "Un tableau présentant l’état et les statistiques des scénarios de test individuels.",
    "playTime": "Heure de consultation",
    "nameOfTestCase": "Nom du scénario de test.",
    "testCaseTriggeredTime": "Heure à laquelle le scénario de test a été mis en œuvre.",
    "automaticallTriggered": "Indique si le scénario de test a été mis en œuvre automatiquement selon l’horaire planifié ou manuellement par l’utilisateur.",
    "testCaseModes": "Il indique si le scénario de test s’est déclenché, s’est interrompu pour une raison quelconque ou s’est correctement achevé.",
    "guideAutomationDetails": "Détails sur l’automatisation du Guide",
    "details": "Détails",
    "testCaseStatus": "Une vue détaillée et imbriquée de tous les scénarios de test avec leur état.",
    "testCaseTitle": "Titre d’un guide sur lequel le scénario de test a été mis en œuvre.",
    "testCaseResult": "Scénario de test réussi ou échoué sur un guide spécifique.",
    "testCaseInfo": "Informations à l’appui de l’état du scénario de test ayant été mis en œuvre sur un guide spécifique.",
    "testCaseGuideInfo": "Texte fournissant quelques informations supplémentaires sur l’état d’exécution du guide.",
    "goalDetailOverview": "Vue d’ensemble des détails de l’Goal",
    "goalPlayed": "Goal consulté",
    "doItForMePlayed": "DoItForMe consulté",
    "goalActivitiesChart": "Graphique linéaire représentant les activités sur les objectifs définis.",
    "totalGuidesAndGuidesPlayedCount": "Le nombre total de guides et le nombre total de guides consultés durant l’intervalle de temps donné.",
    "totalTooltipsRenderCount": "Le nombre total d’info-bulles affichées dans l’intervalle de temps donné.",
    "goalProgress": "Avancement de l’Goal",
    "userGuidesSummary": "Récapitulatif des Guides de l’utilisateur",
    "goalCompletion": "Finalisation de l’Goal",
    "goalCompletionOverhead": "Temps système de finalisation de l’Goal",
    "goalProgressBarChart": "Un graphique à barres représentant l’état d’avancement de l’objectif",
    "targetGoalPercentage": "Le pourcentage d’objectifs cibles définis atteints par l’utilisateur.",
    "goalsExceedingTargets": "Le pourcentage d’objectifs excédant les objectifs cibles définis atteints par l’utilisateur.",
    "guideModification": "Modification des Guides",
    "guideMeShowMeComparison": "Un graphique linéaire comparant les consultations de GuideMe et ShowMe dans l’intervalle de temps donné.",
    "guidesPlayedInGuideMeMode": "Le nombre total de guides consultés en mode GuideMe dans l’intervalle de temps donné.",
    "guidesPlayedInShowMeMode": "Le nombre total de guides consultés en mode ShowMe dans l’intervalle de temps donné.",
    "userInteractedWithPNBTMode": "Un graphique représentant le nombre total de fois où les utilisateurs ont interagi avec les notifications Push, les Beacons et les info-bulles lors de la consultation de la page.",
    "totalModifiedGuides": "Un graphique à barres représentant le nombre total de guides qui ont été modifiés dans l’intervalle de temps donné.",
    "serviceNotAvailable": "Service non disponible",
    "conflictingParameters": "Paramètres conflictuels dans la requête",
    "insightsLogin": "Connexion de Insights",
    "emailId": "ID de messagerie",
    "emailPlaceholder": "nom@domain.com",
    "password": "Mot de passe",
    "remember": "N’oubliez pas",
    "pleaseContact": "Veuillez contacter",
    "accountSuspended": "Votre compte a été suspendu.",
    "forgotPassword": "Mot de passe oublié ?",
    "login": "Connexion",
    "copyright": "Copyright ©",
    "sessionExpiredMsg": "Votre session a expiré.",
    "reLogin": "Veuillez vous reconnecter.",
    "sessionExpired": "Session expirée",
    "reaTimeDateCondition": "Les données en temps réel sont incluses dans toutes les sections de données prises en charge. Veuillez noter que la sélection de la date ne sera autorisée que jusqu’à un jour avant la date actuelle dans le sélecteur de date.",
    "goToHome": "Accéder à l’accueil",
    "pageNotFound": "Oups... page introuvable !",
    "brandName": "MyGuide",
    "buildversion": "Version de module",
    "supportLinkText": "Si vous avez besoin d’aide, veuillez nous envoyer un email à l’adresse",
    "mailto": "mailto",
    "addChartinCustomDash": "Aucun graphique n’a encore été ajouté. Veuillez ajouter des graphiques au Dashboard personnalisé.",
    "commentQnAnySuggestion": "Avez-vous des suggestions ?",
    "commentQnImprove": "Que pouvons-nous améliorer ?",
    "commentQnDescribe": "Décrivez le produit en quelques mots.",
    "multiselectFeaturesLiked": "Quelles sont les fonctionnalités que vous avez le plus appréciées ?",
    "multiselectFeaturesUsed": "Lesquelles de ces fonctionnalités avez-vous déjà utilisées ?",
    "multiselectPlatform": "Sur quelle plateforme consultez-vous le site ?",
    "rangeSelect": "Considérant une fourchette de 1 à 10, comment nous évaluez-vous ?",
    "rangeProvide": "Quelle note nous donneriez-vous ?",
    "rateUs": "Évaluez nous",
    "satisfiedRate": "Êtes-vous satisfait de nous ?",
    "singleselectLikeGuide": "Avez-vous aimé le guide ?",
    "singleselectSuggestGuide": "Proposerez-vous le guide à vos amis ?",
    "singleselectAccomplishGuide": "Le guide vous a-t-il aidé à atteindre votre objectif ?",
    "yesNoLikeGuide": "Avez-vous aimé le guide ?",
    "yesNoSuggest": "Proposerez-vous le guide à vos amis ?",
    "yesNoAccomplish": "Le guide vous a-t-il aidé à atteindre votre objectif ?",
    "downloadingOption": "Téléchargement en cours",
    "desktop": "Bureau",
    "mobile": "Mobile",
    "tablet": "Tablette",
    "mayBe": "Peut être",
    "minute": "minute",
    "minutes": "minutes",
    "second": "seconde",
    "responsesTitle": "RÉPONSES",
    "dashboard": "Dashboard",
    "manage": "Gérer",
    "selectType": "Sélectionner un type",
    "linkedPage": "Page liée",
    "linkPage": "Lier la Page",
    "branching": "Branchement",
    "addOptionLink": "Ajouter un lien d’option",
    "iconToCreateNewSurvey": "sur l’icône pour créer un nouveau Survey",
    "clickToEditSurvey": "Cliquez sur le sondage dans la liste pour modifier ce sondage en particulier",
    "lastUpdated": "Date de la dernière mise à jour",
    "global": "Global",
    "questions": "Questions",
    "turnOnGoalNotif": "Activez pour recevoir des emails pour chaque jalon atteint pour un objectif.",
    "turnOffGoalNotif": "Désactivez pour cesser de recevoir des emails pour chaque jalon atteint pour un objectif.",
    "turnOnEmailNotif": "Activez pour recevoir des emails lorsque l’objectif est atteint.",
    "turnOffEmailNotif": "Désactivez pour cesser de recevoir des emails lorsque l’objectif est atteint.",
    "error": "Erreur !",
    "goalAlreadyExists": "L’Goal existe déjà. Sélectionnez un autre titre.",
    "successfullyCreated": "Création réussie",
    "enterText": "Saisir le texte",
    "searchGoal": "Rechercher l’Goal",
    "enterQuestion": "Saisir votre question",
    "addLink": "Ajouter un lien",
    "searchSurvey": "Rechercher un Survey",
    "nameTagOnPage": "Nommer le tag sur la Page",
    "describeTagOnPage": "Décrire le tag sur la Page",
    "selectUser": "Sélectionner un utilisateur",
    "pinToDashboard": "Épingler sur le Dashboard",
    "deleteDashboard": "Supprimer le tableau de bord",
    "publishGoal": "Publier l’Goal",
    "deleteChart": "Supprimer le graphique",
    "analyzerQuestion": "Question de l’analyseur",
    "branchingQuestion": "Question de branchement",
    "allDashboardDataDeletedMsg": "Toutes les données du Dashboard seront supprimées.",
    "dashboardDeleteConfirmMsg": "Voulez-vous supprimer ce tableau de bord ?",
    "exportDashboardData": "Exporter les données du Dashboard",
    "deleteGoal": "Supprimer l’Goal",
    "viewPendingJobs": "Voir les emplois en attente",
    "deleteSurvey": "Supprimer le Survey",
    "deleteSurveyConfirmMsg": "Voulez-vous vraiment supprimer ce sondage ?",
    "shareSurvey": "Partager des sondages",
    "best": "Meilleur",
    "worst": "Pire",
    "average": "Moyen",
    "selectRange": "Sélectionner une plage",
    "searchSegment": "Rechercher un segment",
    "searches": "Recherches",
    "org": "ORGANISATION",
    "downloads": "Téléchargement(s)",
    "selectApps": "Sélectionner des applications",
    "weeklyUsageStats": "Statistiques d’utilisation hebdomadaires",
    "dailyFailureReport": "Rapport quotidien sur les étapes en échec",
    "allApps": "Toutes les applications",
    "chooseDahsboard": "Choisir un Dashboard",
    "userEngagementDetail": "Détails sur l’engagement de l’utilisateur",
    "organizationDetail": "Détails sur l’organisation",
    "organizationView": "Vue de l’organisation",
    "guideSurveyQuestionsAndResponses": "Questions et réponses relatives au Survey du Guide",
    "exitGuideSurveyQuestionsAndResponses": "Questions et réponses relatives au Surveysur la sortie du Guide",
    "guideStepSurveyQuestionsAndResponses": "Questions et réponses relatives au Survey sur les étapes du Guide",
    "pageDetails": "Détails sur la Page",
    "controlCenter": "Centre de contrôle",
    "slideshows": "Diaporama(s)",
    "videos": "Vidéo(s)",
    "docx": "DOCX",
    "html": "HTML",
    "gifs": "GIF(s)",
    "guideDetail": "Détails d’analyse sur le Guide",
    "tutorialGuideDetail": "Détails sur le Guide du Tutorial",
    "docTitleGuideDetail": "Insights sur le Guide - Détails sur le Guide",
    "docTitleTutorialGuideDetail": "Insights sur le Guide - Details sur les Guides du Tutorial",
    "docTitleTooltipDetails": "Insights sur le Guide - Détails sur les Tooltips",
    "docTitleUserEngagementDetail": "Insights sur le Guide - Détails sur l’engagement de l’utilisateur",
    "docTitleGuideAutomationDetail": "Insightssur l’automatisation - Détails sur l’automatisation du Guide",
    "tooltipDetails": "Détails sur les Tooltips",
    "userEngagement": "Engagement de l’utilisateur",
    "search": "Rechercher",
    "guidanceAndValidation": "Assistance & Validation",
    "guideMeAndShowMe": "GuideMe & ShowMe",
    "of": "de",
    "tooltipAndValidation": "Tooltip & Validation",
    "userActivity": "Activité utilisateur",
    "eventType": "Type d’événement",
    "guideDownloads": "Téléchargements du Guide",
    "time": "Temps",
    "combineWorkflowSteps": "Étapes de workflow combinées",
    "share": "Partager",
    "generating": "Génération en cours...",
    "never": "Jamais",
    "custom": "Personnaliser",
    "surveyModalErrorMsg": "250 caractères maximum sont autorisés",
    "scheduled": "Programmé",
    "manual": "Manuel",
    "interrupted": "Interrompu",
    "editGoal": "Modifier l’Goal",
    "stopWatching": "Arrêter de regarder",
    "startWatching": "Commencer à regarder",
    "invalidDate": "Date non valide",
    "invalidStartAndEndDate": "Date de début et date de fin non valides",
    "goalNotificationEmail": "Email de notification de l’Goal",
    "goalCompletionPercent": "% de finalisation de l’Goal",
    "goalCompletionOverheadPercent": "Temps système requis pour finaliser l’Goal (en %)",
    "actualPlays": "Consultations en cours",
    "targetPlays": "Consultations ciblées",
    "dataSource": "Source de données",
    "selectIcon": "Sélectionner l’icône",
    "guideSteps": "Étapes du Guide",
    "createFilter": "Créer un filtre",
    "filterList": "Filtrer la liste",
    "orgList": "Liste des organisations",
    "allOrganizations": "Toutes les organisations",
    "views": "Vues",
    "docTitleUserEngagement": "Insights sur le Guide - Engagement de l’utilisateur",
    "docTitleSearches": "Insights sur le Guide - Recherches",
    "orLater": "ou suivant",
    "pageNumber": "Page",
    "live": "En direct",
    "achieved": "Atteint",
    "ended": "Achevé",
    "achievedAndLive": "Achevé et en direct",
    "saved": "Enregistré",
    "down": "Descendre",
    "up": "Monter",
    "urlPath": "Chemin de l’URL",
    "urlParameters": "Paramètres de l’URL",
    "urlHash": "Hash de l’URL",
    "errorInvalidURL": "Erreur : URL non valide",
    "publish": "Publier",
    "btnYes": "Oui",
    "btnNo": "Non",
    "select": "Sélectionner",
    "export": "Exporter",
    "apply": "Appliquer",
    "all": "Tout",
    "slideShow": "Diaporama",
    "video": "Vidéo",
    "seconds": "secondes",
    "refresh": "Actualiser",
    "hour": "heure",
    "hours": "heures",
    "inputPlaceHolderSearch": "Rechercher un Guide",
    "noRecordFound": "Aucune donnée disponible",
    "avgRating": "Notation moyenne",
    "ratings": "Notations",
    "ranges": "Plages",
    "option": "Option",
    "nameOfTheApp": "Nom de l’application",
    "guideMePlays": "Consultations de GuideMe",
    "guidePlayedInGuideMeMode": "Nombre de fois où un guide a été consulté en mode GuideMe",
    "showMePlays": "Consultations de ShowMe",
    "guidePlayedInShowMeMode": "Nombre de fois où un guide a été consulté en mode ShowMe",
    "testMePlays": "Consultations de TestMe",
    "gupidePlayedInTestMeMode": "Nombre de fois où un guide a été consulté en mode TestMe",
    "doItForMePlayes": "Consultations de DoItForMe",
    "guidePlayedInDoItForMeMode": "Nombre de fois où un guide a été consulté en mode DoItForMe",
    "tutorialPlays": "Consultations d’un Tutorial",
    "guidePlayedInTutorialMode": "Nombre de fois où un guide a été consulté en mode Tutorial",
    "nameOfWorkflow": "Nom du Workflow",
    "workflowCounts": "Nombre de procédures guidées",
    "counts": "nombres",
    "avgTimeSpentOnWorkflow": "Temps moyen consacré à un flux de travail",
    "minTimeSpentOnWorkflow": "Temps minimum consacré à un flux de travail",
    "numberOfGuidesPlayed": "Nombre de guides consultés.",
    "numberOfShowMePlayed": "Nombre de consultations ShowMe",
    "numberOfTestMePlayed": "Nombre de consultations TestMe",
    "numberOfSurveyResponses": "Nombre de réponses au Survey",
    "nameOfSTep": "Nom de l’étape",
    "stepPlayeCount": "Nombre d’étapes mises en œuvre",
    "numberOfTimeStepWasPlayed": "Nombre de fois où l’étape a été mise en œuvre",
    "stepStartedFrom": "Début lors de cette étape",
    "numberOfTimesSTepStartedFrom": "Nombre de fois où la consultation a commencé à partir de cette étape",
    "stepEndedOn": "Fin lors de cette étape",
    "numberOfTimeStepEndedOn": "Nombre de fois où la consultation s’est terminée sur cette étape",
    "failedOnSTep": "Échec lors de cette étape",
    "numberIfTimesErroreOnStep": "Nombre de fois où la consultation a donné lieu à une erreur durant cette étape",
    "humaneInterventionCount": "Nombre d’interventions humaines",
    "numberOfTimesHUmanInterventionPerformed": "Nombre de fois où une intervention humaine a été effectuée",
    "numberOfTimesStepWasSkiepped": "Nombre de fois où l’étape a été ignorée",
    "tutorialGuideTitle": "Titre du Guide du Tutorial",
    "nameOfTutorialGuide": "Nom du Guide du Tutorial",
    "tutorialGuidePlayed": "Guide du Tutorial consulté",
    "numberOfTutorialGuidesPlayed": "Nombre de guides de didacticiel consultés",
    "tutorialGuideCompleted": "Guide de Tutorial finalisé",
    "numberOfTutorialGuidesCompleted": "Nombre de guides de didacticiel finalisés",
    "nameOfPowerForm": "Nom du Power Form.",
    "numberOfTimePowerFormCancelled": "Nombre de fois où le Power Form a été annulé",
    "numberOfTimesPowerFormSubmitted": "Nombre de fois où le Power Form a été envoyé",
    "nameOfGuide": "Nom du Guide.",
    "numberOfGuidanceCounts": "Nombre de procédures d’assistance",
    "numberOfValidationCounts": "Nombre de procédures de validation",
    "numberOfGuidanceAndValidationCounts": "Nombre de procédures d’assistance et de validation",
    "numberOfDigitalDuctTapeCount": "Nombre d’utilisations du ruban numérique",
    "numberOfPowerFormsCount": "Nombre d’utilisations du formulaire Power",
    "guideMePlayCount": "Nombre de consultations de GuideMe",
    "showMePlayCount": "Nombre de consultations de ShowMe",
    "testMePlayCount": "Nombre de consultations de TestMe",
    "doItForMePlayCount": "Nombre de consultations de DoItForMe",
    "tutorialPlayCount": "Nombre de consultations du Tutorial",
    "uniqueKeywordSearched": "Recherche par mot clé unique",
    "searchCountTitle": "Nombre de recherches",
    "avgResultReaturned": "Nombre moyen de résultats renvoyés",
    "avgNumberOfResultsShown": "Nombre moyen de résultats affichés",
    "numberOfTimeSearchResultedIntoAction": "Nombre de fois où la recherche a abouti à une action",
    "nameOfPageTag": "Nom de tags par page",
    "pageViews": "Vues de Page",
    "numberOfPageViews": "Nombre de vues de page",
    "numberOfUniqueVisitors": "Nombre de visiteurs uniques",
    "totalUniqueUsers": "Nombre total d’utilisateurs uniques",
    "newUsers": "Nouveaux utilisateurs",
    "numberOfSessions": "Nombre de sessions",
    "bouncedUsers": "Utilisateurs rejetés",
    "nameOfStep": "Nom de l’étape",
    "stepPlayedCOunts": "Nombre d’étapes mises en œuvre",
    "abgTimeSpentOnStep": "Temps moyen consacré à l’étape",
    "minTimeSpentOnStep": "Temps minimum consacré à l’étape",
    "nameOfSurvey": "Nom du Survey",
    "numberOfResponses": "Nombre de réponses",
    "userSurveyAnalysis": "Analyse du Survey pour les utilisateurs",
    "lastUpdateTime": "Heure de la dernière mise à jour",
    "receiveGoalEmailNotification": "Recevoir une notification par email sur l’Goal",
    "receiveGoalMilestoneNotification": "Recevoir une notification sur les jalons de l’Goal",
    "other": "autre",
    "tooltipPlays": "Consultations d’Tooltip",
    "watcherUpdatedSuccessfully": "Observateur mis à jour avec succès !",
    "tag": "Tag",
    "chatbotConversation": "Conversation avec chatbot",
    "searchChatbot": "Rechercher un chatbot",
    "iconToCreateNewChatbot": "icône pour créer un nouveau chatbot",
    "clickToEditChatbot": "Cliquez sur le chatBot de la liste pour le modifier.",
    "chatBotTitle": "Titre du chatbot",
    "mandatoryForChatBot": "Obligatoire pour le chatbot",
    "validateEmail": "Valider l'e-mail",
    "manageChatBot": "Gérer le chatbot",
    "geoLocationChartCountryWise1": "Un graphique de géolocalisation représentant l'utilisation des applications par pays à travers le monde. (calculé en fonction du fuseau horaire du système de l'utilisateur)",
    "guidesVsCompletion1": "Le pourcentage de guides joués jusqu'au bout par rapport à ceux qui n'ont pas été terminés.",
    "totalNumbersUsersInteractedWithPNB1": "Un graphique représentant l'initiation de la lecture GuideMe, ShowMe et DoItForMe effectuée à l'aide de la notification Push ou des Beacons.",
    "guideWithAudio1": "Le nombre total de fois qu'un guide a été joué avec une étape avec audio (nombre max. d'étapes jouées avec audio)",
    "guideWithoutAudio1": "Le nombre total de fois qu'un guide a été joué avec une étape sans audio (nombre max. d'étapes jouées sans audio)",
    "done": "Terminé",
    "discard": "Rejeter",
    "enableExitSurvey": "Activer la sortie du Survey",
    "selectExitSurvey": "Sélectionner la sortie du Survey",
    "numberUniqueUsersonPages": "Le nombre d'utilisateurs uniques qui ont consulté la page dans l'intervalle de temps imparti.",
    "exitSurveyWarningMsg": "Cette action sera appliquée à toutes les applications.",
    "orgAdmin": "Organisation Admin",
    "deleteChatBot": "Supprimer le chatbot",
    "deleteChatBotConfirmMsg": "Voulez-vous vraiment supprimer ce chatbot ?",
    "chatBotValidateEmailMsg": "(Seules les adresses électroniques valides seront acceptées dans la réponse à cette question. Veuillez formuler vos questions en conséquence.)",
    "partnerAdmin": "Partenaire Admin",
    "totalCountSummary": "Récapitulatif du nombre total",
    "PNBandTooltips": "Push-notifications, Beacons & Tooltips",
    "buttonText": "Connexion",
    "environmentFilterTitle": "Environnement",
    "segmentFilterTitle": "Segment",
    "eventSourceFilterTitle": "Origine de l’événement",
    "guideStatusFilterTitle": "Guide État",
    "tooltipFilterTitle": "Liste des Tooltips",
    "applications": "Applications",
    "none": "Aucun",
    "yes": "Oui",
    "no": "Non",
    "totalGuidesPlayedTitle": "Somme de : Guide Lu + ShowMe + DoItForMe + Tutorial Lectures",
    "tooltipActivity": "Activité de Tooltip",
    "pushnotificationActivity": "Activité de Push-Notification",
    "totalGuidePlayedInDoItForMeMode": "Nombre de guides lus en mode DoItForMe",
    "userActivityGraph": "Graphique linéaire représentant les activités effectuées par l'utilisateur sur les applications sélectionnées.",
    "conversationStats": "Statistiques des conversations",
    "chatBot": "ChatBot",
    "conversationsResponse": "Réponse aux conversations",
    "chatBotDetail": "Détails des conversations",
    "chatBotResponse": "Réponses aux conversations",
    "remarks": "Remarques",
    "noDataFound": "Donnée introuvable",
    "noRemarkFound": "Remarque introuvable",
    "viewQuestions": "Afficher les questions",
    "viewRemarks": "Afficher les remarques",
    "remarksPlaceholder": "Ajouter une remarque...",
    "remarkPostSuccessful": "Remarque publiée avec succès",
    "remarkPostFailed": "Échec de la publication de la remarque. Veuillez réessayer",
    "conversation": "Conversation",
    "conversationSummary": "Résumé de la conversation",
    "tableActitiesofConversation": "Un tableau représentant les activités de la conversation",
    "conversationName": "Nom de la conversation",
    "nameConversationCreated": "Nom de la conversation créée.",
    "labelsofConversation": "Labels de la conversation.",
    "numberResponsesEachConversation": "Nombre de réponses pour chaque conversation.",
    "dateTimeofConversationModification": "Date et heure auxquelles la conversation a été modifiée.",
    "lineChartConversationStatTooltip": "Graphique linéaire représentant la répartition des réponses à la conversation",
    "featureInteractions": "Interactions avec les fonctionnalités",
    "featureDetail": "Détail des fonctionnalités",
    "heatmapTooltipFeatureDetail": "Carte thermique représentant l’heure de la journée qui affiche le maximum d'interactions avec les fonctionnalités",
    "lineChartTooltipFeatureDetail": "Graphique linéaire représentant le nombre d'interactions avec les fonctionnalités au fil du temps",
    "featureSummary": "Résumé des fonctionnalités",
    "tableActivitiesOfFeature": "Un tableau représentant les activités des fonctionnalités.",
    "featureSetName": "Nom de l'ensemble de fonctionnalités",
    "nameOfFeatureSet": "Nom de l'ensemble de fonctionnalités",
    "interactions": "Interactions",
    "numOfInteractions": "Nombre d'interactions pour chaque ensemble de fonctionnalités",
    "dateTimeOfFeatureModification": "Date et heure auxquelles la fonctionnalité a été modifiée.",
    "rawURLActivities": "URL activités brutes",
    "pageTagInfo": "REMARQUE : Les modifications prévues suite à la création/ la suppression de tags prendront un certain temps pour prendre effet.",
    "createdBy": "Créé par",
    "createdByText": "Nom de l'utilisateur qui a crée le tag.",
    "lastUpdatedTimespan": "Dernière période de mise à jour du tag.",
    "blockDiagramTop50Pages1": "Schéma fonctionnel représentant les 50 premières pages d'événement bruts (en fonction du nombre d’accès à chaque page).",
    "webBrowserTooltip": "Section contenant la répartition d'utilisation en fonction du navigateur (en fonction du nombre de visites sur chaque page).",
    "osTooltip": "Section contenant la répartition d'utilisation en fonction du système d'exploitation (en fonction du nombre de visites sur chaque page).",
    "screenResolutionTooltip": "Section contenant la répartition d'utilisation en fonction de la résolution d'écran (en fonction du nombre de visites sur chaque page).",
    "completeURL": "URL} complète",
    "completeURLText": "URL} complète de la page dont l'activité a été enregistrée.",
    "URLVisits": "Visites de URL",
    "URLVisitsText": "Nombre de visites à une URL} particulière",
    "uniqueUserVisits": "Visites de URL} uniques",
    "uniqueUserVisitsText": "Nombre de visites uniques (utilisateurs uniques) à une URL} particulière.",
    "associatedPageTags": "Page Tags associés",
    "associatedPageTagsText": "Nombre de tags de page auxquels est associée cette URL}.",
    "totalUniqueUsersGraph": "Graphique représentant les statistiques utilisateur uniques au cours d'une période donnée",
    "noOfSessionsGraph": "Graphique représentant le nombre de sessions (activité continue avec des intervalles de temps ne dépassant pas 30 minutes) au cours d'une période donnée",
    "avgSessionDurationGraph": "Graphique représentant la durée moyenne de session (activité continue avec des intervalles de temps ne dépassant pas 30 minutes) au cours d'une période donnée",
    "pageViewsGraph": "Graphique représentant le nombre total de pages vues pour une période donnée",
    "featureList": "Liste des fonctionnalités",
    "userName": "Nom d'utilisateur",
    "userNameTooltip": "Nom de l'utilisateur",
    "userEmail": "E-mail utilisateur",
    "userEmailTooltip": "Adresse e-mail de l'utilisateur",
    "numOfInteractionsforUser": "Nombre d'interactions pour chaque utilisateur",
    "lineChartTooltipUserEngagement": "Graphique linéaire représentant le nombre d'interactions de fonctionnalités & d'utilisateurs de fonctionnalités au fil du temps",
    "appEnvSelect": "Sélectionner une application & un environnement",
    "totalCount": "Nombre total",
    "selectedAppsTitle": "Applications sélectionnées",
    "selectDateRangeWithin180Days": "Sélectionnez des tranches de date dans les 180 jours !",
    "pageVisits": "Visites de Page",
    "pageUsers": "Utilisateurs Page",
    "lineChartTooltipUserEngagementPage": "Graphique linéaire représentant le nombre de visites de pages et d'utilisateurs de pages au fil du temps",
    "lineChartTooltipUserEngagementPageModal": "Graphique linéaire représentant le nombre de visites de page au fil du temps",
    "numOfPageVisitsforUser": "Nombre de visites Page pour chaque utilisateur",
    "ytd": "YTD",
    "userActivityGraphDashboard": "Graphique à courbes représentant le nombre total d'utilisateurs dans une sélection spécifique d'applications.",
    "totalUserCount": "Nombre total d'utilisateurs",
    "bestTimePageLoad1": "Temps optimal nécessaire au chargement de la page.",
    "averageTimePageLoad1": "Temps moyen nécessaire au chargement de la page.",
    "worstTimePageLoad1": "Temps maximum nécessaire au chargement de la page.",
    "timeOfPageLoading1": "Temps nécessaire au chargement de la page.",
    "tooltipSummary": "Récapitulatif des Tooltips",
    "top10Tooltips": "10 principaux Tooltips",
    "totalPlaysLabel": "Nombre total de consultations",
    "guidanceLabel": "Assistance",
    "validationLabel": "Validation",
    "powerFormLabel": "Alimentation à partir de",
    "totalPlays": "Nombre total d'info-bulles avec interaction",
    "guidanceTooltip": "Nombre de fois où l'interaction de l'utilisateur s'est produite en mode assistance",
    "validationTooltip": "Nombre de fois où l'interaction de l'utilisateur s'est produite en mode Validation",
    "guidanceAndValidationTooltip": "Nombre de fois où l'interaction de l'utilisateur s'est produite en mode assistance et & Validation",
    "digitalDuctTapeTooltip": "Nombre de fois où l'interaction de l'utilisateur s'est produite en mode Duct Tape numérique",
    "powerFormTooltip": "Nombre de fois où l'interaction de l'utilisateur s'est produite en mode Power Form",
    "applicationAndPlayType": "Application et type de lecture",
    "selectApplication": "Sélectionner une application :",
    "selectEventSource": "Sélectionner une source d'événement :",
    "selectPlayType": "Sélectionner un type de lecture :",
    "appSelectionNote": "Remarque : Pour une sélection granulaire, veuillez sélectionner une seule application",
    "chartFilteredBasedOnApps": "Ce graphique est filtré en fonction des applications sélectionnées uniquement.",
    "pushNotificationInteractions": "Interaction avec les notifications push",
    "pushNotificationDisplayed": "Notifications push affichées",
    "beaconsInteractions": "Interaction Beacons",
    "beaconsDisplayed": "Beacons affiché",
    "guidesPlayedByPushAndBeacons": "Guides Déclenché par Push & Beacon",
    "emailCCPlaceholder": "Tapez Email Identifiant et appuyez sur Entrée !",
    "emailCC": "CC e-mail",
    "invalidEmailId": "Identifiant de messagerie invalide",
    "duplicateEmailId": "Adresse e-mail en double",
    "selectedPlayTypes": "Vos types de jeu sélectionnés",
    "selectedTooltip": "Sélectionner Tooltip",
    "newTemplate": "Nouveau modèle",
    "templateTitle": "Titre du modèle",
    "newSchedule": "Nouveau planning",
    "scheduleTitle": "Titre du planning",
    "useTemplate": "Utiliser modèle",
    "saveSchedule": "Enregistrer planning",
    "updateSchedule": "Mettre à jour planning",
    "deleteTemplate": "Supprimer le modèle",
    "deleteSchedule": "Supprimer planning",
    "deleteTemplateConfirmationMsg": "Voulez-vous supprimer ce modèle ?",
    "deleteScheduleConfirmationMsg": "Voulez-vous supprimer ce planning ?",
    "saveTemplate": "Enregistrer le modèle",
    "monday": "Lundi",
    "tuesday": "Mardi",
    "wednesday": "Mercredi",
    "thursday": "Jeudi",
    "friday": "Vendredi",
    "saturday": "Samedi",
    "sunday": "Dimanche",
    "triggerTime": "Temps de déclenchement",
    "activate": "Activer",
    "deactivate": "Désactiver",
    "templateTitleErrorMsg": "Veuillez ajouter le titre du modèle",
    "scheduleTitleErrorMsg": "Veuillez ajouter le titre du planning",
    "scheduleFutureDateErrorMsg": "Veuillez sélectionner une date future pour le planning",
    "scheduleDaySelectionErrorMsg": "Veuillez sélectionner les jours pendant lesquels le planning s'exécutera",
    "scheduleMaxDaySelectionErrorMsg": "Un maximum de 7 jours peut être sélectionné",
    "scheduleMaxActiveErrorMsg": "Un maximum de 3 plannings peuvent être actifs en même temps.",
    "activeScheduleNonEditableInfoMsg": "Le planning actif ne peut'pas être modifié",
    "selectTime": "Sélectionnez l'heure",
    "oneTime": "Une fois",
    "repetitive": "Répétitif",
    "tutorial": "Tutorial",
    "updatedSuccessfully": "Mis à jour avec succès !",
    "pushNotificationInteractedKey": "PushNotification a interagi",
    "pushNotificationDisplayedKey": "Notification Push affichée",
    "beaconInteractedKey": "Beacon} a interagi",
    "pushNotificationInteractedValue": "Nombre d'instances où l'utilisateur a interagi avec la notification push.",
    "pushNotificationDisplayedValue": "Nombre de fois où les notifications push ont été affichées sur l'écran de l'utilisateur (veuillez noter que les affichages sont capturés une fois pour chaque combinaison push de l'utilisateur & par jour).",
    "beaconInteractedValue": "Nombre d'instances où l'utilisateur a interagi avec la balise.",
    "beaconDisplayedValue": "Nombre de fois où les beacons ont été affichés sur l'écran de l'utilisateur (veuillez noter que les affichages sont capturés une fois pour chaque combinaison beacon de l'utilisateur & par jour).",
    "emailCCWarningMessage": "Un maximum de 10 adresses e-mail est autorisé",
    "ytdCalendarYear": "YTD (Calendar Year)",
    "activateScheduleMsg": "Souhaitez-vous activer ce planning ?",
    "deactivateScheduleMsg": "Voulez-vous désactiver ce planning ?",
    "help": "Aide",
    "updateTemplate": "Mise à jour du modèle",
    "beaconDisplayedKey1": "Beacon affiché",
    "taskList": "Liste de tâches",
    "tasksTotalPlayed": "Nombre total de listes de tâches engagées dans une période donnée.",
    "tasksCompleted": "Nombre total de listes de tâches terminées dans une période donnée.",
    "taskListNameLabel": "Nom de la liste de tâches",
    "taskListEngagementsLabel": "Liste de tâches engagée",
    "taskListCompletedLabel": "Liste des tâches terminée",
    "taskListLastUpdatedLabel": "Date de la dernière mise à jour",
    "taskListName": "Nom de la liste des tâches",
    "taskListEngagements": "Nombre total d'engagements de listes de tâches",
    "taskListCompleted": "Nombre total de listes de tâches terminées",
    "taskListLastUpdated": "Date et heure de modification de la liste des tâches",
    "taskListDetail": "Détail de la liste des tâches",
    "taskListGuidePlay": "Consultation de Guide",
    "completionStatus": "État d’achèvement",
    "completionTime": "Durée d'achèvement",
    "totalTaskListPlayedMsgTooltip": "Nombre de fois où la liste des tâches est lue",
    "completedTaskListTooptip": "Nombre de fois où la liste des tâches est terminée",
    "guidePlays": "Consultations de Guide",
    "guidePlayTaskListTooltip": "Nombre de lectures de guide via la liste des tâches",
    "taskListInteracted": "Liste des tâches avec interaction",
    "taskListInteractedTooltip": "L'utilisateur a joué à au moins un guide dans la liste des tâches",
    "taskListCompletedTooltip": "L'utilisateur a terminé tous les guides de la liste des tâches",
    "viewGuides": "Afficher Guides",
    "min7DaysSelection": "Le délai minimum sélectionné pour la section Engagement des utilisateurs est de 7 jours.",
    "totalGuidesPlayCount": "Le nombre total de guides lus en mode GuideMe, DoItForMe et ShowMe.",
    "role": "RÔLE",
    "performedSearches": "Recherches effectuées",
    "searchesResultedIntoAction": "Recherches ayant abouti à une action",
    "exportStatus": "État d'exportation",
    "myGuideGuidesPlayed": "MyGuide Guides Joué",
    "myGuideTutorialGuidesPlayed": "MyGuide Tutorial Guides Joué",
    "totalNumberOfTimesGuidePlayedInDoItForMe": "Nombre total de fois où le guide a été joué en mode DoItForMe.",
    "creatorExtensionRequired": "Pour que cette fonctionnalité soit opérationnelle, la dernière version de l'extension MyGuide Creator doit être installée en tant qu'extension dans le navigateur.",
    "guidesInTooltipModeinTimeFrame": "Nombre total de Tooltip affichés dans le laps de temps donné.",
    "guidesInPNModeinTimeFrame": "Nombre total de guides joués à l'aide des notifications push dans la période donnée.",
    "guidesInBeaconModeinTimeFrame": "Nombre total de guides lus en utilisant des Beacons dans le délai donné.",
    "guidesInSurveyModeinTimeFrame": "Nombre total de réponses à l'enquête dans la période donnée.",
    "visits": "Visites",
    "desktopPlayer": "Lecteur pour ordinateur de bureau",
    "guideErrors": "Guide Erreurs",
    "guideErrorsDetails": "Guide Détails des erreurs",
    "guideStepErrors": "Guide Erreurs d'étape",
    "guideStepErrorDetails": "Détails des erreurs d'étape du Guide",
    "guideStepErrorsActivity": "Guide Activité sur les erreurs d'étape",
    "guideErrorOccurrences": "Guide Occurrences d'erreur",
    "guideErrorOccurrencesValue": "Nombre total d'erreurs du guide dans le laps de temps sélectionné",
    "guideErrorSummary": "Guide Liste récapitulative des erreurs",
    "guideErrorSummaryValue": "Résumé de tous les guides comportant des étapes qui ont connu une erreur d'étape dans le laps de temps donné",
    "stepInGuideWithErrors": "Étapes du guide avec erreurs",
    "totalGuidePlayErrors": "Total des erreurs de lecture du Guide",
    "latestErrorRecorded": "Dernière erreur enregistrée le",
    "stepInGuideWithErrorsValue": "Nombre total d'étapes du guide qui ont rencontré des erreurs.",
    "totalGuidePlayErrorsValue": "Nombre total de fois qu'une erreur a été rencontrée dans le guide de lecture",
    "latestErrorRecordedValue": "Horodatage de la dernière erreur enregistrée",
    "latestStartTimestamp": "Dernier horodatage de début",
    "stepErrorCounts": "Nombre d'étapes erronées",
    "latestStartTimestampValue": "Dernier horodatage de l'occurrence de l'erreur à l'étape.",
    "stepErrorCountsValue": "Nombre total de fois qu'une erreur s'est produite à l'étape",
    "stepErrorSummary": "Résumé des erreurs d'étape",
    "guideError": "Erreur du Guide",
    "actions": "Actions",
    "createTemplate": "Créer un modèle",
    "createSchedule": "Créer un planning",
    "createJob": "Créer un emploi",
    "playType": "Type d'opération",
    "doYouWantToDelete": "Confirmez-vous la suppression ?",
    "dateRange": "Tranches de date",
    "triggerType": "Type de déclencheur",
    "triggerFrequency": "Fréquence de déclenchement",
    "rowsPerPage": "Lignes par page",
    "exportToExcel": "Exporter vers Excel",
    "triggerDay": "Jour de déclenchement",
    "triggerDate": "Date de déclenchement",
    "dayOfWeek": "Jour de la semaine",
    "dateOfMonth": "Date du mois",
    "pauseSchedule": "Mettre le programme en pause",
    "playSchedule": "Lire le programme",
    "editSchedule": "Modifier le programme",
    "editTemplate": "Modifier le modèle",
    "businessIntelligenceToolsIntegrations": "Intégration des outils de Business Intelligence",
    "bitiInfoHeading0": "Comment obtenir le connecteur Power BI Desktop pour \"CornerstoneGuide\" ?",
    "bitiInfo0_1": "Cliquez ici",
    "bitiInfo0_2": "pour télécharger le fichier du connecteur (.mez)",
    "bitiInfo0_3": "Copiez le fichier d'extension dans",
    "bitiInfo0_4": "Cochez l'option",
    "bitiInfo0_5": "Autoriser le chargement de n'importe quelle extension sans validation ni avertissement",
    "bitiInfo0_6": "Dans Power BI Desktop sous",
    "bitiInfo0_7": "Redémarrer Power BI Desktop",
    "bitiInfoHeading1": "Comment extraire des données de Cornerstone Guide Insights directement vers votre instance PowerBI Desktop ?",
    "bitiInfo1_1": "Générez un URL} authentifié en cliquant sur le bouton « Générer » ci-dessous.",
    "bitiInfo1_2": "Copiez le URL} généré",
    "bitiInfo1_3": "Accédez à PowerBI Desktop sur votre ordinateur Windows",
    "bitiInfo1_4": "Cliquez sur l'onglet \"Accueil\" dans la barre de navigation, puis cliquez sur l'option \"Obtenir des données\". Dans le menu déroulant, cliquez sur « Plus »",
    "bitiInfo1_5": "Dans la boîte de dialogue qui s'ouvre, cliquez sur « Autre » et recherchez « Cornerstone Guide (Beta) (Custom) », puis cliquez sur « Connecter ».",
    "bitiInfo1_6": "Dans la nouvelle boîte de dialogue, collez l'URL} authentifiée et cliquez sur « Ok ».",
    "bitiInfo1_7": "Cela ouvrira une fenêtre \"Navigation\". Naviguez et sélectionnez les données que vous voulez extraire dans PowerBI Desktop et cliquez sur « Charger »",
    "bitiInfoHeading2": "Instructions importantes",
    "bitiInfo2_1": "Chaque URL} authentifiée générée dans ce portail est propre à chaque utilisateur et est liée à son compte utilisateur.",
    "bitiInfo2_2": "Bien que les URL} elles-mêmes n'aient pas d'expiration, elles sont liées à des sessions qui expirent au bout de 60 minutes d'inactivité.",
    "bitiInfo2_3": "Les URL peuvent être réautorisées en cliquant sur le bouton \"Réautoriser\" ci-dessus dans la section.",
    "bitiInfo2_4": "Si vous souhaitez modifier l'URL (à volonté ou en cas de compromission du URL), vous pouvez le faire en cliquant sur le bouton « générer à nouveau ».",
    "bitiInfoNote": "Remarque : L'authentification de URL est applicable pendant 60 minutes à partir du moment où elle a été générée.",
    "generateUrl": "Générer URL",
    "authenticateUrl": "Authentifier URL",
    "goalLiveOn": "Goal En direct",
    "surveySummary": "Récapitulatif des Surveys",
    "stats": "Statistiques",
    "guidePlayed": "Guide Joué",
    "showMePlayed": "ShowMe Joué",
    "testMePlayed": "TestMe Joué",
    "digitalDuctTapeLabel": "Duct Tape numérique",
    "taskListActivity": "Activité de la liste de tâches",
    "taskListSummary": "Résumé de la liste des tâches",
    "copy": "Copier",
    "initiateDataSynchronization": "Lancer la synchronisation des données",
    "initiationInProgressInfo": "Votre demande d'initiation est en cours. Veuillez revenir plus tard.",
    "initiationFailedInfo": "La dernière demande d'initiation a échoué. Veuillez réessayer",
    "goalStatus": "État du Goal}",
    "currentStatus": "État actuel",
    "goalActions": "Actions du Goal}",
    "goalTitleText": "Le titre de l'objectif suivi",
    "goalStatusText": "L'état actuel de l'objectif",
    "goalLiveOnText": "Date de mise en service de l'objectif",
    "targetCompletionText": "La date d'achèvement prévue ou planifiée de l'objectif",
    "currentStatusText": "Le pourcentage de progression ou le niveau d'achèvement actuel de l'objectif.",
    "goalActionsText": "Actions ou options disponibles pour gérer l'objectif",
    "dashboard24HrMsg": "Les données du tableau de bord sont mises à jour généralement une fois toutes les 24 heures.",
    "totalShowMePlayedInGivenTimeFrame": "Nombre total de guides joués en mode « show me » dans un laps de temps donné",
    "cioDashboardTitle": "Dashboard du DSI",
    "myGuideDashboard": "MyGuide Dashboard",
    "guideInsights": "Guide Insights",
    "appInsights": "Insights sur l'application",
    "survey": "Survey",
    "automationInsights": "Automatisation Insights",
    "goal": "Goal",
    "applicationAndEnvironment": "Application & environnement",
    "appEnvironmentFilter": "Filtre de l'environnement de l'application",
    "hi": "Bonjour",
    "yourInsightUpdates": "Votre Insights mise à jour",
    "tutorialGuide": "Tutorial Guide",
    "tooltip": "Tooltip",
    "pushNotification": "Push-Notification",
    "beacon": "Beacon",
    "guidesTab": "Guides",
    "jobList": "Liste des travaux",
    "templateList": "Liste des modèles",
    "schedularList": "Liste des planificateurs",
    "guideAutomation": "Guide Automatisation",
    "ductTape": "Duct Tape",
    "powerForm": "Power Form",
    "tutorialGuides": "Tutorial Guides",
    "guideMeTutorialGuide": "GuideMe",
    "pushNotifications": "Push-Notifications",
    "beacons": "Beacons",
    "tooltipLabel": "Tooltips",
    "validation": "Validation",
    "guideSurvey": "Guide Survey",
    "exitGuideSurvey": "Sortie Guide Survey",
    "guideStepSurvey": "Guide Étape Survey",
    "guide": "Guide",
    "guideNameSubheadingLabel": "Nom de Guide",
    "guidesPlayedSubheadingLabel": "Nombre de guides consultés",
    "guidesPlayedInGuideMeModeSubheadingLabel": "Nombre de guides lus en mode GuideMe",
    "guidesPlayedInDoItForMeModeSubheadingLabel": "Nombre de guides consultés en mode Do It For Me",
    "showMePlayedSubheadingLabel": "Nombre de consultations ShowMe",
    "testMePlayedSubheadingLabel": "Nombre de consultations TestMe",
    "surveyResponsesSubheadingLabel": "Nombre de réponses au Survey",
    "stepsWithErrorsSubheadingLabel": "N° d'étapes dans le guide rencontrant des erreurs",
    "timesErrorEncounteredSubheadingLabel": "Nombre de fois où l'erreur a été rencontrée",
    "lastErrorTimestampSubheadingLabel": "Horodatage de la dernière erreur enregistrée",
    "stepNameSubheadingLabel": "Nom de l'étape",
    "stepLatestTimestampSubheadingLabel": "Horodatage le plus récent",
    "stepErrorCountSubheadingLabel": "Nombre de fois où l'erreur a été rencontrée",
    "stepPlayCountSubheadingLabel": "Nombre de fois où l'étape a été mise en œuvre",
    "humanInterventionCountSubheadingLabel": "Nombre de fois où une intervention humaine a été effectuée",
    "stepSkippedCountSubheadingLabel": "Nombre de fois où l'étape a été ignorée",
    "stepPlayErrorCountSubheadingLabel": "Nombre de fois où la consultation a donné lieu à une erreur durant cette étape",
    "tutorialGuideNameSubheadingLabel": "Nom du Guide du Tutorial",
    "tutorialGuidesPlayedSubheadingLabel": "Nombre de guides de didacticiel consultés",
    "tutorialGuidesCompletedSubheadingLabel": "Nombre de guides de didacticiel finalisés",
    "stepPlayStartedCountSubheadingLabel": "Nombre de fois où la consultation a commencé à partir de cette étape",
    "stepPlayEndedCountSubheadingLabel": "Nombre de fois où la consultation s'est terminée sur cette étape",
    "powerFormNameSubheadingLabel": "Nom de Power Form",
    "powerFormCancelledCountSubheadingLabel": "Nombre de fois où le Power Form a été annulé",
    "powerFormSubmittedCountSubheadingLabel": "Nombre de fois où le Power Form a été envoyé",
    "taskListNameSubheadingLabel": "Nom de la liste des tâches",
    "taskListEngagedCountSubheadingLabel": "Nombre de listes de tâches engagées",
    "taskListCompletedCountSubheadingLabel": "Nombre de listes de tâches complétées",
    "taskListLastUpdatedSubheadingLabel": "Dernière mise à jour de la liste des tâches le",
    "userNameSubheadingLabel": "Nom de l'utilisateur",
    "userEmailAddressSubheadingLabel": "Adresse e-mail de l'utilisateur",
    "userPlayedTaskListGuideSubheadingLabel": "L'utilisateur a consulté au moins un guide de liste de tâches.",
    "userCompletedAllGuidesInTaskListSubheadingLabel": "l'utilisateur a terminé tous les guides dans la liste des tâches",
    "guideMePlayCountSubheadingLabel": "GuideMe Nombre de lectures",
    "showMePlayCountSubheadingLabel": "ShowMe Nombre de lectures",
    "testMePlayCountSubheadingLabel": "TestMe Nombre de lectures",
    "doItForMePlayCountSubheadingLabel": "Nombre de consultations de DoItForMe",
    "tutorialPlayCountSubheadingLabel": "Nombre de consultations de Tutorial",
    "uniqueKeywordSearchedSubheadingLabel": "Recherche par mot clé unique",
    "keywordSearchCountSubheadingLabel": "Nombre de recherches",
    "averageSearchResultsSubheadingLabel": "Nombre moyen de résultats affichés",
    "searchActionCountSubheadingLabel": "Nombre de fois où la recherche a abouti à une action",
    "pageUrlSubheadingLabel": "URL complète de la page",
    "pageVisitCountSubheadingLabel": "Nombre de visites de l'URL",
    "uniqueUserVisitsSubheadingLabel": "Nombre de visites d'utilisateurs uniques",
    "urlTagCountSubheadingLabel": "Nombre de tags associés à cette URL",
    "pageTagNameSubheadingLabel": "Nom de tags par page",
    "pageViewCountSubheadingLabel": "Nombre de vues de page",
    "uniquePageVisitorsSubheadingLabel": "Nombre de visiteurs uniques",
    "userWhoTaggedSubheadingLabel": "Nom de l'utilisateur qui a activé le tag",
    "pageLastUpdatedSubheadingLabel": "Dernière mise à jour le",
    "surveyNameSubheadingLabel": "Nom de Survey",
    "surveyResponseCountSubheadingLabel": "Nombre de réponses",
    "userSurveyAnalysisSubheadingLabel": "Analyse du Survey pour les utilisateurs",
    "surveyLastUpdatedSubheadingLabel": "Heure de la dernière mise à jour",
    "conversationNameSubheadingLabel": "Nom de la conversation",
    "conversationResponseCountSubheadingLabel": "Nombre de réponses",
    "conversationLastUpdatedSubheadingLabel": "Heure de la dernière mise à jour",
    "goalNameSubheadingLabel": "Nom du Goal",
    "goalStatusSubheadingLabel": "État du Goal}",
    "goalLiveDateSubheadingLabel": "Date de mise en service du Goal",
    "goalCompletionDateSubheadingLabel": "Goal Date d'achèvement",
    "goalCompletionPercentageSubheadingLabel": "Pourcentage d'achèvement du Goal",
    "availableActionsSubheadingLabel": "Actions disponibles",
    "pushNotificationAndBeacons": "Push-notifications & Beacons",
    "pushNotificationsAndBeacons": "Notifications push & Beacons"
  };

  module.exports = GmXt.playerLbls.fr_FR;